import * as React from "react";
import styled from "styled-components";
import { InputGroup, InputGroupAddon, Input } from "reactstrap";
import {
  toggleGenerate,
  toggleCrosswordPremadePreviewModal,
  setPremadeCrosswordId
} from "../../../redux";
import {
  getAllCategories,
  getPremadeListForCategory
} from "../../../API/PremadeProducts";
import { connect } from "react-redux";
import Select from "react-select";
//@ts-ignore
import BootstrapTable from "react-bootstrap-table-next";
//@ts-ignore
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
  //@ts-ignore
} from "react-bootstrap-table2-paginator";
import { nameOfAllProducts } from "../Variables";
const { SearchBar } = Search;

export interface props {
  toggleGenerate: Function;
  toggleCrosswordPremadePreviewModal: Function;
  setPremadeCrosswordId: Function;
  type: string;
}

export interface state {
  premadeJsonBlobs: Array<any>;
  premadeList: Array<any>;
  categoryOptions: Array<{ value: string; label: string }>;
  selectedCategory: { value: string; label: string };
  loading: boolean;
}

const Div = styled("div")`
  height: 50vh;
  min-height: 455px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  background-color: #3c4c55;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const Title = styled("div")`
  color: #ffffff;
  font-size: 25px;
  padding-bottom: 20px;
`;
const PremadeListForm = styled("div")`
  max-height: 89%;
  padding-top: 8px;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #70c989;
`;
const TableDiv = styled("div")`
  height: 100%;
  overflow: auto;
  .table {
    margin-bottom: unset;
  }
  cursor: pointer;
  th.sortable {
    background-color: #252a31;
    &:hover {
      color: #70c989;
    }
    &:focus {
      outline: unset;
      color: #70c989;
      box-shadow: inset 0px -5px 0px 0px #70c989;
    }
    &:active {
      outline: unset;
      color: #70c989;
      box-shadow: inset 0px -5px 0px 0px #70c989;
    }
  }

  tr {
    &:hover {
      td {
        color: #70c989;
      }
    }
  }
  .table-bordered td,
  .table-bordered th {
    color: white;
    border: 0.1px solid #252a31;
  }
`;
const StyledInputGroup = styled(InputGroup)`
  width: unset !important;
  .titleInput {
    height: 26px !important;
    border: unset !important;
  }
  .categoryLabel {
    height: 26px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const PaginationDiv = styled("div")`
  .pagination {
    margin: unset;
  }
  .page-link {
    display: flex;
    height: 26px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 0 !important;
    background-color: #252a31;
    border: 0.5px solid #70c989;
    &:hover {
      color: #70c989;
      border: 0.5px solid #70c989;
      background-color: #252a31;
    }
    &:focus {
      box-shadow: unset;
    }
  }
  .page-item.active .page-link {
    background-color: #70c989;
    border: 0.5px solid #70c989;
  }
`;
const styles = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 26,
    height: 26,
    width: 135,
    borderRadius: 0,
    border: "unset",
    boxShadow: "unset"
  }),
  container: (base: any, state: any) => ({
    ...base,
    height: 26
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  menu: (base: any) => ({
    ...base,
    margin: 0,
    borderRadius: 0
  }),
  option: (base: any, state: any) => ({
    ...base,
    paddingTop: 5,
    paddingBottom: 5,
    height: 26,
    backgroundColor: state.isSelected
      ? "#70c989"
      : state.isFocused
      ? "#70c98963"
      : null
  })
};
const TableConfig = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;
export class PremadeList extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      premadeJsonBlobs: [],
      premadeList: [],
      categoryOptions: [],
      selectedCategory: { value: "All", label: "All" },
      loading: false
    };
  }

  componentDidMount() {
    this.getCategories();
    this.getPremadeListForCategory("All");
  }

  getCategories = async () => {
    const categoryOptions = [{ value: "All", label: "All" }];

    let apiCategoryOptions = null;
    try {
      apiCategoryOptions = await getAllCategories(this.props.type);
    } catch (e) {
      throw e;
    }

    apiCategoryOptions = apiCategoryOptions.map((category: string) => {
      return { value: category, label: category };
    });

    this.setState({
      categoryOptions: [...categoryOptions, ...apiCategoryOptions]
    });
  };

  getPremadeListForCategory = async (category: string) => {
    this.setState({ premadeList: [] });

    let premadeList = null;
    try {
      premadeList = await getPremadeListForCategory(this.props.type, category);
    } catch (e) {
      throw e;
    }

    this.setState({
      premadeList
    });
  };

  handleRowClick = async (e: any, row: any, rowIndex: any) => {
    if (this.props.type === nameOfAllProducts[0]) {
      this.props.setPremadeCrosswordId(row.id);
      this.props.toggleGenerate(false);
      this.props.toggleCrosswordPremadePreviewModal(true);
    }
  };

  getTing = () => {
    // @ts-ignore
    const ting = ({ paginationProps, paginationTableProps }) => (
      <ToolkitProvider
        keyField="id"
        search
        data={this.state.premadeList}
        columns={[
          {
            dataField: "id",
            hidden: true
          },
          {
            dataField: "rawData.titleText",
            text: "Title",
            sort: true
          },
          {
            dataField: "category",
            text: "Category",
            sort: true
          },
          {
            dataField: "rawData.numOfWords",
            text: "Words",
            sort: true,
            headerStyle: { width: "90px" }
          }
        ]}
      >
        {(toolkitProps: any) => (
          <React.Fragment>
            <TableConfig>
              <StyledInputGroup>
                <InputGroupAddon className="categoryLabel" addonType="append">
                  Category
                </InputGroupAddon>
                <Select
                  styles={styles}
                  value={this.state.selectedCategory}
                  onChange={(selectedCategory: any) => {
                    this.getPremadeListForCategory(selectedCategory.value);
                    this.setState({ selectedCategory });
                  }}
                  options={this.state.categoryOptions}
                />
              </StyledInputGroup>
              <SearchBar
                style={{ borderRadius: "unset", height: "26px" }}
                {...toolkitProps.searchProps}
              />
            </TableConfig>
            <TableDiv>
              <BootstrapTable
                noDataIndication={() => <div>Loading...</div>}
                hover
                rowStyle={{ lineHeight: "5px" }}
                rowEvents={{
                  onClick: (e: any, row: any, rowIndex: any) =>
                    this.handleRowClick(e, row, rowIndex)
                }}
                {...toolkitProps.baseProps}
                {...paginationTableProps}
              />
            </TableDiv>
            <PaginationDiv>
              <PaginationListStandalone {...paginationProps} />
            </PaginationDiv>
          </React.Fragment>
        )}
      </ToolkitProvider>
    );

    return ting;
  };

  render(): JSX.Element {
    return (
      <Div>
        <Title>Pre Made</Title>
        <PremadeListForm>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: 1,
              sizePerPage: 50
            })}
          >
            {this.getTing()}
          </PaginationProvider>
        </PremadeListForm>
      </Div>
    );
  }
}

const PremadeListConnected = connect(null, {
  toggleGenerate,
  toggleCrosswordPremadePreviewModal,
  setPremadeCrosswordId
})(PremadeList);

export default PremadeListConnected;
