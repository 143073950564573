import * as React from "react";
import styled from "styled-components";
import { Button, Modal } from "reactstrap";
import { connect } from "react-redux";
import { toggleUserAuthModal, toggleAuthToShow } from "../../redux";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

export interface props {
  showUserAuthModal: boolean;
  authToShow: string;
  toggleUserAuthModal: Function;
  toggleAuthToShow: Function;
}

export interface state {
  showUserAuthModal: boolean;
  authToShow: string;
}

export interface update {
  showUserAuthModal?: boolean;
  authToShow?: string;
}

const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  .modal-content {
    max-width: 365px;
    display: flex;
    border-radius: 0 !important;
    border: none;
    background-color: transparent;
  }
`;
const StyledModalHeader = styled("div")`
  width: 100%;
  display: flex;
  background-color: transparent;
  .closeButtonTab {
    width: 36px;
    font-weight: 700;
  }
  .loginButtonTab {
    border-right: 1px solid #70c989;
  }
`;
const Tab = styled("div")<{ active?: number }>`
  border-bottom: 1px solid #70c989;
  height: 34px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3c4c55;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  color: ${props => (props.active ? "#70c989" : "#ffffff")};
  box-shadow: ${props =>
    props.active ? "inset 0px -5px 0px 0px #70c989" : null};
`;
const CloseModalDiv = styled("div")`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const StyledModalBody = styled("div")`
  background-color: #3c4c55;
  padding: 36px;
`;
export class UserAuthModal extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      showUserAuthModal: false,
      authToShow: "login"
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: update = {};

    if (nextProps.showUserAuthModal !== prevState.showUserAuthModal) {
      update.showUserAuthModal = nextProps.showUserAuthModal;
    }
    if (nextProps.authToShow !== prevState.authToShow) {
      update.authToShow = nextProps.authToShow;
    }

    return Object.keys(update).length ? update : null;
  }

  getAuthBody = () => {
    const { authToShow } = this.state;

    if (authToShow === "login") {
      return (
        <StyledModalBody>
          <Login />
        </StyledModalBody>
      );
    } else if (authToShow === "register") {
      return (
        <StyledModalBody>
          <Register />
        </StyledModalBody>
      );
    } else if (authToShow === "forgotPassword") {
      return (
        <StyledModalBody>
          <ForgotPassword />
        </StyledModalBody>
      );
    }
  };

  handleModalOutsideClick = () => {
    const { authToShow } = this.state;
    if (authToShow === "login") {
      this.props.toggleUserAuthModal(false);
    }
  };

  render(): JSX.Element {
    return (
      <StyledModal
        toggle={this.handleModalOutsideClick}
        isOpen={this.state.showUserAuthModal}
        centered={true}
      >
        <StyledModalHeader>
          <Tab
            className="loginButtonTab"
            onClick={() => this.props.toggleAuthToShow("login")}
            active={this.state.authToShow === "login" ? 1 : 0}
          >
            Sign In
          </Tab>
          <Tab
            onClick={() => this.props.toggleAuthToShow("register")}
            active={this.state.authToShow === "register" ? 1 : 0}
          >
            Sign Up
          </Tab>
          <CloseModalDiv>
            <Tab
              className="closeButtonTab"
              onClick={() => this.props.toggleUserAuthModal(false)}
            >
              X
            </Tab>
          </CloseModalDiv>
        </StyledModalHeader>
        {this.getAuthBody()}
      </StyledModal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    showUserAuthModal: state.userAuthReducer.showUserAuthModal,
    authToShow: state.userAuthReducer.authToShow
  };
}

const UserAuthModalContainer = connect(mapStateToProps, {
  toggleUserAuthModal,
  toggleAuthToShow
})(UserAuthModal);

export default UserAuthModalContainer;
