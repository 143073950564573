import * as React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, Modal, Table } from "reactstrap";
import { connect } from "react-redux";
import {
  toggleToast,
  togglePaymentPlansModal,
  toggleExpiredPlanModal
} from "../../redux";
import {
  paymentPlansInterface,
  paymentPlansModalTypes
} from "../Helpers/Variables";

export interface props {
  paymentPlans: Array<paymentPlansInterface>;
  isLoggedIn: boolean;
  togglePaymentPlansModal: Function;
  toggleToast: Function;
  isPayingUser: boolean;
  showExpiredPlanModal: boolean;
  toggleExpiredPlanModal: Function;
}

export interface state {
  paymentPlans: Array<paymentPlansInterface>;
  isLoggedIn: boolean;
  isPayingUser: boolean;
  showExpiredPlanModal: boolean;
}

const Title = styled("div")`
  color: white;
  font-size: 25px;
`;
const Description = styled("div")`
  padding-top: 30px;
  width: 100%;
  text-align: left;
  color: white;
  font-size: 18px;
  white-space: pre-line;
  line-height: 1.5;
`;
const ReactStrapButton = styled(Button)`
  margin-left: 5px !important;
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 30px !important;
  padding: 0 13px 0 13px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const StyledModalBody = styled("div")`
  background-color: #3c4c55;
  padding: 36px;
`;
const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  .modal-content {
    width: unset;
    border-radius: 0 !important;
    border: none;
    background-color: transparent;
  }
`;
const CloseButton = styled("div")`
  position: absolute;
  align-self: flex-end;
  // border: 1px solid #70c989;
  height: 34px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3c4c55;
  width: 36px;
  font-weight: 700;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  color: #ffffff;
`;
export class ExpiredPlanModal extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      paymentPlans: [],
      isLoggedIn: false,
      isPayingUser: false,
      showExpiredPlanModal: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      paymentPlans?: Array<paymentPlansInterface>;
      isLoggedIn?: boolean;
      isPayingUser?: boolean;
      showExpiredPlanModal?: boolean;
    } = {};
    if (nextProps.paymentPlans !== prevState.paymentPlans) {
      update.paymentPlans = nextProps.paymentPlans;
    }

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }

    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    if (nextProps.showExpiredPlanModal !== prevState.showExpiredPlanModal) {
      update.showExpiredPlanModal = nextProps.showExpiredPlanModal;
    }
    return Object.keys(update).length ? update : null;
  }

  handleToggle = () => {
    window.location.reload();
  };

  render(): JSX.Element {
    return (
      <StyledModal
        isOpen={this.state.showExpiredPlanModal}
        toggle={() => this.handleToggle()}
        centered={true}
      >
        <CloseButton onClick={() => this.handleToggle()}>X</CloseButton>
        <StyledModalBody>
          <Title>Paid Plan Has Expired</Title>
          <Description>
            You've reached your maximum download limit for the payment plan that
            was activated for your account. Because of this, unfortunately, you
            cannot continue to download your own creations.
            <br />
            <br />
            Please activate a premium plan to continue downloading. You can
            browse the available plans below.
            <br />
            <br />
            <ReactStrapButton
              onClick={() =>
                this.props.togglePaymentPlansModal(
                  true,
                  paymentPlansModalTypes[1],
                  !this.state.isPayingUser
                )
              }
            >
              Browse Plans
            </ReactStrapButton>
            <br />
            <br />
            {
              "Note: If you believe this expiration is a mistake, please contact wordymaker@gmail.com"
            }
          </Description>
        </StyledModalBody>
      </StyledModal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    paymentPlans: state.paymentReducer.paymentPlans,
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser,
    showExpiredPlanModal: state.paymentReducer.showExpiredPlanModal
  };
}

const ExpiredPlanModalContainer = connect(mapStateToProps, {
  toggleToast,
  togglePaymentPlansModal,
  toggleExpiredPlanModal
})(ExpiredPlanModal);

export default withRouter(ExpiredPlanModalContainer);
