import * as React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  togglePaymentPlansModal,
  toggleAuthToShow,
  toggleUserAuthModal,
  toggleGenerate,
  setCrosswordWordAndTitleData,
  toggleCrosswordCustomizeType,
  toggleToast
} from "../../../../../redux";
import { connect } from "react-redux";
import { paymentPlansModalTypes } from "../../../../Helpers/Variables";

export interface props {
  toggleCrosswordCustomizeType?: Function;
  toggleGenerate?: Function;
  setCrosswordWordAndTitleData?: Function;
  toggleAuthToShow?: Function;
  toggleUserAuthModal?: Function;
  togglePaymentPlansModal?: Function;
  isPremadeButton: boolean;
  isSaveButton: boolean;
  isPayingUser: boolean;
  isLoggedIn: boolean;
  wordsOnGrid: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  titleText: string;
  toggleToast: Function;
}

export interface state {
  isPayingUser: boolean;
  isLoggedIn: boolean;
}

const ReactStrapButton = styled(Button)<{}>`
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border: none !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
  width: 115px;
`;
export class CustomizeButton extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isPayingUser: false,
      isLoggedIn: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isLoggedIn?: boolean;
      isPayingUser?: boolean;
    } = {};

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }
    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    return Object.keys(update).length ? update : null;
  }

  handleCustomizeButton = () => {
    // if (!this.props.isLoggedIn) {
    //   this.props.toggleToast(
    //     true,
    //     "Please sign up or login to customize crosswords"
    //   );
    //   this.props.toggleUserAuthModal(true);
    //   this.props.toggleAuthToShow("register");
    // } else
    if (!this.props.isPayingUser) {
      this.props.togglePaymentPlansModal(true, paymentPlansModalTypes[0]);
    } else if (this.props.isPremadeButton && this.props.isPayingUser) {
      this.props.toggleCrosswordCustomizeType("preMade");
      this.props.setCrosswordWordAndTitleData(
        this.props.wordsOnGrid,
        this.props.titleText
      );
      // @ts-ignore
      this.props.history.push("/CrossWordFinalPage");
    } else if (this.props.isSaveButton && this.props.isPayingUser) {
      this.props.toggleCrosswordCustomizeType("saved");
      this.props.setCrosswordWordAndTitleData(
        this.props.wordsOnGrid,
        this.props.titleText
      );
      // @ts-ignore
      this.props.history.push("/CrossWordFinalPage");
    }
  };

  render(): JSX.Element {
    return (
      <React.Fragment>
        <ReactStrapButton onClick={() => this.handleCustomizeButton()}>
          Customize
        </ReactStrapButton>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const CustomizeButtonContainer = connect(mapStateToProps, {
  togglePaymentPlansModal,
  toggleAuthToShow,
  toggleUserAuthModal,
  toggleGenerate,
  setCrosswordWordAndTitleData,
  toggleCrosswordCustomizeType,
  toggleToast
})(CustomizeButton);

// @ts-ignore
export default withRouter(CustomizeButtonContainer);
