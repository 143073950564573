import * as React from "react";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
//@ts-ignore
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { connect } from "react-redux";
import { login } from "../../API/Auth";
import {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast,
  toggleIsPayingUser
} from "../../redux";
import ConfirmationCodeStep from "./ConfirmationCodeStep";
import PasswordForm from "../UserAuth/PasswordForm";
import { checkIfUserIsPaying } from "../../API/Payment";

export interface props {
  toggleUserAuthModal: Function;
  toggleIsLoggedIn: Function;
  toggleAuthToShow: Function;
  toggleToast: Function;
  toggleIsPayingUser: Function;
}

export interface state {
  emailText: string;
  passwordText: string;
  attemptingLogin: boolean;
  showConfirmationCodeStep: boolean;
}

const Div = styled("div")<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Inputs = styled("div")`
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
`;
const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const Footer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const ReactStrapButton = styled(Button)`
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 36px !important;
  width: 98px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const ForgotPasswordButton = styled(Button)`
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 18px !important;
  margin-top: 15px !important;
  width: 98px !important;
  font-size: 11px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
export class Login extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      emailText: "",
      passwordText: "",
      attemptingLogin: false,
      showConfirmationCodeStep: false
    };
  }

  attemptLogin = async () => {
    this.setState({
      attemptingLogin: true
    });

    try {
      await login(
        this.state.emailText,
        this.state.passwordText,
        this.props.toggleUserAuthModal,
        this.props.toggleIsLoggedIn
      );
    } catch (e) {
      // ask user to enter confirmation code
      if (e.response.data.code === "UserNotConfirmedException") {
        this.setState({
          attemptingLogin: false,
          showConfirmationCodeStep: true
        });
      } else {
        this.props.toggleToast(true, "Invalid email or password");
        this.setState({
          attemptingLogin: false
        });
      }
      return;
    }

    // check if user is paying
    try {
      await checkIfUserIsPaying(this.props.toggleIsPayingUser);
    } catch (e) {
      console.log(e);
    }

    this.setState({
      attemptingLogin: false
    });
  };

  forgotPassword = () => {
    this.props.toggleAuthToShow("forgotPassword");
  };

  render(): JSX.Element {
    return (
      <Div>
        {this.state.showConfirmationCodeStep ? (
          <ConfirmationCodeStep
            emailText={this.state.emailText}
            backToLogin={() => {
              this.setState({ showConfirmationCodeStep: false });
            }}
          />
        ) : (
          <React.Fragment>
            <Inputs>
              <StyledInputGroup>
                <InputGroupAddon className="titleLabel" addonType="append">
                  Email
                </InputGroupAddon>
                <Input
                  type="email"
                  className="loginInputs"
                  value={this.state.emailText}
                  onChange={e => {
                    this.setState({ emailText: e.target.value });
                  }}
                  style={{
                    border: "1px solid #70c989",
                    borderRadius: 0
                  }}
                />
              </StyledInputGroup>
              <PasswordForm
                showTooltip={false}
                tooltipId=""
                label="Password"
                passwordText={this.state.passwordText}
                confirmPassword={false}
                updatePassword={(passwordText: string) => {
                  this.setState({ passwordText });
                }}
              />
            </Inputs>
            <Footer>
              <ReactStrapButton
                onClick={() => this.attemptLogin()}
                disabled={this.state.attemptingLogin}
              >
                {this.state.attemptingLogin ? (
                  <Loader type="Rings" color="#fff" height={36} width={36} />
                ) : (
                  "Login"
                )}
              </ReactStrapButton>
              <ForgotPasswordButton
                onClick={() => this.forgotPassword()}
                disabled={this.state.attemptingLogin}
              >
                Forgot Password
              </ForgotPasswordButton>
            </Footer>
          </React.Fragment>
        )}
      </Div>
    );
  }
}

const LoginContainer = connect(null, {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast,
  toggleIsPayingUser
})(Login);

export default LoginContainer;
