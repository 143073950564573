import * as React from "react";
import styled from "styled-components";
import { Clues } from "./Clues";

export interface props {
  wordsOnGrid: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  horizontalShift: number;
  verticalShift: number;
  lowestMostYCoord: number;
  charactersOnGrid: { [key: string]: string };
  showAnswerKey: boolean;
  showTitle: boolean;
  showDateInput: boolean;
  showNameInput: boolean;
  showCustomInput1: boolean;
  customInput1Text: string;
  showCustomInput2: boolean;
  customInput2Text: string;
  bigifiedClueNumberLabel: boolean;
  VerticalClueListFontSizeInPx: number;
  HorizontalClueListFontSizeInPx: number;
  titleText: string;
  setClueListFontSize: Function;
  titleDivHeightInCm: number;
  puzzleBlockHeightInCm: number;
  totalHeightInCm: number;
  totalWidthInCm: number;
  maxXCoord: number;
  maxYCoord: number;
  moveCluesToAnotherPage: Function;
}

export interface state {
  HorizontalClueListFontSizeInPx: number;
  VerticalClueListFontSizeInPx: number;
  clueDivHeightInCm: number;
  grid: Array<any>;
  horizontalClues: Array<any>;
  verticalClues: Array<any>;
  showClueList: boolean;
}

export interface wordToPlaceObj {
  id: number;
  word: string;
  clue: string;
}
export interface placedWordObj {
  id: number;
  word: string;
  clue: string;
  orientation: string;
  startCoord: string;
  endCoord: string;
}

const Div = styled("div")<{
  totalHeightInCm: number;
  totalWidthInCm: number;
}>`
  background-color: white;
  height: ${props => props.totalHeightInCm}cm;
  width: ${props => props.totalWidthInCm}cm;
  position: relative;
`;
const TitleDiv = styled("div")<{ titleDivHeightInCm: number }>`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  height: ${props => props.titleDivHeightInCm}cm;
  // background-color: red;
  width: 100%;

  padding: 1cm 1cm 0 1cm;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;
const TitleText = styled("span")`
  font-weight: bold;
  font-size: 20px;
`;
const NameInput = styled("span")`
  position: absolute;
  left: 30px;
  top: 25px;
  font-size: 15px;
`;
const DateInput = styled("span")`
  position: absolute;
  left: 30px;
  top: 50px;
  font-size: 15px;
`;
const CustomInput1 = styled("span")`
  position: absolute;
  right: 30px;
  top: 25px;
  font-size: 15px;
`;
const CustomInput2 = styled("span")`
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 15px;
`;
const GridDiv = styled("div")<{
  titleDivHeightInCm: number;
  puzzleBlockHeightInCm: number;
  bigifiedClueNumberLabel: boolean;
  showAnswerKey: boolean;
}>`
  padding-top: ${props => props.titleDivHeightInCm}cm;
  display: flex;
  justify-content: center;

  .characterBox {
    height: ${props => props.puzzleBlockHeightInCm}cm;
    width: ${props => props.puzzleBlockHeightInCm}cm;
  }
  .emptyCharacterBox {
    height: ${props => props.puzzleBlockHeightInCm}cm;
    width: ${props => props.puzzleBlockHeightInCm}cm;
  }
  .wordId {
    font-size: ${props => (props.bigifiedClueNumberLabel ? null : "44%")};
    color: ${props => (props.bigifiedClueNumberLabel ? "#cdcdcd" : "black")};
  }
  .crosswordCharacter {
    display: ${props => (props.showAnswerKey ? null : "none")};
  }
`;
const CharacterBox = styled("div")`
  text-align: center;
  background-color: white;
  box-shadow: 1.6px 1.5px 0px 2.5px black;
  position: relative;
`;

const EmptyCharacterBox = styled("div")`
  // background-color: black;
  // box-shadow: 1.6px 1.5px 0px 2.5px black;
  // position: relative;
`;
const WordId = styled("span")`
  font-weight: bold;
  left: 0.5px;
  position: absolute;
  z-index: 1;
`;
const Character = styled("span")`
  color: #434343;
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 2;
`;
export class Preview extends React.Component<props, state> {
  Preview: any;

  constructor(props: props) {
    super(props);
    this.state = {
      HorizontalClueListFontSizeInPx: props.HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx: props.VerticalClueListFontSizeInPx,
      clueDivHeightInCm: 16,
      grid: [],
      horizontalClues: [],
      verticalClues: [],
      showClueList: true
    };
  }

  componentDidMount = () => {
    this.setGrid();
  };

  getWordId = (xCoord: number, yCoord: number) => {
    // unshift after this.centerPuzzle() has run to find proper placement of words
    xCoord -= this.props.horizontalShift;
    yCoord -= this.props.verticalShift;

    const { wordsOnGrid } = this.props;
    for (const wordObj of wordsOnGrid) {
      if (wordObj.startCoord === `${xCoord},${yCoord}`) {
        return wordObj.id;
      }
    }
  };

  setGrid = () => {
    let grid = [];

    for (let x = 0; x < this.props.maxXCoord + 1; x += 1) {
      let gridLine = [];
      for (let y = this.props.maxYCoord; y >= 0; y -= 1) {
        const { charactersOnGrid } = this.props;
        if (`${x},${y}` in charactersOnGrid) {
          gridLine.push(
            <CharacterBox className="characterBox" key={x + y}>
              <WordId className="wordId">{this.getWordId(x, y)}</WordId>
              <Character className="crosswordCharacter">
                {charactersOnGrid[`${x},${y}`]}
              </Character>
            </CharacterBox>
          );
        } else {
          gridLine.push(
            <EmptyCharacterBox className="emptyCharacterBox" key={x + y}>
              {charactersOnGrid[`${x},${y}`]}
            </EmptyCharacterBox>
          );
        }
      }
      // add line to the grid
      grid.push(<div key={x}>{gridLine}</div>);
      // start new line on grid
      grid.push(<br key={x + 2222} />);
    }
    this.setState({ grid });
  };

  getGrid = () => {
    return (
      <GridDiv
        titleDivHeightInCm={this.props.titleDivHeightInCm}
        puzzleBlockHeightInCm={this.props.puzzleBlockHeightInCm}
        bigifiedClueNumberLabel={this.props.bigifiedClueNumberLabel}
        showAnswerKey={this.props.showAnswerKey}
      >
        {this.state.grid}
      </GridDiv>
    );
  };

  getTitle = () => {
    return (
      <TitleDiv titleDivHeightInCm={this.props.titleDivHeightInCm}>
        {this.props.showNameInput === true ? (
          <NameInput>Name ___________</NameInput>
        ) : null}
        {this.props.showDateInput === true ? (
          <DateInput>Date ___________</DateInput>
        ) : null}
        {this.props.showTitle === true ? (
          <TitleText>{this.props.titleText}</TitleText>
        ) : null}
        {this.props.showCustomInput1 === true ? (
          <CustomInput1>{this.props.customInput1Text} ___________</CustomInput1>
        ) : null}
        {this.props.showCustomInput2 === true ? (
          <CustomInput2>{this.props.customInput2Text} ___________</CustomInput2>
        ) : null}
      </TitleDiv>
    );
  };

  getClues = () => {
    return (
      <Clues
        wordsOnGrid={this.props.wordsOnGrid}
        lowestMostYCoord={this.props.lowestMostYCoord}
        showAnswerKey={this.props.showAnswerKey}
        VerticalClueListFontSizeInPx={this.props.VerticalClueListFontSizeInPx}
        HorizontalClueListFontSizeInPx={
          this.props.HorizontalClueListFontSizeInPx
        }
        setClueListFontSize={this.props.setClueListFontSize}
        titleDivHeightInCm={this.props.titleDivHeightInCm}
        puzzleBlockHeightInCm={this.props.puzzleBlockHeightInCm}
        totalHeightInCm={this.props.totalHeightInCm}
        maxYCoord={this.props.maxYCoord}
        moveCluesToAnotherPage={this.moveCluesToAnotherPage}
        isOnSecondPage={false}
      />
    );
  };

  moveCluesToAnotherPage = () => {
    this.setState({ showClueList: false });
    this.props.moveCluesToAnotherPage();
  };

  render(): JSX.Element {
    return (
      <Div
        totalHeightInCm={this.props.totalHeightInCm}
        totalWidthInCm={this.props.totalWidthInCm}
        id="crosswordEditPage"
      >
        {this.getTitle()}
        {this.getGrid()}
        {this.state.showClueList ? this.getClues() : null}
      </Div>
    );
  }
}
