import * as React from "react";
import { InputGroup, InputGroupAddon, Input, Tooltip } from "reactstrap";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow
} from "../../redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
  faEye,
  faEyeSlash
} from "@fortawesome/free-solid-svg-icons";

export interface props {
  showTooltip: boolean;
  tooltipId: string;
  label: string;
  passwordText: string;
  confirmPassword: boolean;
  updatePassword: Function;
  toggleUserAuthModal: Function;
  toggleIsLoggedIn: Function;
  toggleAuthToShow: Function;
  validate?: Function;
  matchesPassword?: boolean;
}

export interface state {
  passwordTooltip: boolean;
  "8chars": boolean;
  lowerCaseLetter: boolean;
  upperCaseLetter: boolean;
  number: boolean;
  special: boolean;
  showPassword: boolean;
}

const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const TooltipStyled = styled(Tooltip)`
  .tooltip.show {
    opacity: 1;
  }
  .tooltip-inner {
    min-width: 235px;
  }
`;
const TooltipBody = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: left;
  .icon {
    margin: 4px 6px 0;
  }
`;
const colorAnimation = (props: { valid: boolean }) => keyframes`
  from {
    color:  ${props.valid ? "red" : "green"};
  }
  to {
    color:  ${props.valid ? "green" : "red"};
  }
`;
const TooltipTextDiv = styled("div")<{ valid: boolean }>`
  display: flex;
  margin-bottom: 5px;
  color: ${props => (props.valid ? "green" : "red")};
  animation: ${colorAnimation} 0.3s ease;
  font-weight: 600;
`;
const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 17px;
  margin-right: 8px;
  z-index: 3;
`;
export class PasswordForm extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      passwordTooltip: false,
      "8chars": false,
      lowerCaseLetter: false,
      upperCaseLetter: false,
      number: false,
      special: false,
      showPassword: false
    };
  }

  componentDidUpdate(prevProps: props, prevState: state) {
    if (prevProps.passwordText !== this.props.passwordText) {
      this.checkPasswordIsValid();
    }
  }

  checkPasswordIsValid = () => {
    const { passwordText } = this.props;

    // "8chars"
    if (passwordText.length >= 8) {
      this.setState({
        "8chars": true
      });
    } else {
      this.setState({
        "8chars": false
      });
    }

    // lowerCaseLetter
    if (/[a-z]/.test(passwordText)) {
      this.setState({
        lowerCaseLetter: true
      });
    } else {
      this.setState({
        lowerCaseLetter: false
      });
    }

    // upperCaseLetter
    if (/[A-Z]/.test(passwordText)) {
      this.setState({
        upperCaseLetter: true
      });
    } else {
      this.setState({
        upperCaseLetter: false
      });
    }

    // number
    if (/[0-9]/.test(passwordText)) {
      this.setState({
        number: true
      });
    } else {
      this.setState({
        number: false
      });
    }

    // special
    if (/[#?!@$%^&*-]/.test(passwordText)) {
      this.setState({
        special: true
      });
    } else {
      this.setState({
        special: false
      });
    }
  };

  validatePassword = () => {
    if (this.props.validate !== undefined && this.props.validate !== null) {
      if (
        this.state["8chars"] &&
        this.state.lowerCaseLetter &&
        this.state.upperCaseLetter &&
        this.state.number &&
        this.state.special
      ) {
        this.props.validate(true);
      } else {
        this.props.validate(false);
      }
    }
  };

  toggleTooltip = () => {
    this.setState({
      passwordTooltip: !this.state.passwordTooltip
    });
  };

  getIcon = (validation: boolean) => {
    if (validation) {
      return <FontAwesomeIcon icon={faCheckCircle} className="icon" />;
    }
    return <FontAwesomeIcon icon={faTimesCircle} className="icon" />;
  };

  getTooltip = () => {
    if (this.props.showTooltip && this.props.confirmPassword) {
      return (
        <TooltipStyled
          className="tooltipClass"
          isOpen={this.state.passwordTooltip}
          placement="top"
          target={this.props.tooltipId}
        >
          <TooltipBody>
            <TooltipTextDiv valid={this.props.matchesPassword}>
              {this.getIcon(this.props.matchesPassword)}
              <div>Matches password</div>
            </TooltipTextDiv>
          </TooltipBody>
        </TooltipStyled>
      );
    }
    if (this.props.showTooltip) {
      return (
        <TooltipStyled
          className="tooltipClass"
          isOpen={this.state.passwordTooltip}
          placement="top"
          target={this.props.tooltipId}
        >
          <TooltipBody>
            <TooltipTextDiv valid={this.state["8chars"]}>
              {this.getIcon(this.state["8chars"])}
              <div>At least 8 characters in length</div>
            </TooltipTextDiv>
            <TooltipTextDiv valid={this.state.lowerCaseLetter}>
              {this.getIcon(this.state.lowerCaseLetter)}
              <div>Lower case letters (a-z)</div>
            </TooltipTextDiv>
            <TooltipTextDiv valid={this.state.upperCaseLetter}>
              {this.getIcon(this.state.upperCaseLetter)}
              <div>Upper case letters (A-Z)</div>
            </TooltipTextDiv>
            <TooltipTextDiv valid={this.state.number}>
              {this.getIcon(this.state.number)}
              <div>Numbers (0-9) </div>
            </TooltipTextDiv>
            <TooltipTextDiv valid={this.state.special}>
              {this.getIcon(this.state.special)}
              <div>Special characters (i.e. #@*) </div>
            </TooltipTextDiv>
          </TooltipBody>
        </TooltipStyled>
      );
    }
  };

  render(): JSX.Element {
    return (
      <StyledInputGroup>
        {this.getTooltip()}
        <InputGroupAddon className="titleLabel" addonType="append">
          {this.props.label}
        </InputGroupAddon>
        <Input
          id={this.props.tooltipId}
          onFocus={() => this.toggleTooltip()}
          onBlur={() => {
            this.toggleTooltip();
            this.validatePassword();
          }}
          type={this.state.showPassword ? "text" : "password"}
          className="loginInputs"
          value={this.props.passwordText}
          onChange={e => {
            this.props.updatePassword(e.target.value);
          }}
          style={{
            borderRadius: 0
          }}
        />
        <FontAwesomeIconStyled
          color="#2a3840"
          icon={this.state.showPassword ? faEyeSlash : faEye}
          onClick={() =>
            this.setState({ showPassword: !this.state.showPassword })
          }
        />
      </StyledInputGroup>
    );
  }
}

const PasswordFormContainer = connect(null, {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow
})(PasswordForm);

export default PasswordFormContainer;
