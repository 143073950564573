import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

export interface props {}

export interface state {}

const Div = styled("div")`
  margin: 20px 20px 20px 20px;
  flex: 1;
  background-color: #3c4c55;
  padding: 20px 20px 20px 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
`;
const Header = styled("div")`
  padding-top: 20px;
  color: white;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
`;
const Description = styled("div")`
  width: 100%;
  text-align: left;
  color: white;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1.5;
`;
export class TermsAndConditions extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    return (
      <Div>
        <Title>Terms And Conditions</Title>
        <Header>Overview</Header>
        <Description>
          By accessing this website we assume you accept these terms and
          conditions in full. Do not continue to use WordyMaker's website if you
          do not accept all of the terms and conditions stated on this page. The
          following terminology applies to these Terms and Conditions, Privacy
          Statement and Disclaimer Notice and any or all Agreements: "Client",
          "You" and "Your" refers to you, the person accessing this website and
          accepting the Company's terms and conditions. "The Company",
          "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
          "Parties", or "Us", refers to both the Client and ourselves, or either
          the Client or ourselves. All terms refer to the offer, acceptance and
          consideration of payment necessary to undertake the process of our
          assistance to the Client in the most appropriate manner, whether by
          formal meetings of a fixed duration, or any other means, for the
          express purpose of meeting the Client's needs in respect of provision
          of the Company's stated services/products, in accordance with and
          subject to, prevailing law. Any use of the above terminology or other
          words in the singular, plural, capitalisation and/or he/she or they,
          are taken as interchangeable and therefore as referring to same.
        </Description>
        <Header>Refunds</Header>
        <Description>
          You can request a full refund for the Limited plan within 30 days of
          purchase. Refunds will not be granted for payments made for the
          Unlimited Monthly or Unlimited Yearly plan. The contact email for
          refunds can be found on the 'Contact Us' page.
        </Description>
        <Header>Subscription Cancellation</Header>
        <Description>
          You can cancel an active subscription or trial at any time through the
          'Payment' section of the 'Profile' page
        </Description>
        <Header>Pricing</Header>
        <Description>All prices listed are in US Dollars.</Description>
        <Header>Cookies</Header>
        <Description>
          We employ the use of cookies. By using WordyMaker's website you
          consent to the use of cookies in accordance with WordyMaker's privacy
          policy.Most of the modern day interactive web sites use cookies to
          enable us to retrieve user details for each visit. Cookies are used in
          some areas of our site to enable the functionality of this area and
          ease of use for those people visiting. Some of our affiliate /
          advertising partners may also use cookies.
        </Description>
        <Header>License</Header>
        <Description>
          Unless otherwise stated, WordyMaker and/or it's licensors own the
          intellectual property rights for all material on WordyMaker. All
          intellectual property rights are reserved. You may view and/or print
          pages from https://wordymaker.com for your own personal use subject to
          restrictions set in these terms and conditions. You must not:
          <ul>
            <li>Republish material from https://wordymaker.com</li>
            <li>
              Sell, rent or sub-license material from https://wordymaker.com
            </li>
            <li>
              Reproduce, duplicate or copy material from https://wordymaker.com
            </li>
            <li>
              Redistribute content from WordyMaker (unless content is
              specifically made for redistribution)
            </li>
          </ul>
        </Description>
        <Header>Reservation of Rights</Header>
        <Description>
          We reserve the right to amend these terms and conditions and its
          linking policy at any time.
        </Description>
        <Header>Removal of links from our website</Header>
        <Description>
          If you find any link on our Web site or any linked web site
          objectionable for any reason, you may contact us about this. We will
          consider requests to remove links but will have no obligation to do so
          or to respond directly to you. Whilst we endeavour to ensure that the
          information on this website is correct, we do not warrant its
          completeness or accuracy; nor do we commit to ensuring that the
          website remains available or that the material on the website is kept
          up to date.
        </Description>
        <Header>Disclaimer</Header>
        <Description>
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
          NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
          FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
          WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
          WHERE APPROPRIATE.
        </Description>
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const TermsAndConditionsContainer = connect(
  mapStateToProps,
  {}
)(TermsAndConditions);

export default TermsAndConditionsContainer;
