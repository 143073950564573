import * as React from "react";
import styled from "styled-components";
import { Button, Modal, InputGroup } from "reactstrap";
import { connect } from "react-redux";
import Switch from "react-switch";
import {
  togglePaymentPlansModal,
  togglePaymentFormModal,
  toggleToast,
  toggleUserAuthModal,
  toggleAuthToShow,
  setProductIdToCheckIfAlreadySaved
} from "../../redux";
// @ts-ignore
import Loader from "react-loader-spinner";
import { redirectToStripeCheckoutSession } from "../../API/Payment";
import { saveProduct } from "../../API/SavedProducts";
import { paymentPlansInterface, nameOfAllProducts } from "../Helpers/Variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { stripePublishableKey } from "../../apiconfig";

export interface props {
  showPaymentPlansModal: boolean;
  togglePaymentPlansModal: Function;
  paymentPlansModalType: string;
  togglePaymentFormModal: Function;
  paymentPlans: Array<paymentPlansInterface>;
  isLoggedIn: boolean;
  toggleToast: Function;
  toggleUserAuthModal: Function;
  toggleAuthToShow: Function;
  showPurchaseButtonsInModal: boolean;
  productType: string;
  setProductIdToCheckIfAlreadySaved: Function;
  productIdToCheckIfAlreadySaved: string;
  showCrosswordCreatePreviewModal: boolean;
  crosswordRawDataToSave: any;
}

export interface state {
  showPaymentPlansModal: boolean;
  paymentPlansModalType: string;
  paymentPlans: Array<paymentPlansInterface>;
  isLoggedIn: boolean;
  attemptingRedirectToCheckoutSession: boolean;
  showPurchaseButtonsInModal: boolean;
  showLegalModal: boolean;
  legalAcknowledge: boolean;
  productType: string;
  productIdToCheckIfAlreadySaved: string;
  showCrosswordCreatePreviewModal: boolean;
  crosswordRawDataToSave: any;
  stripe: any;
  selectedPaymentPlan: paymentPlansInterface;
}

const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  .modal-content {
    width: unset;
    display: flex;
    border-radius: 0 !important;
    border: none;
    background-color: transparent;
  }
`;
const CloseButton = styled("div")`
  position: absolute;
  align-self: flex-end;
  // border: 1px solid #70c989;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3c4c55;
  width: 36px;
  font-weight: 700;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  color: #ffffff;
`;
const StyledModalBody = styled("div")`
  background-color: #3c4c55;
`;
const PricingTable = styled("div")`
  display: flex;
  padding: 0 36px 36px 36px;
`;
const TableColumn = styled("div")`
  display: flex;
  flex-direction: column;
  .category {
    color: white;
    flex-direction: row;
    justify-content: unset;
  }
  height: 450px;
  min-width: 175px !important;
`;
const TableCell = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 10px;
  flex-grow: 1;
  font-weight: 500;
  border-bottom: solid #2a3840 2px;
  color: #70c989;
  .checkIcon {
    color: #70c989;
  }
  .crossIcon {
    color: red;
  }
`;
const PurchaseButton = styled(Button)`
  font-weight: 600 !important;
  color: #3c4c55 !important;
  width: 175px !important;
  background-color: #70c989 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #63ad78 !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #63ad78 !important;
  }
`;
const PlanText = styled("span")`
  color: white;
  font-size: 18px;
  // font-weight: bold;
  margin-bottom: 10px;
`;
const MoneyLabel = styled("span")`
  color: #70c989;
  font-size: 18px;
  // font-weight: bold;
  margin-bottom: 10px;
`;
const SavingsText = styled("div")`
  color: #ffffff;
  font-size: 10px;
  text-align: center;
`;
const Description = styled("div")`
  padding-top: 30px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 20px;
`;
const StepText = styled("div")`
  color: white;
  max-width: 270px;
  font-size: 15px;
`;
const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
  .categoryLabel {
    height: 30px !important;
  }
`;
const EmailDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Link = styled("span")`
  color: #70c989;
  &:hover {
    cursor: pointer;
  }
`;
export class PaymentPlansModal extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      showPaymentPlansModal: false,
      paymentPlansModalType: "",
      paymentPlans: [],
      isLoggedIn: false,
      attemptingRedirectToCheckoutSession: false,
      showPurchaseButtonsInModal: true,
      showLegalModal: false,
      legalAcknowledge: false,
      // everything below here is to save an open creation before navigating to payment
      productType: "",
      productIdToCheckIfAlreadySaved: "",
      showCrosswordCreatePreviewModal: false,
      crosswordRawDataToSave: {},
      stripe: {},
      selectedPaymentPlan: {
        id: "",
        plan: "",
        amountInCents: 0,
        moneyText: "",
        currency: "",
        oneTimePayment: false,
        payFormDescription: "",
        savingsText: "",
        promotionText: "",
        downloadYourOwn: "",
        downloadPremade: "",
        saves: "",
        customization: false,
        noAds: false,
        purchaseButtonText: "",
        showPurchaseButton: false,
        restrictMaxPrints: false,
        trialDays: 0
      }
    };
  }

  componentDidMount() {
    this.setState({
      // @ts-ignore
      stripe: Stripe(stripePublishableKey)
    });
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      showPaymentPlansModal?: boolean;
      paymentPlansModalType?: string;
      paymentPlans?: Array<paymentPlansInterface>;
      isLoggedIn?: boolean;
      showPurchaseButtonsInModal?: boolean;
      productType?: string;
      productIdToCheckIfAlreadySaved?: string;
      crosswordRawDataToSave?: any;
      showCrosswordCreatePreviewModal?: boolean;
    } = {};

    if (nextProps.showPaymentPlansModal !== prevState.showPaymentPlansModal) {
      update.showPaymentPlansModal = nextProps.showPaymentPlansModal;
    }

    if (nextProps.paymentPlansModalType !== prevState.paymentPlansModalType) {
      update.paymentPlansModalType = nextProps.paymentPlansModalType;
    }

    if (nextProps.paymentPlans !== prevState.paymentPlans) {
      update.paymentPlans = nextProps.paymentPlans;
    }

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }

    if (
      nextProps.showPurchaseButtonsInModal !==
      prevState.showPurchaseButtonsInModal
    ) {
      update.showPurchaseButtonsInModal = nextProps.showPurchaseButtonsInModal;
    }

    if (nextProps.productType !== prevState.productType) {
      update.productType = nextProps.productType;
    }

    if (
      nextProps.productIdToCheckIfAlreadySaved !==
      prevState.productIdToCheckIfAlreadySaved
    ) {
      update.productIdToCheckIfAlreadySaved =
        nextProps.productIdToCheckIfAlreadySaved;
    }

    if (nextProps.crosswordRawDataToSave !== prevState.crosswordRawDataToSave) {
      update.crosswordRawDataToSave = nextProps.crosswordRawDataToSave;
    }

    if (
      nextProps.showCrosswordCreatePreviewModal !==
      prevState.showCrosswordCreatePreviewModal
    ) {
      update.showCrosswordCreatePreviewModal =
        nextProps.showCrosswordCreatePreviewModal;
    }

    return Object.keys(update).length ? update : null;
  }

  handleRedirectToStripeCheckoutSession = async (paymentPlanId: string) => {
    // user isn't logged in:
    if (this.state.isLoggedIn === false) {
      this.props.toggleToast(
        true,
        "Please sign up or sign in to continue with the purchase"
      );
      this.props.toggleUserAuthModal(true);
      this.props.toggleAuthToShow("register");
      return;
    }

    // user is logged in:
    if (this.state.isLoggedIn === true) {
      this.setState({
        attemptingRedirectToCheckoutSession: true
      });

      // if a creation is open... save it... haha.. woah..
      try {
        await this.saveCreation();
      } catch (e) {
        console.log(e);
      }

      let result = null;
      try {
        result = await redirectToStripeCheckoutSession(paymentPlanId);
      } catch (e) {
        // @ts-ignore
        this.props.toggleToast(
          true,
          "Unable to purchase, please try again later"
        );
        this.setState({
          attemptingRedirectToCheckoutSession: false
        });
      }

      // redirect to checkout page
      let error = null;
      try {
        ({ error } = await this.state.stripe.redirectToCheckout({
          sessionId: result.id
        }));
      } catch (e) {
        console.log(error);
        this.props.toggleToast(
          true,
          "Unable to purchase, please try again later"
        );
        this.setState({
          attemptingRedirectToCheckoutSession: false
        });
      }

      this.setState({
        attemptingRedirectToCheckoutSession: false
      });
    }
  };

  saveCreation = async () => {
    // verify that a creation is open, cuz if a creation isn't open, then don't bother saving
    if (!this.state.showCrosswordCreatePreviewModal) {
      return;
    }

    let rawDataToSave = null;
    if (this.state.productType === nameOfAllProducts[0]) {
      rawDataToSave = this.state.crosswordRawDataToSave;
    }

    let result = null;
    try {
      result = await saveProduct(
        this.state.productType,
        rawDataToSave,
        this.state.productIdToCheckIfAlreadySaved,
        null,
        true,
        this.props.setProductIdToCheckIfAlreadySaved
      );
    } catch (e) {
      console.log(e);
    }
  };

  getPaymentBody = () => {
    const plans = [];

    plans.push(
      <TableColumn key={Math.random()}>
        <TableCell
          className="category"
          style={{ order: 0, minHeight: "100px" }}
        ></TableCell>
        <TableCell className="category" style={{ order: 1 }}>
          Download Your Own
        </TableCell>
        <TableCell className="category" style={{ order: 2 }}>
          Download Premade
        </TableCell>
        <TableCell className="category" style={{ order: 3 }}>
          Saves
        </TableCell>
        <TableCell className="category" style={{ order: 4 }}>
          Customization
        </TableCell>
        <TableCell className="category" style={{ order: 5 }}>
          No Ads
        </TableCell>
        <TableCell
          className="category"
          style={{ order: 6, border: "unset", minHeight: "65px" }}
        ></TableCell>
      </TableColumn>
    );

    for (let i = 0; i < this.state.paymentPlans.length; i += 1) {
      plans.push(this.getIndividualPlanColumn(i));
    }

    return <PricingTable>{plans}</PricingTable>;
  };

  getIndividualPlanColumn = (index: number) => {
    const paymentPlan = this.state.paymentPlans[index];

    return (
      <TableColumn key={index}>
        <TableCell style={{ order: 0, minHeight: "100px" }}>
          <PlanText>{paymentPlan.plan}</PlanText>
          <MoneyLabel>{paymentPlan.moneyText}</MoneyLabel>
          <SavingsText>{paymentPlan.savingsText}</SavingsText>
        </TableCell>
        <TableCell style={{ order: 1 }}>
          {paymentPlan.downloadYourOwn}
        </TableCell>
        <TableCell style={{ order: 2 }}>
          {paymentPlan.downloadPremade}
        </TableCell>
        <TableCell style={{ order: 3 }}>{paymentPlan.saves}</TableCell>
        <TableCell style={{ order: 4 }}>
          {paymentPlan.customization ? (
            <FontAwesomeIcon icon={faCheck} className="checkIcon" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="crossIcon" />
          )}
        </TableCell>
        <TableCell style={{ order: 5 }}>
          {paymentPlan.noAds ? (
            <FontAwesomeIcon icon={faCheck} className="checkIcon" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="crossIcon" />
          )}
        </TableCell>
        <TableCell style={{ order: 6, border: "unset", minHeight: "65px" }}>
          {paymentPlan.showPurchaseButton &&
          this.state.showPurchaseButtonsInModal ? (
            <PurchaseButton
              key={index}
              id="purchaseButton"
              onClick={() => {
                this.setState({
                  showLegalModal: true,
                  selectedPaymentPlan: paymentPlan
                });
              }}
            >
              {paymentPlan.purchaseButtonText}
            </PurchaseButton>
          ) : null}
        </TableCell>
      </TableColumn>
    );
  };

  getLegalConfirmationModal = () => {
    return (
      <StyledModal
        toggle={() =>
          this.setState({
            showLegalModal: false,
            legalAcknowledge: false
          })
        }
        isOpen={this.state.showLegalModal}
        centered={true}
      >
        <CloseButton
          onClick={() =>
            this.setState({
              showLegalModal: false,
              legalAcknowledge: false
            })
          }
        >
          X
        </CloseButton>
        <StyledModalBody style={{ padding: "50px" }}>
          <StyledInputGroup style={{ justifyContent: "center" }}>
            <EmailDiv>
              <StepText style={{ marginRight: "30px" }}>
                <span>
                  Do you acknowledge that you have read and agree to the terms
                  of the{" "}
                  <Link
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/PrivacyPolicy`,
                        "_blank"
                      )
                    }
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/TermsAndConditions`,
                        "_blank"
                      )
                    }
                  >
                    Terms and Conditions
                  </Link>
                  ?
                </span>
              </StepText>
              <Switch
                offColor="#252a31"
                onColor="#6d7b8e"
                onChange={() => {
                  this.setState({
                    legalAcknowledge: !this.state.legalAcknowledge
                  });
                }}
                checked={this.state.legalAcknowledge}
              />
            </EmailDiv>
            <PurchaseButton
              id="purchaseButton"
              disabled={
                this.state.attemptingRedirectToCheckoutSession ||
                !this.state.legalAcknowledge
              }
              onClick={() => {
                this.handleRedirectToStripeCheckoutSession(
                  this.state.selectedPaymentPlan.id
                );
              }}
              style={{ marginTop: "50px" }}
            >
              {this.state.attemptingRedirectToCheckoutSession ? (
                <Loader type="Rings" color="#3c4c55" height={25} width={36} />
              ) : (
                this.state.selectedPaymentPlan.purchaseButtonText
              )}
            </PurchaseButton>
          </StyledInputGroup>
        </StyledModalBody>
      </StyledModal>
    );
  };

  render(): JSX.Element {
    return (
      <div>
        {this.getLegalConfirmationModal()}
        <StyledModal
          toggle={() =>
            this.props.togglePaymentPlansModal(
              false,
              this.state.paymentPlansModalType,
              this.state.showPurchaseButtonsInModal
            )
          }
          isOpen={this.state.showPaymentPlansModal}
          centered={true}
        >
          <CloseButton
            onClick={() =>
              this.props.togglePaymentPlansModal(
                false,
                this.state.paymentPlansModalType,
                this.state.showPurchaseButtonsInModal
              )
            }
          >
            X
          </CloseButton>
          <StyledModalBody>
            {this.state.showPurchaseButtonsInModal ? (
              <Description>
                Unlock sitewide access to {this.state.paymentPlansModalType} and
                much more!
              </Description>
            ) : null}
            {this.getPaymentBody()}
          </StyledModalBody>
        </StyledModal>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    showPaymentPlansModal: state.paymentReducer.showPaymentPlansModal,
    paymentPlansModalType: state.paymentReducer.paymentPlansModalType,
    showPurchaseButtonsInModal: state.paymentReducer.showPurchaseButtonsInModal,
    paymentPlans: state.paymentReducer.paymentPlans,
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    productType: state.paymentReducer.productType,
    productIdToCheckIfAlreadySaved:
      state.saveProductReducer.productIdToCheckIfAlreadySaved,
    showCrosswordCreatePreviewModal:
      state.crosswordReducer.showCrosswordCreatePreviewModal,
    crosswordRawDataToSave: state.saveProductReducer.crosswordRawDataToSave
  };
}

const PaymentPlansModalContainer = connect(mapStateToProps, {
  togglePaymentPlansModal,
  togglePaymentFormModal,
  toggleToast,
  toggleUserAuthModal,
  toggleAuthToShow,
  setProductIdToCheckIfAlreadySaved
})(PaymentPlansModal);

export default PaymentPlansModalContainer;
