import * as React from "react";
import styled from "styled-components";
import { Preview } from "./Preview/CrosswordPreview";
import EditModal from "./CrosswordEditModal";
import { Clues } from "./Preview/Clues";
import UnplacedWords from "./Preview/UnplacedWords";
import { store } from "../../../redux";
import { connect } from "react-redux";
import {
  toggleGenerate,
  toggleCrosswordCreatePreviewModal,
  toggleCrosswordPremadePreviewModal,
  toggleCrosswordSavedPreviewModal,
  toggleToast,
  setRawDataToSave,
  setProductIdToCheckIfAlreadySaved,
  setCrosswordTitleText
} from "../../../redux";
//@ts-ignore
import Loader from "react-loader-spinner";
import { getPremadeProductById } from "../../../API/PremadeProducts";
import { getSavedProductById } from "../../../API/SavedProducts";
import { getCrosswordInfo } from "../../../API/Algos";
import { nameOfAllProducts } from "../../Helpers/Variables";

export interface props {
  words: Array<{
    id: number;
    word: string;
    clue: string;
  }>;
  titleText: string;
  toggleCrosswordCreatePreviewModal: Function;
  toggleCrosswordPremadePreviewModal: Function;
  toggleGenerate: Function;
  toggleCrosswordSavedPreviewModal: Function;
  generate: boolean;
  premadeCrosswordId: string;
  toggleToast: Function;
  setRawDataToSave: Function;
  setProductIdToCheckIfAlreadySaved: Function;
  customizeType: string;
  savedCrosswordId: string;
  setCrosswordTitleText: Function;
}

export interface state {
  words: Array<{
    id: number;
    word: string;
    clue: string;
  }>;
  wordsOnGrid: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  charactersOnGrid: { [key: string]: string };
  maxWordSize: number;
  HorizontalClueListFontSizeInPx: number;
  VerticalClueListFontSizeInPx: number;
  lowestMostYCoord: number;
  horizontalShift: number;
  verticalShift: number;
  showAnswerKey: boolean;
  showTitle: boolean;
  showDateInput: boolean;
  showNameInput: boolean;
  customInput1Text: string;
  showCustomInput1: boolean;
  customInput2Text: string;
  showCustomInput2: boolean;
  bigifiedClueNumberLabel: boolean;
  titleText: string;
  puzzleBlockHeightInCm: number;
  changedPuzzleBlockHeightInCm: number;
  puzzleLoaded: boolean;
  blockSizeChanged: boolean;
  maxXCoord: number;
  changedMaxXCoord: number;
  maxXCoordHasChanged: boolean;
  maxAllowedXCoord: number;
  maxYCoord: number;
  changedMaxYCoord: number;
  maxYCoordHasChanged: boolean;
  maxAllowedYCoord: number;
  totalHeightInCm: number;
  totalWidthInCm: number;
  changedTotalHeightInCm: number;
  changedTotalWidthInCm: number;
  sizeHasChanged: boolean;
  showPageWithClues: boolean;
  showUnplacedWords: boolean;
  unplacedWords: Array<{
    id: number;
    word: string;
    clue: string;
  }>;
  generate: boolean;
  premadeCrosswordId: string;
  customizeType: string;
  savedCrosswordId: string;
}
export interface wordToPlaceObj {
  id: number;
  word: string;
  clue: string;
}
export interface placedWordObj {
  id: number;
  word: string;
  clue: string;
  orientation: string;
  startCoord: string;
  endCoord: string;
}

const EditPage = styled("div")<{ totalHeightInCm: number }>`
  max-height: ${props => props.totalHeightInCm}cm;
  display: flex;
  position: relative;
`;
const PreviewSizedPage = styled("div")<{
  totalHeightInCm: number;
  totalWidthInCm: number;
}>`
  background-color: white;
  height: ${props => props.totalHeightInCm}cm;
  width: ${props => props.totalWidthInCm}cm;
  margin-left: 2px;
  position: relative;
`;

export class CrosswordFinalPage extends React.Component<props, state> {
  titleDivHeightInCm: number;

  EditPage: any;
  maxXCoord: number;
  maxYCoord: number;

  constructor(props: props) {
    super(props);

    this.titleDivHeightInCm = 2.5;

    this.state = {
      words: [],
      wordsOnGrid: [],
      charactersOnGrid: {},
      maxWordSize: 0,
      HorizontalClueListFontSizeInPx: 16,
      VerticalClueListFontSizeInPx: 16,
      lowestMostYCoord: 0,
      horizontalShift: 0,
      verticalShift: 0,
      showAnswerKey: false,
      showTitle: true,
      showDateInput: false,
      showNameInput: false,
      customInput1Text: "",
      showCustomInput1: false,
      customInput2Text: "",
      showCustomInput2: false,
      bigifiedClueNumberLabel: false,
      titleText: "",
      puzzleBlockHeightInCm: 0.6,
      changedPuzzleBlockHeightInCm: 0.6,
      puzzleLoaded: false,
      blockSizeChanged: false,
      maxXCoord: 0,
      changedMaxXCoord: 0,
      maxXCoordHasChanged: false,
      maxAllowedXCoord: 0,
      maxYCoord: 0,
      changedMaxYCoord: 0,
      maxYCoordHasChanged: false,
      maxAllowedYCoord: 0,
      totalHeightInCm: 29.7,
      totalWidthInCm: 21,
      changedTotalHeightInCm: 29.7,
      changedTotalWidthInCm: 21,
      sizeHasChanged: false,
      showPageWithClues: false,
      showUnplacedWords: false,
      unplacedWords: [],
      generate: false,
      premadeCrosswordId: "",
      customizeType: "",
      savedCrosswordId: ""
    };

    this.maxXCoord = this.getMaxAllowedXCoord();
    this.maxYCoord = this.getMaxAllowedYCoord();
  }

  componentDidMount() {
    // ensure all crossword modals are closed when going to preview page so that they don't remain open when going back
    this.props.toggleCrosswordCreatePreviewModal(false);
    this.props.toggleCrosswordPremadePreviewModal(false);
    this.props.toggleCrosswordSavedPreviewModal(false);

    this.sortWords();
    this.setState(
      {
        maxXCoord: this.maxXCoord,
        changedMaxXCoord: this.maxXCoord,
        maxAllowedXCoord: this.maxXCoord,
        maxYCoord: this.maxYCoord,
        changedMaxYCoord: this.maxYCoord,
        maxAllowedYCoord: this.maxYCoord
      },
      () => {
        this.createCrosswordGrid();
      }
    );
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      generate?: boolean;
      premadeCrosswordId?: string;
      words?: any;
      titleText?: string;
      customizeType?: string;
      savedCrosswordId?: string;
    } = {};

    if (nextProps.generate !== prevState.generate) {
      update.generate = nextProps.generate;
    }

    if (nextProps.premadeCrosswordId !== prevState.premadeCrosswordId) {
      update.premadeCrosswordId = nextProps.premadeCrosswordId;
    }

    if (nextProps.words !== prevState.words) {
      update.words = nextProps.words;
    }

    if (nextProps.titleText !== prevState.titleText) {
      update.titleText = nextProps.titleText;
    }

    if (nextProps.customizeType !== prevState.customizeType) {
      update.customizeType = nextProps.customizeType;
    }

    if (nextProps.savedCrosswordId !== prevState.savedCrosswordId) {
      update.savedCrosswordId = nextProps.savedCrosswordId;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate() {
    this.setSavingParameters();
  }

  setSavingParameters = () => {
    this.props.setRawDataToSave(nameOfAllProducts[0], {
      bigifiedClueNumberLabel: this.state.bigifiedClueNumberLabel,
      changedPuzzleBlockHeightInCm: this.state.changedPuzzleBlockHeightInCm,
      charactersOnGrid: this.state.charactersOnGrid,
      customInput1Text: this.state.customInput1Text,
      customInput2Text: this.state.customInput2Text,
      HorizontalClueListFontSizeInPx: this.state.HorizontalClueListFontSizeInPx,
      horizontalShift: this.state.horizontalShift,
      lowestMostYCoord: this.state.lowestMostYCoord,
      maxXCoord: this.state.maxXCoord,
      maxYCoord: this.state.maxYCoord,
      puzzleBlockHeightInCm: this.state.puzzleBlockHeightInCm,
      showCustomInput1: this.state.showCustomInput1,
      showCustomInput2: this.state.showCustomInput2,
      showDateInput: this.state.showDateInput,
      showNameInput: this.state.showNameInput,
      showTitle: this.state.showTitle,
      titleText: this.state.titleText,
      totalHeightInCm: this.state.totalHeightInCm,
      totalWidthInCm: this.state.totalWidthInCm,
      unplacedWords: this.state.unplacedWords,
      VerticalClueListFontSizeInPx: this.state.VerticalClueListFontSizeInPx,
      verticalShift: this.state.verticalShift,
      wordsOnGrid: this.state.wordsOnGrid,
      numOfWords: this.state.words.length
    });
  };

  getMaxAllowedXCoord = () => {
    return (
      Math.floor(this.state.totalWidthInCm / this.state.puzzleBlockHeightInCm) -
      2
    );
  };

  getMaxAllowedYCoord = () => {
    return (
      Math.floor(
        (this.state.totalHeightInCm - this.titleDivHeightInCm) /
          this.state.puzzleBlockHeightInCm
      ) - 1
    );
  };

  createCrosswordGrid = () => {
    if (this.state.generate === true) {
      this.generateGrid();
    } else if (
      this.state.generate === false &&
      this.state.customizeType === "preMade"
    ) {
      this.getPremadeGrid();
    } else if (
      this.state.generate === false &&
      this.state.customizeType === "saved"
    ) {
      this.getSavedGrid();
    }
  };

  generateGrid = async () => {
    let crosswordInfo = null;
    try {
      crosswordInfo = await getCrosswordInfo(
        this.state.words,
        this.state.maxXCoord,
        this.state.maxYCoord
      );
    } catch (e) {
      console.log(e);
    }

    const {
      charactersOnGrid,
      wordsOnGrid,
      lowestMostYCoord,
      horizontalShift,
      verticalShift,
      unplacedWords
    } = crosswordInfo;

    let showUnplacedWords = false;
    if (unplacedWords.length > 0) {
      showUnplacedWords = true;
    }
    this.setState(
      {
        charactersOnGrid,
        wordsOnGrid,
        lowestMostYCoord,
        horizontalShift,
        verticalShift,
        showUnplacedWords,
        unplacedWords
      },
      () =>
        this.setState(
          {
            puzzleLoaded: true
          },
          () => {
            this.setSavingParameters();
          }
        )
    );
  };
  // do this ting herrr
  getPremadeGrid = async () => {
    let result = null;
    try {
      result = await getPremadeProductById(
        nameOfAllProducts[0],
        this.state.premadeCrosswordId,
        this.props.setRawDataToSave,
        this.props.setProductIdToCheckIfAlreadySaved
      );
    } catch (e) {
      throw e;
    }

    const {
      wordsOnGrid,
      charactersOnGrid,
      lowestMostYCoord,
      horizontalShift,
      verticalShift,
      unplacedWords,
      HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx,
      showTitle,
      showDateInput,
      showNameInput,
      customInput1Text,
      showCustomInput1,
      customInput2Text,
      showCustomInput2,
      bigifiedClueNumberLabel,
      titleText,
      puzzleBlockHeightInCm,
      changedPuzzleBlockHeightInCm,
      totalHeightInCm,
      totalWidthInCm,
      maxXCoord,
      maxYCoord
    } = result;

    let showUnplacedWords = false;
    if (unplacedWords.length > 0) {
      showUnplacedWords = true;
    }
    this.setState(
      {
        showUnplacedWords,
        wordsOnGrid,
        charactersOnGrid,
        lowestMostYCoord,
        horizontalShift,
        verticalShift,
        unplacedWords,
        HorizontalClueListFontSizeInPx,
        VerticalClueListFontSizeInPx,
        showTitle,
        showDateInput,
        showNameInput,
        customInput1Text,
        showCustomInput1,
        customInput2Text,
        showCustomInput2,
        bigifiedClueNumberLabel,
        titleText,
        puzzleBlockHeightInCm,
        changedPuzzleBlockHeightInCm,
        totalHeightInCm,
        totalWidthInCm,
        maxXCoord,
        changedMaxXCoord: maxXCoord,
        maxYCoord,
        changedMaxYCoord: maxYCoord
      },
      () => {
        this.setState({
          puzzleLoaded: true
        });
        this.props.toggleGenerate(true);
      }
    );
  };

  getSavedGrid = async () => {
    let result = null;
    try {
      result = await getSavedProductById(
        nameOfAllProducts[0],
        this.state.savedCrosswordId,
        this.props.setRawDataToSave,
        this.props.setProductIdToCheckIfAlreadySaved
      );
    } catch (e) {
      throw e;
    }

    const {
      wordsOnGrid,
      charactersOnGrid,
      lowestMostYCoord,
      horizontalShift,
      verticalShift,
      unplacedWords,
      HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx,
      showTitle,
      showDateInput,
      showNameInput,
      customInput1Text,
      showCustomInput1,
      customInput2Text,
      showCustomInput2,
      bigifiedClueNumberLabel,
      titleText,
      puzzleBlockHeightInCm,
      changedPuzzleBlockHeightInCm,
      totalHeightInCm,
      totalWidthInCm,
      maxXCoord,
      maxYCoord
    } = result.rawData;

    let showUnplacedWords = false;
    if (unplacedWords.length > 0) {
      showUnplacedWords = true;
    }
    this.setState(
      {
        showUnplacedWords,
        wordsOnGrid,
        charactersOnGrid,
        lowestMostYCoord,
        horizontalShift,
        verticalShift,
        unplacedWords,
        HorizontalClueListFontSizeInPx,
        VerticalClueListFontSizeInPx,
        showTitle,
        showDateInput,
        showNameInput,
        customInput1Text,
        showCustomInput1,
        customInput2Text,
        showCustomInput2,
        bigifiedClueNumberLabel,
        titleText,
        puzzleBlockHeightInCm,
        changedPuzzleBlockHeightInCm,
        totalHeightInCm,
        totalWidthInCm,
        maxXCoord,
        changedMaxXCoord: maxXCoord,
        maxYCoord,
        changedMaxYCoord: maxYCoord
      },
      () => {
        this.setState({
          puzzleLoaded: true
        });
        this.props.toggleGenerate(true);
      }
    );
  };

  getMaxWordSize = () => {
    let maxWordSize = null;
    if (this.maxYCoord > this.maxXCoord) {
      maxWordSize = this.maxYCoord + 1;
    } else if (this.maxXCoord > this.maxYCoord) {
      maxWordSize = this.maxXCoord + 1;
    } else {
      maxWordSize = this.maxXCoord + 1;
    }
    return maxWordSize;
  };

  /**
   * Sorts array of words (this.state.words) by greatest word length to shortest
   */
  sortWords = () => {
    const maxWordSize = this.getMaxWordSize();
    let { words } = this.state;

    const wordsExceedingMaxSize: Array<string> = [];

    words = words.sort((a, b) => {
      if (a.word.length > maxWordSize) {
        wordsExceedingMaxSize.push(a.word);
      }
      if (b.word.length > maxWordSize) {
        wordsExceedingMaxSize.push(b.word);
      }

      // // convert all words to lowercase
      // a.word = a.word.toLowerCase();
      // b.word = b.word.toLowerCase();

      return b.word.length - a.word.length;
    });

    // temporary handle for words that are larger than max word size
    if (wordsExceedingMaxSize.length > 0) {
      console.log("THESE WORDS r TOO LONG", wordsExceedingMaxSize);
    }

    this.setState({ words });
  };

  togglePreview = (type: string, value?: any) => {
    switch (type) {
      case "answerKey":
        this.setState({ showAnswerKey: !this.state.showAnswerKey });
        break;
      case "titleToggle":
        this.setState({ showTitle: !this.state.showTitle });
        break;
      case "dateInput":
        this.setState({ showDateInput: !this.state.showDateInput });
        break;
      case "nameInput":
        this.setState({ showNameInput: !this.state.showNameInput });
        break;
      case "customInput1":
        this.setState({ showCustomInput1: !this.state.showCustomInput1 });
        break;
      case "customInput1Text":
        this.setState({ customInput1Text: value });
        break;
      case "customInput2":
        this.setState({ showCustomInput2: !this.state.showCustomInput2 });
        break;
      case "customInput2Text":
        this.setState({ customInput2Text: value });
        break;
      case "numberLabel":
        this.setState({
          bigifiedClueNumberLabel: value
        });
        break;
      case "puzzleBlockHeightInCm":
        if (this.state.changedPuzzleBlockHeightInCm !== value) {
          this.setState({
            puzzleBlockHeightInCm: value,
            blockSizeChanged: true
          });
        } else {
          this.setState({
            puzzleBlockHeightInCm: value,
            blockSizeChanged: false
          });
        }
        break;
      case "maxXCoord":
        if (value <= this.state.maxAllowedXCoord && value >= 0) {
          if (this.state.maxXCoord !== value) {
            this.setState({
              changedMaxXCoord: value,
              maxXCoordHasChanged: true
            });
          } else {
            this.setState({
              changedMaxXCoord: value,
              maxXCoordHasChanged: false
            });
          }
        }
        break;
      case "maxYCoord":
        if (value <= this.state.maxAllowedYCoord && value >= 0) {
          if (this.state.maxYCoord !== value) {
            this.setState({
              changedMaxYCoord: value,
              maxYCoordHasChanged: true
            });
          } else {
            this.setState({
              changedMaxYCoord: value,
              maxYCoordHasChanged: false
            });
          }
        }
        break;
      case "verticalCluesFont":
        if (value > 0 && value <= 18) {
          this.setState({
            VerticalClueListFontSizeInPx: value
          });
        }
        break;
      case "horizontalCluesFont":
        if (value > 0 && value <= 18) {
          this.setState({
            HorizontalClueListFontSizeInPx: value
          });
        }
        break;
      case "titleText":
        this.props.setCrosswordTitleText(value);
        break;
      case "size":
        if (value === "a4") {
          if (this.state.totalHeightInCm !== 29.7) {
            this.setState({
              changedTotalHeightInCm: 29.7,
              changedTotalWidthInCm: 21,
              sizeHasChanged: true
            });
          } else {
            this.setState({
              changedTotalHeightInCm: this.state.totalHeightInCm,
              changedTotalWidthInCm: this.state.totalWidthInCm,
              sizeHasChanged: false
            });
          }
        }
        if (value === "letter") {
          if (this.state.totalHeightInCm !== 27.94) {
            this.setState({
              changedTotalHeightInCm: 27.94,
              changedTotalWidthInCm: 21.59,
              sizeHasChanged: true
            });
          } else {
            this.setState({
              changedTotalHeightInCm: this.state.totalHeightInCm,
              changedTotalWidthInCm: this.state.totalWidthInCm,
              sizeHasChanged: false
            });
          }
        }
        break;
      default:
        break;
    }
  };

  refreshPreview = (type: string) => {
    if (type === "simpleRegen") {
      this.setState(
        {
          puzzleLoaded: false,
          showPageWithClues: false
        },
        () => this.createCrosswordGrid()
      );
    }
    if (type === "size") {
      const maxAllowedXCoord = this.getMaxAllowedXCoord();
      const maxAllowedYCoord = this.getMaxAllowedYCoord();

      let { changedMaxXCoord } = this.state;
      let { changedMaxYCoord } = this.state;

      changedMaxXCoord = maxAllowedXCoord;
      changedMaxYCoord = maxAllowedXCoord; // when changing sizes, set both height and width to same thing to try and generate perfect square puzzle

      this.setState(
        {
          changedPuzzleBlockHeightInCm: this.state.puzzleBlockHeightInCm,
          blockSizeChanged: false,
          maxXCoord: changedMaxXCoord,
          changedMaxXCoord,
          maxXCoordHasChanged: false,
          maxAllowedXCoord,
          maxYCoord: changedMaxYCoord,
          changedMaxYCoord,
          maxYCoordHasChanged: false,
          maxAllowedYCoord,
          totalHeightInCm: this.state.changedTotalHeightInCm,
          totalWidthInCm: this.state.changedTotalWidthInCm,
          sizeHasChanged: false,
          puzzleLoaded: false,
          showPageWithClues: false
        },
        () => {
          this.createCrosswordGrid();
        }
      );
    }
    if (type === "puzzle") {
      const maxAllowedXCoord = this.getMaxAllowedXCoord();
      const maxAllowedYCoord = this.getMaxAllowedYCoord();

      let { changedMaxXCoord } = this.state;
      let { changedMaxYCoord } = this.state;

      if (changedMaxXCoord > maxAllowedXCoord) {
        changedMaxXCoord = maxAllowedXCoord;
      }
      if (changedMaxYCoord > maxAllowedYCoord) {
        changedMaxYCoord = maxAllowedYCoord;
      }

      this.setState(
        {
          changedPuzzleBlockHeightInCm: this.state.puzzleBlockHeightInCm,
          blockSizeChanged: false,
          maxXCoord: changedMaxXCoord,
          changedMaxXCoord,
          maxXCoordHasChanged: false,
          maxAllowedXCoord,
          maxYCoord: changedMaxYCoord,
          changedMaxYCoord,
          maxYCoordHasChanged: false,
          maxAllowedYCoord,
          puzzleLoaded: false,
          showPageWithClues: false
        },
        () => {
          this.createCrosswordGrid();
        }
      );
    }
  };

  setClueListFontSize = (
    HorizontalClueListFontSizeInPx: number,
    VerticalClueListFontSizeInPx: number
  ) => {
    this.setState({
      HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx
    });
  };

  moveCluesToAnotherPage = () => {
    this.setState(
      {
        showPageWithClues: true,
        HorizontalClueListFontSizeInPx: 16,
        VerticalClueListFontSizeInPx: 16
      },
      () => {
        this.props.toggleToast(
          true,
          "Your clues would've been too small on one page, so we moved them to a second one. You could try to fit them by changing the puzzle settings. 👍"
        );
      }
    );
  };

  render(): JSX.Element {
    return (
      <EditPage totalHeightInCm={this.state.totalHeightInCm}>
        <EditModal
          togglePreview={this.togglePreview}
          totalHeightInCm={this.state.totalHeightInCm}
          changedTotalHeightInCm={this.state.changedTotalHeightInCm}
          showAnswerKey={this.state.showAnswerKey}
          showTitle={this.state.showTitle}
          titleText={this.state.titleText}
          showDateInput={this.state.showDateInput}
          showNameInput={this.state.showNameInput}
          showCustomInput1={this.state.showCustomInput1}
          customInput1Text={this.state.customInput1Text}
          showCustomInput2={this.state.showCustomInput2}
          customInput2Text={this.state.customInput2Text}
          bigifiedClueNumberLabel={this.state.bigifiedClueNumberLabel}
          VerticalClueListFontSizeInPx={this.state.VerticalClueListFontSizeInPx}
          HorizontalClueListFontSizeInPx={
            this.state.HorizontalClueListFontSizeInPx
          }
          puzzleBlockHeightInCm={this.state.puzzleBlockHeightInCm}
          blockSizeChanged={this.state.blockSizeChanged}
          refreshPreview={this.refreshPreview}
          maxXCoord={this.state.maxXCoord}
          changedMaxXCoord={this.state.changedMaxXCoord}
          maxXCoordHasChanged={this.state.maxXCoordHasChanged}
          maxYCoord={this.state.maxYCoord}
          changedMaxYCoord={this.state.changedMaxYCoord}
          maxYCoordHasChanged={this.state.maxYCoordHasChanged}
          sizeHasChanged={this.state.sizeHasChanged}
        />
        {this.state.puzzleLoaded ? (
          <Preview
            wordsOnGrid={this.state.wordsOnGrid}
            lowestMostYCoord={this.state.lowestMostYCoord}
            horizontalShift={this.state.horizontalShift}
            verticalShift={this.state.verticalShift}
            showAnswerKey={this.state.showAnswerKey}
            showTitle={this.state.showTitle}
            showDateInput={this.state.showDateInput}
            showNameInput={this.state.showNameInput}
            showCustomInput1={this.state.showCustomInput1}
            customInput1Text={this.state.customInput1Text}
            showCustomInput2={this.state.showCustomInput2}
            customInput2Text={this.state.customInput2Text}
            bigifiedClueNumberLabel={this.state.bigifiedClueNumberLabel}
            VerticalClueListFontSizeInPx={
              this.state.VerticalClueListFontSizeInPx
            }
            HorizontalClueListFontSizeInPx={
              this.state.HorizontalClueListFontSizeInPx
            }
            titleText={this.state.titleText}
            setClueListFontSize={this.setClueListFontSize}
            titleDivHeightInCm={this.titleDivHeightInCm}
            puzzleBlockHeightInCm={this.state.changedPuzzleBlockHeightInCm}
            totalHeightInCm={this.state.totalHeightInCm}
            totalWidthInCm={this.state.totalWidthInCm}
            maxXCoord={this.state.maxXCoord}
            maxYCoord={this.state.maxYCoord}
            charactersOnGrid={this.state.charactersOnGrid}
            moveCluesToAnotherPage={this.moveCluesToAnotherPage}
          />
        ) : (
          <PreviewSizedPage
            totalHeightInCm={this.state.totalHeightInCm}
            totalWidthInCm={this.state.totalWidthInCm}
            id="cluesSecondPage"
          >
            <Loader type="Rings" color="#302e34" height={400} width={400} />
          </PreviewSizedPage>
        )}
        {this.state.showUnplacedWords ? (
          <UnplacedWords
            totalHeightInCm={this.state.totalHeightInCm}
            totalWidthInCm={this.state.totalWidthInCm}
            unplacedWords={this.state.unplacedWords}
            refreshPreview={this.refreshPreview}
          ></UnplacedWords>
        ) : null}
        {this.state.showPageWithClues ? (
          <PreviewSizedPage
            totalHeightInCm={this.state.totalHeightInCm}
            totalWidthInCm={this.state.totalWidthInCm}
            id="cluesSecondPage"
          >
            <Clues
              wordsOnGrid={this.state.wordsOnGrid}
              lowestMostYCoord={this.state.lowestMostYCoord}
              showAnswerKey={this.state.showAnswerKey}
              VerticalClueListFontSizeInPx={
                this.state.VerticalClueListFontSizeInPx
              }
              HorizontalClueListFontSizeInPx={
                this.state.HorizontalClueListFontSizeInPx
              }
              setClueListFontSize={this.setClueListFontSize}
              titleDivHeightInCm={this.titleDivHeightInCm}
              puzzleBlockHeightInCm={this.state.puzzleBlockHeightInCm}
              totalHeightInCm={this.state.totalHeightInCm}
              maxYCoord={this.state.maxYCoord}
              moveCluesToAnotherPage={() => {}}
              isOnSecondPage={true}
            />
          </PreviewSizedPage>
        ) : null}
      </EditPage>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    words: state.crosswordReducer.words,
    titleText: state.crosswordReducer.titleText,
    generate: state.crosswordReducer.generate,
    premadeCrosswordId: state.crosswordReducer.premadeCrosswordId,
    customizeType: state.crosswordReducer.customizeType,
    savedCrosswordId: state.crosswordReducer.savedCrosswordId
  };
}

const CrosswordFinalPageConnected = connect(mapStateToProps, {
  toggleGenerate,
  toggleCrosswordCreatePreviewModal,
  toggleCrosswordPremadePreviewModal,
  toggleCrosswordSavedPreviewModal,
  toggleToast,
  setRawDataToSave,
  setProductIdToCheckIfAlreadySaved,
  setCrosswordTitleText
})(CrosswordFinalPage);

export default CrosswordFinalPageConnected;
