import * as React from "react";
import styled from "styled-components";
import CrosswordForm from "./CrosswordForm";
import PremadeList from "../../Helpers/UniversalComponents/PremadeProductsCard";
import SavedCrosswords from "../../Helpers/UniversalComponents/SavedProductsCard";
import { connect } from "react-redux";
import { nameOfAllProducts } from "../../Helpers/Variables";

export interface props {
  isPayingUser: boolean;
  isLoggedIn: boolean;
}

export interface state {
  isPayingUser: boolean;
  isLoggedIn: boolean;
}

const Div = styled("div")`
  width: 100%;
  height: 100%
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
`;
export class CrosswordFirstPage extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      isPayingUser: false,
      isLoggedIn: false
    };
  }

  componentDidMount() {}

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isLoggedIn?: boolean;
      isPayingUser?: boolean;
    } = {};

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }
    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    return Object.keys(update).length ? update : null;
  }

  render(): JSX.Element {
    return (
      <Div>
        {this.state.isLoggedIn ? (
          <SavedCrosswords type={nameOfAllProducts[0]} />
        ) : null}
        <CrosswordForm />
        <PremadeList type={nameOfAllProducts[0]} />
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const CrosswordFirstPageContainer = connect(
  mapStateToProps,
  {}
)(CrosswordFirstPage);

export default CrosswordFirstPageContainer;
