import * as React from "react";
import styled from "styled-components";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  togglePaymentPlansModal,
  toggleToast,
  toggleIsPayingUser,
  toggleExpiredPlanModal
} from "../../../../../redux";
import { connect } from "react-redux";
import { exportPdf, exportPng } from "../../../../Helpers/DownloadHelpers";
import { paymentPlansModalTypes } from "../../../../Helpers/Variables";
import { incrementPrintCount } from "../../../../../API/Payment";

export interface props {
  togglePaymentPlansModal?: Function;
  isPremadeDropdown: boolean;
  isSaveDropdown: boolean;
  isPayingUser: boolean;
  isLoggedIn: boolean;
  titleText?: string;
  isCreateDropdown: boolean;
  isCreatedSave: boolean;
  toggleToast: Function;
  toggleIsPayingUser: Function;
  toggleExpiredPlanModal: Function;
}

export interface state {
  isPayingUser: boolean;
  isLoggedIn: boolean;
}

const CustomDropdownItem = styled(DropdownItem)`
  color: white !important;
  text-align: center !important;
  min-height: 36px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border: 1px solid #70c989 !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
  min-width: 115px !important;
`;
const CustomDropdownToggle = styled(DropdownToggle)`
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border: none !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
  min-width: 115px !important;
`;
const CustomDropdownMenu = styled(DropdownMenu)`
  min-width: unset !important;
  padding: unset !important;
  margin: unset !important;
  background-color: transparent !important;
  border: unset !important;
  border-radius: unset !important;
`;
const AnswerKeyText = styled("span")`
  font-size: 13px;
`;
export class DownloadDropdown extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isPayingUser: false,
      isLoggedIn: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isLoggedIn?: boolean;
      isPayingUser?: boolean;
    } = {};

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }
    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    return Object.keys(update).length ? update : null;
  }

  handleDownload = async (type: string, showAnswerKey: boolean) => {
    // trying to download a created crossword
    if (this.props.isCreateDropdown && !this.state.isPayingUser) {
      this.props.togglePaymentPlansModal(true, paymentPlansModalTypes[1]);
      return;
    }
    // trying to download a premade crossword
    else {
      let { titleText } = this.props;
      if (titleText === undefined || titleText === null) {
        titleText = "";
      }
      if (type === "pdf") {
        if (showAnswerKey) {
          exportPdf(titleText, true);
        }
        if (!showAnswerKey) {
          exportPdf(titleText);
        }
      }
      if (type === "image") {
        if (showAnswerKey) {
          exportPng(titleText, true);
        }
        if (!showAnswerKey) {
          exportPng(titleText);
        }
      }
    }

    // increment download count if downloading a user's saved creation or brand new creation AND NOT a premade product under any circumstance boii
    if (
      this.state.isPayingUser &&
      (this.props.isCreatedSave || this.props.isCreateDropdown)
    ) {
      let printCountInfo = null;
      try {
        printCountInfo = await incrementPrintCount();
      } catch (e) {
        console.log(e);
        if (e.response.data.error === "Max print limit reached") {
          this.props.toggleIsPayingUser(false);
          this.props.toggleExpiredPlanModal(true);
        } else {
          this.props.toggleToast(
            true,
            "Unable to increment download count. Please contact wordymaker@gmail.com if this problem persists."
          );
        }
      }

      if (printCountInfo.restrictMaxPrints) {
        this.props.toggleToast(
          true,
          `${printCountInfo.prints} ${
            printCountInfo.prints > 1 ? "downloads" : "download"
          } used so far. Please keep in mind you have a maximum of ${
            printCountInfo.maxPrintsAllowed
          } downloads available.`
        );
      }
    }
  };

  render(): JSX.Element {
    return (
      <UncontrolledDropdown>
        <CustomDropdownToggle
          ispremadedropdown={this.props.isPremadeDropdown ? 1 : 0}
          caret
        >
          Download
        </CustomDropdownToggle>
        <CustomDropdownMenu>
          <CustomDropdownItem onClick={() => this.handleDownload("pdf", false)}>
            PDF
          </CustomDropdownItem>
          <CustomDropdownItem
            onClick={() => this.handleDownload("image", false)}
          >
            Image
          </CustomDropdownItem>
          <CustomDropdownItem onClick={() => this.handleDownload("pdf", true)}>
            PDF
            <br /> <AnswerKeyText>Answer Key</AnswerKeyText>
          </CustomDropdownItem>
          <CustomDropdownItem
            onClick={() => this.handleDownload("image", true)}
          >
            Image
            <br /> <AnswerKeyText>Answer Key</AnswerKeyText>
          </CustomDropdownItem>
        </CustomDropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const DownloadDropdownContainer = connect(mapStateToProps, {
  togglePaymentPlansModal,
  toggleToast,
  toggleIsPayingUser,
  toggleExpiredPlanModal
})(DownloadDropdown);

export default DownloadDropdownContainer;
