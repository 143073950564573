import * as React from "react";
import styled from "styled-components";
//@ts-ignore
import Loader from "react-loader-spinner";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast
} from "../../redux";
import { confirmRegister, resendConfirmationCode } from "../../API/Auth";

export interface props {
  emailText: string;
  toggleUserAuthModal: Function;
  toggleIsLoggedIn: Function;
  toggleAuthToShow: Function;
  backToLogin?: Function;
  toggleToast: Function;
}

export interface state {
  emailText: string;
  confirmationCodeText: string;
  attemptRegisterSecondStep: boolean;
  attemptResending: boolean;
  reSent: boolean;
}

const Div = styled("div")<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Inputs = styled("div")`
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
`;
const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const Footer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;
const ReactStrapButton = styled(Button)`
  margin: 0 10px 0 10px !important;
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 36px !important;
  width: 98px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const StepText = styled("div")`
  color: white;
  max-width: 270px;
  font-size: 15px;
  margin: auto auto 10px auto;
`;
export class ConfirmationCodeStep extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      emailText: props.emailText,
      confirmationCodeText: "",
      attemptRegisterSecondStep: false,
      attemptResending: false,
      reSent: false
    };
  }

  componentDidMount = () => {};

  attemptRegisterSecondStep = async () => {
    this.setState({
      attemptRegisterSecondStep: true
    });

    let result = null;
    try {
      result = await confirmRegister(
        this.state.emailText,
        this.state.confirmationCodeText
      );
    } catch (e) {
      this.props.toggleToast(
        true,
        "Unable to complete registration, check that the confirmation code matches the one you received"
      );
      this.setState({
        attemptRegisterSecondStep: false
      });
    }

    if ("confirmed" in result && result.confirmed === true) {
      // navigate back to login and let user know that registration worked
      if (
        this.props.backToLogin !== undefined &&
        this.props.backToLogin !== null
      ) {
        this.props.toggleToast(
          true,
          "Signed up successfuly, you can now login. 👍"
        );
        this.setState(
          {
            attemptRegisterSecondStep: false
          },
          () => this.props.backToLogin()
        );
      } else {
        this.props.toggleToast(
          true,
          "Signed up successfuly, you can now login. 👍"
        );
        this.setState(
          {
            attemptRegisterSecondStep: false
          },
          () => this.props.toggleAuthToShow("login")
        );
      }
    } else {
      this.props.toggleToast(
        true,
        "Unable to complete registration, check that the confirmation code matches the one you received"
      );
      this.setState({
        attemptRegisterSecondStep: false
      });
    }
  };

  attemptResending = async () => {
    this.setState({
      attemptResending: true
    });

    let result = null;
    try {
      result = await resendConfirmationCode(this.state.emailText);
    } catch (e) {
      this.props.toggleToast(true, "Unable to resend confirmation code");
      this.setState({
        attemptResending: false
      });
    }

    if ("sent" in result && result.sent === true) {
      // another code has been sent to user
      this.props.toggleToast(
        true,
        "We've sent another code to your email, please allow up to 5 minutes for it to arrive. 👍"
      );
      this.setState({
        attemptResending: false,
        reSent: true
      });
    } else {
      this.props.toggleToast(true, "Unable to resend confirmation code");
      this.setState({
        attemptResending: false
      });
    }
  };

  render(): JSX.Element {
    return (
      <Div>
        <Inputs>
          <StepText>
            We have sent a confirmation code to the email you used, please enter
            it below to complete registration.
          </StepText>
          <StepText>Allow up to 5 minutes for the code to come in.</StepText>
          <StepText>
            If the code doesn't arrive in 5 minutes, please check your spam or
            try resending.
          </StepText>
          <StyledInputGroup>
            <InputGroupAddon className="titleLabel" addonType="append">
              Code
            </InputGroupAddon>
            <Input
              type="number"
              className="loginInputs"
              value={this.state.confirmationCodeText}
              onChange={e => {
                this.setState({ confirmationCodeText: e.target.value });
              }}
              style={{
                borderRadius: 0
              }}
            />
          </StyledInputGroup>
        </Inputs>
        <Footer>
          <ReactStrapButton
            onClick={() => this.attemptResending()}
            disabled={this.state.reSent || this.state.attemptResending}
          >
            {this.state.attemptResending ? (
              <Loader type="Rings" color="#fff" height={36} width={36} />
            ) : (
              "Resend"
            )}
          </ReactStrapButton>
          <ReactStrapButton
            onClick={() => this.attemptRegisterSecondStep()}
            disabled={
              this.state.attemptRegisterSecondStep ||
              this.state.confirmationCodeText.length === 0
            }
          >
            {this.state.attemptRegisterSecondStep ? (
              <Loader type="Rings" color="#fff" height={36} width={36} />
            ) : (
              "Sign Up"
            )}
          </ReactStrapButton>
        </Footer>
      </Div>
    );
  }
}

const ConfirmationCodeStepContainer = connect(null, {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast
})(ConfirmationCodeStep);

export default ConfirmationCodeStepContainer;
