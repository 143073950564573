import * as React from "react";
import styled from "styled-components";

export interface props {
  wordsOnGrid: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  lowestMostYCoord: number;
  showAnswerKey: boolean;
  VerticalClueListFontSizeInPx: number;
  HorizontalClueListFontSizeInPx: number;
  setClueListFontSize: Function;
  titleDivHeightInCm: number;
  puzzleBlockHeightInCm: number;
  totalHeightInCm: number;
  maxYCoord: number;
  moveCluesToAnotherPage: Function;
  isOnSecondPage: boolean;
}

export interface state {
  HorizontalClueListFontSizeInPx: number;
  VerticalClueListFontSizeInPx: number;
  clueDivHeightInCm: number;
  horizontalClues: Array<any>;
  verticalClues: Array<any>;
  wordsSortedById: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  showClueList: boolean;
}

export interface wordToPlaceObj {
  id: number;
  word: string;
  clue: string;
}
export interface placedWordObj {
  id: number;
  word: string;
  clue: string;
  orientation: string;
  startCoord: string;
  endCoord: string;
}

const ClueDiv = styled("div")<{
  clueDivHeightInCm: number;
  showAnswerKey: boolean;
  isOnSecondPage: boolean;
}>`
  position: absolute;
  bottom: ${props => (props.isOnSecondPage ? null : "0")};
  height: calc(${props => props.clueDivHeightInCm}cm - 6px);
  width: 100%;
  padding: 1cm 1cm 0 1cm;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  .clueAnswer {
    display: ${props => (props.showAnswerKey ? null : "none")};
  }
  left: 2px;
  width: calc(100% - 2px);
`;
const HorizontalClues = styled("div")`
height: 100%
flex: 50%;
max-width: 100%;
`;
const VerticalClues = styled("div")`
height: 100%
flex: 50%;
max-width: 100%;
`;
const LabelDiv = styled("div")`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
`;
const HorizontalCluelist = styled("div")<{
  HorizontalClueListFontSizeInPx: number;
}>`
font-size: ${props => props.HorizontalClueListFontSizeInPx}px
word-wrap: break-word;
max-width: 358px;
`;
const VerticalClueList = styled("div")<{
  VerticalClueListFontSizeInPx: number;
}>`
font-size: ${props => props.VerticalClueListFontSizeInPx}px
word-wrap: break-word;
max-width: 358px;
`;
const ClueText = styled("div")`
  padding-left: 1em;
  padding-right: 1em;
  text-indent: -1em;
`;
const ClueId = styled("span")`
  font-weight: bold;
`;
const ClueAnswer = styled("span")`
  font-weight: bold;
`;
export class Clues extends React.Component<props, state> {
  Preview: any;

  constructor(props: props) {
    super(props);
    this.state = {
      HorizontalClueListFontSizeInPx: props.HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx: props.VerticalClueListFontSizeInPx,
      clueDivHeightInCm: 16,
      horizontalClues: [],
      verticalClues: [],
      wordsSortedById: [],
      showClueList: true
    };
  }

  componentDidMount = () => {
    this.setWordsSortedById();
  };

  componentDidUpdate(prevProps: props, prevState: state) {
    if (
      prevProps.HorizontalClueListFontSizeInPx !==
      this.props.HorizontalClueListFontSizeInPx
    ) {
      this.setState({
        HorizontalClueListFontSizeInPx: this.props
          .HorizontalClueListFontSizeInPx
      });
    }
    if (
      prevProps.VerticalClueListFontSizeInPx !==
      this.props.VerticalClueListFontSizeInPx
    ) {
      this.setState({
        VerticalClueListFontSizeInPx: this.props.VerticalClueListFontSizeInPx
      });
    }
  }

  setWordsSortedById = () => {
    let wordsSortedById = this.props.wordsOnGrid.slice();

    wordsSortedById = wordsSortedById.sort(function(a, b) {
      return a.id - b.id;
    });

    this.setState({ wordsSortedById }, () => {
      this.getClueDivHeight();
      this.setClueLists();
    });
  };

  getClueDivHeight = () => {
    const { lowestMostYCoord } = this.props;

    const baseHeightInCm =
      this.props.totalHeightInCm -
      (this.props.titleDivHeightInCm +
        this.props.puzzleBlockHeightInCm * (this.props.maxYCoord + 1));
    const heightIncrease = lowestMostYCoord * this.props.puzzleBlockHeightInCm;

    const clueDivHeightInCm = baseHeightInCm + heightIncrease;

    this.setState({ clueDivHeightInCm }, () => this.getClueListFontSize()); // gets font size here cuz needs to happen after clue div height has been set
  };

  getClueListFontSize = () => {
    // FYI:
    // 16px is default font-size of either clue list
    // 18px is default height of a single line of a clue

    // step 1: get number of horizontal clues & get number of vertical clues
    const { wordsOnGrid } = this.props;

    let horizontalClues = 1;
    let verticalClues = 1;

    for (const wordOnGridObj of wordsOnGrid) {
      if (wordOnGridObj.orientation === "horizontal") {
        horizontalClues += 1;
      }
      if (wordOnGridObj.orientation === "vertical") {
        verticalClues += 1;
      }
    }

    // step 2: get clue div height then subtract (23 + 38) to get height that clues can use
    const { clueDivHeightInCm } = this.state;
    const clueListMaxHeightInPx =
      Math.floor(
        clueDivHeightInCm * 37.7952755905511 // converting cm to pixels
      ) -
      (23 + 38) -
      25; // removing an extra 25px to allow white space below list

    // step 3: Check horizontal clues list
    const heightInPxTakenByHorizontalClueList = horizontalClues * 18;
    let newHorizontalClueListFontSize = 16;
    // if horizontal clue list is longer than max alloted space for clue list, shrink font size
    if (heightInPxTakenByHorizontalClueList > clueListMaxHeightInPx) {
      let horizontalClueHeight = 18;
      while (horizontalClues * horizontalClueHeight >= clueListMaxHeightInPx) {
        horizontalClueHeight -= 0.1;
      }
      const differenceInNewHorizontalClueListHeight = 18 - horizontalClueHeight;
      newHorizontalClueListFontSize =
        16 - differenceInNewHorizontalClueListHeight;
    }
    // if horizontal clue list is shorter than max alloted space for clue list, expand font size
    if (heightInPxTakenByHorizontalClueList < clueListMaxHeightInPx) {
      let horizontalClueHeight = 18;
      while (horizontalClues * horizontalClueHeight <= clueListMaxHeightInPx) {
        horizontalClueHeight += 0.1;
      }
      horizontalClueHeight -= 1; // prevent overshoot while expanding font size
      const differenceInNewHorizontalClueListHeight = 18 - horizontalClueHeight;
      newHorizontalClueListFontSize =
        16 - differenceInNewHorizontalClueListHeight;
    }

    // step 4: Check vertical clues list
    const heightInPxTakenByVerticalClueList = verticalClues * 18;
    let newVerticalClueListFontSize = 16;
    // if vertical clue list is longer than max alloted space for clue list, shrink font size
    if (heightInPxTakenByVerticalClueList > clueListMaxHeightInPx) {
      let verticalClueHeight = 18;
      while (verticalClues * verticalClueHeight >= clueListMaxHeightInPx) {
        verticalClueHeight -= 0.1;
      }
      const differenceInNewVerticalClueListHeight = 18 - verticalClueHeight;
      newVerticalClueListFontSize = 16 - differenceInNewVerticalClueListHeight;
    }

    // if vertical clue list is shorter than max alloted space for clue list, expand font size
    if (heightInPxTakenByVerticalClueList < clueListMaxHeightInPx) {
      let verticalClueHeight = 18;
      while (verticalClues * verticalClueHeight <= clueListMaxHeightInPx) {
        verticalClueHeight += 0.1;
      }
      verticalClueHeight -= 0.1; // prevent overshoot while expanding font size
      const differenceInNewVerticalClueListHeight = 18 - verticalClueHeight;
      newVerticalClueListFontSize = 16 - differenceInNewVerticalClueListHeight;
    }

    // limit max clue list font size to 18px to prevent craziness
    if (newHorizontalClueListFontSize > 18) {
      newHorizontalClueListFontSize = 18;
    }
    if (newVerticalClueListFontSize > 18) {
      newVerticalClueListFontSize = 18;
    }

    if (
      newHorizontalClueListFontSize < 10 ||
      newVerticalClueListFontSize < 10
    ) {
      this.props.moveCluesToAnotherPage();
      this.setState({
        showClueList: false
      });
    }
    // else {
    //   this.setState(
    //     {
    //       HorizontalClueListFontSizeInPx: newHorizontalClueListFontSize,
    //       VerticalClueListFontSizeInPx: newVerticalClueListFontSize
    //     },
    //     () => {
    //       this.props.setClueListFontSize(
    //         newHorizontalClueListFontSize,
    //         newVerticalClueListFontSize
    //       );
    //     }
    //   );
    // }
  };

  setClueLists = () => {
    const { wordsSortedById } = this.state;
    const horizontalClues: any = [];
    const verticalClues: any = [];

    // get across clues
    for (const wordsSortedByIdObj of wordsSortedById) {
      if (wordsSortedByIdObj.orientation === "horizontal") {
        horizontalClues.push(
          <ClueText key={Math.random()}>
            <ClueId key={Math.random()}>{`${wordsSortedByIdObj.id}.`}</ClueId>
            {` ${wordsSortedByIdObj.clue} `}
            <ClueAnswer
              className="clueAnswer"
              key={Math.random()}
            >{`(${wordsSortedByIdObj.word})`}</ClueAnswer>
          </ClueText>
        );
      }
      if (wordsSortedByIdObj.orientation === "vertical") {
        verticalClues.push(
          <ClueText key={Math.random()}>
            <ClueId key={Math.random()}>{`${wordsSortedByIdObj.id}.`}</ClueId>
            {` ${wordsSortedByIdObj.clue} `}
            <ClueAnswer
              className="clueAnswer"
              key={Math.random()}
            >{`(${wordsSortedByIdObj.word})`}</ClueAnswer>
          </ClueText>
        );
      }
    }

    this.setState({
      horizontalClues,
      verticalClues
    });
  };

  render(): JSX.Element {
    return (
      <ClueDiv
        clueDivHeightInCm={this.state.clueDivHeightInCm}
        showAnswerKey={this.props.showAnswerKey}
        isOnSecondPage={this.props.isOnSecondPage}
      >
        <HorizontalClues>
          <LabelDiv>Across</LabelDiv>
          <HorizontalCluelist
            HorizontalClueListFontSizeInPx={
              this.state.HorizontalClueListFontSizeInPx
            }
          >
            {this.state.horizontalClues}
          </HorizontalCluelist>
        </HorizontalClues>
        <VerticalClues>
          <LabelDiv>Down</LabelDiv>
          <VerticalClueList
            VerticalClueListFontSizeInPx={
              this.state.VerticalClueListFontSizeInPx
            }
          >
            {this.state.verticalClues}
          </VerticalClueList>
        </VerticalClues>
      </ClueDiv>
    );
  }
}
