import * as React from "react";
import styled from "styled-components";
import { Modal } from "reactstrap";
import { getCrosswordInfo } from "../../../../API/Algos";
import { getSavedProductById } from "../../../../API/SavedProducts";
import {
  store,
  toggleGenerate,
  toggleCrosswordCreatePreviewModal,
  toggleCrosswordPremadePreviewModal,
  setPremadeCrosswordId,
  setRawDataToSave,
  setProductIdToCheckIfAlreadySaved,
  toggleCrosswordCustomizeType,
  toggleCrosswordSavedPreviewModal
} from "../../../../redux";
import { connect } from "react-redux";
//@ts-ignore
import Loader from "react-loader-spinner";
import DownloadDropdown from "./Buttons/DownloadDropdown";
import CustomizeButton from "./Buttons/CustomizeButton";
import SaveCrosswordButton from "./Buttons/SaveCrosswordButton";
import CreatePreviewModalUnplacedWords from "./CreatePreviewModalUnplacedWords";
import { nameOfAllProducts } from "../../../Helpers/Variables";

export interface props {
  toggleGenerate: Function;
  toggleCrosswordCreatePreviewModal: Function;
  toggleCrosswordPremadePreviewModal: Function;
  showCrosswordCreatePreviewModal: boolean;
  setPremadeCrosswordId: Function;
  setRawDataToSave: Function;
  setProductIdToCheckIfAlreadySaved: Function;
  customizeType: string;
  savedCrosswordId: string;
  toggleCrosswordCustomizeType: Function;
  toggleCrosswordSavedPreviewModal: Function;
}

export interface state {
  grid: Array<any>;
  puzzleBlockHeightInCm: number;
  maxXCoord: number;
  maxYCoord: number;
  horizontalShift: number;
  verticalShift: number;
  lowestMostYCoord: number;
  leftMostXCoord: number;
  rightMostXCoord: number;
  wordsOnGrid: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  charactersOnGrid: { [key: string]: string };
  puzzleLoaded: boolean;
  HorizontalClueListFontSizeInPx: number;
  VerticalClueListFontSizeInPx: number;
  showTitle: boolean;
  showDateInput: boolean;
  showNameInput: boolean;
  customInput1Text: string;
  showCustomInput1: boolean;
  customInput2Text: string;
  showCustomInput2: boolean;
  bigifiedClueNumberLabel: boolean;
  titleText: string;
  changedPuzzleBlockHeightInCm: number;
  totalHeightInCm: number;
  totalWidthInCm: number;
  changedMaxXCoord: number;
  changedMaxYCoord: number;
  showAnswerKey: boolean;
  showCrosswordCreatePreviewModal: boolean;
  showUnplacedWords: boolean;
  unplacedWords: Array<{
    id: number;
    word: string;
    clue: string;
  }>;
  puzzleHeightInCm: number;
  customizeType: string;
  savedCrosswordId: string;
}

const StyledModalBody = styled("div")`
  background-color: #3c4c55;
  padding: 36px;
`;
const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  .modal-content {
    width: unset;
    border-radius: 0 !important;
    border: none;
    background-color: transparent;
  }
`;
const CloseButton = styled("div")`
  position: absolute;
  align-self: flex-end;
  // border: 1px solid #70c989;
  height: 34px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3c4c55;
  width: 36px;
  font-weight: 700;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  color: #ffffff;
`;
const GridBody = styled("div")<{
  maxheight: number;
}>`
  display: flex;
  flex-direction: row;
  max-height: ${props => props.maxheight}cm;
`;
const GridDiv = styled("div")<{
  puzzleBlockHeightInCm: number;
}>`
  display: flex;
  justify-content: center;

  .characterBox {
    height: ${props => props.puzzleBlockHeightInCm}cm;
    width: ${props => props.puzzleBlockHeightInCm}cm;
  }
  .emptyCharacterBox {
    height: ${props => props.puzzleBlockHeightInCm}cm;
    width: ${props => props.puzzleBlockHeightInCm}cm;
  }
  .wordId {
    font-size: 44%;
    color: black;
  }
`;
const Body = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ConfigButtons = styled("div")`
  display: flex;
  flex-direction: row;
  min-width: 390px;
  justify-content: space-between;
  margin-bottom: 25px;
`;
const CharacterBox = styled("div")`
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 1.6px 1.5px 0px 2.5px black;
  position: relative;
  z-index: 1;
`;

const EmptyCharacterBox = styled("div")`
  background-color: white;
  // box-shadow: 1.6px 1.5px 0px 2.5px white;
  position: relative;
`;
const WordId = styled("span")`
  font-weight: bold;
  left: 0.5px;
  position: absolute;
`;
const Character = styled("span")`
  font-weight: 600;
  font-size: 85%;
`;
const Description = styled("div")`
  margin-top: 30px;
  color: white;
`;
const Link = styled("span")`
  color: #70c989;
  &:hover {
    all: unset;
    cursor: pointer;
    color: #3f734d;
  }
`;
export class CreatePreviewModal extends React.Component<props, state> {
  titleDivHeightInCm: number;
  constructor(props: props) {
    super(props);
    this.titleDivHeightInCm = 2.5;
    this.state = {
      grid: [],
      puzzleBlockHeightInCm: 0.6,
      maxXCoord: 33,
      maxYCoord: 44,
      horizontalShift: 0,
      verticalShift: 0,
      lowestMostYCoord: 0,
      leftMostXCoord: 0,
      rightMostXCoord: 0,
      wordsOnGrid: [],
      charactersOnGrid: {},
      puzzleLoaded: false,
      HorizontalClueListFontSizeInPx: 16,
      VerticalClueListFontSizeInPx: 16,
      showTitle: true,
      showDateInput: false,
      showNameInput: false,
      customInput1Text: "",
      showCustomInput1: false,
      customInput2Text: "",
      showCustomInput2: false,
      bigifiedClueNumberLabel: false,
      titleText: "",
      changedPuzzleBlockHeightInCm: 0,
      totalHeightInCm: 0,
      totalWidthInCm: 0,
      changedMaxXCoord: 0,
      changedMaxYCoord: 0,
      showAnswerKey: true,
      showCrosswordCreatePreviewModal: false,
      showUnplacedWords: false,
      unplacedWords: [],
      puzzleHeightInCm: 0,
      customizeType: "",
      savedCrosswordId: ""
    };
  }

  componentDidUpdate(prevProps: props, prevState: state) {
    if (
      this.props.showCrosswordCreatePreviewModal === true &&
      prevState.showCrosswordCreatePreviewModal === false
    ) {
      if (this.state.customizeType === "createdSave") {
        this.getCreateYourOwnSavedCrossword();
      } else {
        this.generateGrid();
      }
    }
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      showCrosswordCreatePreviewModal?: boolean;
      puzzleLoaded?: boolean;
      customizeType?: string;
      savedCrosswordId?: string;
    } = {};

    if (
      nextProps.showCrosswordCreatePreviewModal !==
      prevState.showCrosswordCreatePreviewModal
    ) {
      update.showCrosswordCreatePreviewModal =
        nextProps.showCrosswordCreatePreviewModal;
    }

    if (!nextProps.showCrosswordCreatePreviewModal) {
      update.puzzleLoaded = false;
    }

    if (nextProps.customizeType !== prevState.customizeType) {
      update.customizeType = nextProps.customizeType;
    }

    if (nextProps.savedCrosswordId !== prevState.savedCrosswordId) {
      update.savedCrosswordId = nextProps.savedCrosswordId;
    }
    return Object.keys(update).length ? update : null;
  }

  generateGrid = async () => {
    let crosswordInfo = null;
    try {
      crosswordInfo = await getCrosswordInfo(
        store.getState().crosswordReducer.words,
        this.state.maxXCoord,
        this.state.maxYCoord
      );
    } catch (e) {
      console.log(e);
    }

    const {
      charactersOnGrid,
      wordsOnGrid,
      lowestMostYCoord,
      leftMostXCoord,
      rightMostXCoord,
      horizontalShift,
      verticalShift,
      unplacedWords
    } = crosswordInfo;

    this.props.setRawDataToSave(nameOfAllProducts[0], {
      charactersOnGrid,
      wordsOnGrid,
      lowestMostYCoord,
      horizontalShift,
      verticalShift,
      unplacedWords,
      maxXCoord: this.state.maxXCoord,
      maxYCoord: this.state.maxYCoord,
      titleText: this.state.titleText
    });
    this.props.setProductIdToCheckIfAlreadySaved("");

    let showUnplacedWords = false;
    if (unplacedWords.length > 0) {
      showUnplacedWords = true;
    }
    this.setState(
      {
        charactersOnGrid,
        wordsOnGrid,
        horizontalShift,
        verticalShift,
        lowestMostYCoord,
        leftMostXCoord,
        rightMostXCoord,
        showUnplacedWords,
        unplacedWords
      },
      () => this.setGeneratedGrid()
    );
  };

  getCreateYourOwnSavedCrossword = async () => {
    let result = null;
    try {
      result = await getSavedProductById(
        nameOfAllProducts[0],
        this.state.savedCrosswordId,
        this.props.setRawDataToSave,
        this.props.setProductIdToCheckIfAlreadySaved
      );
    } catch (e) {
      throw e;
    }

    const {
      charactersOnGrid,
      wordsOnGrid,
      lowestMostYCoord,
      leftMostXCoord,
      rightMostXCoord,
      horizontalShift,
      verticalShift,
      unplacedWords,
      maxXCoord,
      maxYCoord,
      titleText
    } = result.rawData;

    this.props.setRawDataToSave(nameOfAllProducts[0], {
      charactersOnGrid,
      wordsOnGrid,
      lowestMostYCoord,
      horizontalShift,
      verticalShift,
      unplacedWords,
      maxXCoord: this.state.maxXCoord,
      maxYCoord: this.state.maxYCoord,
      titleText: this.state.titleText
    });

    let showUnplacedWords = false;
    if (unplacedWords.length > 0) {
      showUnplacedWords = true;
    }
    this.setState(
      {
        charactersOnGrid,
        wordsOnGrid,
        horizontalShift,
        verticalShift,
        lowestMostYCoord,
        leftMostXCoord,
        rightMostXCoord,
        showUnplacedWords,
        unplacedWords
      },
      () => this.setGeneratedGrid()
    );
  };

  getWordId = (xCoord: number, yCoord: number) => {
    // unshift after this.centerPuzzle() has run to find proper placement of words
    xCoord -= this.state.horizontalShift;
    yCoord -= this.state.verticalShift;

    const { wordsOnGrid } = this.state;
    for (const wordObj of wordsOnGrid) {
      if (wordObj.startCoord === `${xCoord},${yCoord}`) {
        return wordObj.id;
      }
    }
  };

  getUpdatedGridSize = () => {
    // refactor this code into a function and also use that function in getWatermarkDiv()
    let {
      maxXCoord,
      maxYCoord,
      lowestMostYCoord,
      leftMostXCoord,
      rightMostXCoord
    } = this.state;

    let begginingXCoord = 0;
    let endingXCoord = maxXCoord;

    // ensure crossword is at least 10 blocks in width
    let leastXDifference = 0; // this is the least number of empty blocks found on either left or right side of populated puzzle area
    if (leftMostXCoord < maxXCoord - rightMostXCoord) {
      leastXDifference = leftMostXCoord;
    } else {
      leastXDifference = maxXCoord - rightMostXCoord;
    }
    if (maxXCoord - leastXDifference * 2 < 9) {
      begginingXCoord = Math.round(maxXCoord / 2) - 5;
      endingXCoord = Math.round(maxXCoord / 2) + 5;
    }

    // ensure crossword is at least 10 blocks in height
    if (maxYCoord - lowestMostYCoord < 9) {
      lowestMostYCoord = lowestMostYCoord - 9;
    }

    return {
      begginingXCoord,
      endingXCoord,
      lowestMostYCoord
    };
  };

  setGeneratedGrid = () => {
    let grid = [];

    const {
      begginingXCoord,
      endingXCoord,
      lowestMostYCoord
    } = this.getUpdatedGridSize();

    for (let x = begginingXCoord; x < endingXCoord + 1; x += 1) {
      let gridLine = [];
      for (let y = this.state.maxYCoord; y >= lowestMostYCoord; y -= 1) {
        const { charactersOnGrid } = this.state;
        if (`${x},${y}` in charactersOnGrid) {
          gridLine.push(
            <CharacterBox className="characterBox" key={x + y}>
              <WordId className="wordId">{this.getWordId(x, y)}</WordId>
              <Character className="character">
                {charactersOnGrid[`${x},${y}`]}
              </Character>
            </CharacterBox>
          );
        } else {
          gridLine.push(
            <EmptyCharacterBox className="emptyCharacterBox" key={x + y}>
              {charactersOnGrid[`${x},${y}`]}
            </EmptyCharacterBox>
          );
        }
      }
      // add line to the grid
      grid.push(<div key={x}>{gridLine}</div>);
      // start new line on grid
      grid.push(<br key={x + 2222} />);
    }

    const puzzleHeightInCm =
      (this.state.maxYCoord - lowestMostYCoord + 1) *
      this.state.puzzleBlockHeightInCm;

    this.setState({ grid, puzzleHeightInCm, puzzleLoaded: true });
  };

  getWatermarkDiv = () => {
    const {
      begginingXCoord,
      endingXCoord,
      lowestMostYCoord
    } = this.getUpdatedGridSize();

    const puzzleHeightInCm =
      (this.state.maxYCoord - lowestMostYCoord + 1) *
      this.state.puzzleBlockHeightInCm;
    const puzzleWidthInCm =
      (endingXCoord - begginingXCoord + 1) * this.state.puzzleBlockHeightInCm;

    let watermarkLengthToUse = puzzleWidthInCm;
    if (puzzleHeightInCm < puzzleWidthInCm) {
      watermarkLengthToUse = puzzleHeightInCm;
    }
    const watermarkFontSizeInPx = Math.round(
      (watermarkLengthToUse * 37.7952755905511) / 5
    );

    return (
      <div
        style={{
          zIndex: 5,
          height: `${puzzleHeightInCm}cm`,
          width: `${puzzleWidthInCm}cm`,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "4.2vmin",
          opacity: 0.7
        }}
      >
        <span
          style={{
            fontSize: `${watermarkFontSizeInPx}px`,
            transform: "rotate(-45deg)",
            fontWeight: "bold"
          }}
        >
          WATERMARK
        </span>
      </div>
    );
  };

  handleOpenSample = () => {
    this.props.setPremadeCrosswordId(1);
    this.props.toggleGenerate(false);
    this.props.toggleCrosswordPremadePreviewModal(true);
  };

  handleToggle = () => {
    this.props.toggleCrosswordCustomizeType("");
    this.props.toggleCrosswordSavedPreviewModal(false);
    this.props.toggleCrosswordCreatePreviewModal(false);
  };

  render(): JSX.Element {
    return (
      <StyledModal
        isOpen={this.state.showCrosswordCreatePreviewModal}
        toggle={this.handleToggle}
        centered={true}
      >
        <CloseButton onClick={this.handleToggle}>X</CloseButton>
        <StyledModalBody>
          {this.state.puzzleLoaded ? (
            <Body>
              <ConfigButtons>
                <DownloadDropdown
                  isPremadeDropdown={false}
                  isSaveDropdown={false}
                  isCreateDropdown={true}
                  isCreatedSave={false}
                />
                <CustomizeButton />
                {this.state.customizeType !== "createdSave" ? (
                  <SaveCrosswordButton
                    isPremadeButton={false}
                    isSaveButton={false}
                    isCreateButton={true}
                    type={nameOfAllProducts[0]}
                  />
                ) : null}
              </ConfigButtons>
              <GridBody maxheight={this.state.puzzleHeightInCm}>
                <GridDiv
                  puzzleBlockHeightInCm={this.state.puzzleBlockHeightInCm}
                >
                  {/* {this.getWatermarkDiv()} */}
                  {this.state.grid}
                </GridDiv>
                {this.state.showUnplacedWords ? (
                  <CreatePreviewModalUnplacedWords
                    unplacedWords={this.state.unplacedWords}
                  ></CreatePreviewModalUnplacedWords>
                ) : null}
              </GridBody>
              <Description>
                This is just a preview of the puzzle.{" "}
                <Link onClick={() => this.handleOpenSample()}>Click here</Link>{" "}
                or open any of the pre made crosswords to see what it looks like
                when downloaded or being customized.
              </Description>
            </Body>
          ) : (
            <Loader type="Rings" color="#70c989" height={400} width={400} />
          )}
        </StyledModalBody>
      </StyledModal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    showCrosswordCreatePreviewModal:
      state.crosswordReducer.showCrosswordCreatePreviewModal,
    savedCrosswordId: state.crosswordReducer.savedCrosswordId,
    customizeType: state.crosswordReducer.customizeType
  };
}

const CreatePreviewModalContainer = connect(mapStateToProps, {
  toggleGenerate,
  toggleCrosswordCreatePreviewModal,
  toggleCrosswordPremadePreviewModal,
  setPremadeCrosswordId,
  setRawDataToSave,
  setProductIdToCheckIfAlreadySaved,
  toggleCrosswordCustomizeType,
  toggleCrosswordSavedPreviewModal
})(CreatePreviewModal);

export default CreatePreviewModalContainer;
