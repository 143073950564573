import axios from "axios";
import { apiUrl } from "../apiconfig";

export async function getPremadeProductById(
  type: string,
  id: string,
  setRawDataToSave: Function,
  setProductIdToCheckIfAlreadySaved: Function
) {
  // pull premade product from DynamoDB using ID
  let result = {};
  try {
    // @ts-ignore
    result = await axios({
      method: "get",
      url: `${apiUrl}/premade/id/${id}/${type}`
    });
  } catch (e) {
    throw e;
  }

  // @ts-ignore
  setRawDataToSave(type, result.data.rawData);
  setProductIdToCheckIfAlreadySaved(id);
  // @ts-ignore
  return result.data.rawData;
}

export async function getAllCategories(type: string) {
  let result = null;

  try {
    result = await axios({
      method: "get",
      url: `${apiUrl}/premade/categories/${type}`
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function getPremadeListForCategory(
  type: string,
  category: string
) {
  let result = null;

  try {
    result = await axios({
      method: "get",
      url: `${apiUrl}/premade/category/${category}/${type}`
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}
