import axios from "axios";
import { apiUrl } from "../apiconfig";

export async function redirectToStripeCheckoutSession(paymentPlanId: string) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;
  try {
    result = await axios({
      method: "put",
      url: `${apiUrl}/payment`,
      data: {
        paymentPlanId,
        email,
        redirectUrl: window.location.origin
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function pullPaymentPlans(setPaymentPlans: Function) {
  let result = null;
  try {
    result = await axios({
      method: "get",
      url: `${apiUrl}/payment/paymentPlans`
    });
  } catch (e) {
    throw e;
  }

  setPaymentPlans(result.data);
}

export async function getSuccessfulPaymentDescription(
  checkoutSessionId: string
) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;
  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/Payment/getSuccessfulPaymentDescription`,
      data: {
        checkoutSessionId,
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function checkIfUserIsPaying(toggleIsPayingUser: Function) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;
  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/Payment/checkIfUserIsPaying`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  toggleIsPayingUser(result.data.isPayingUser);
  return result.data;
}

export async function getUserPaymentInfo() {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;
  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/Payment/getUserPaymentInfo`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function updateReceiptEmail(updatedReceiptEmail: string) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;
  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/Payment/updateReceiptEmail`,
      data: {
        email,
        updatedReceiptEmail
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function cancelUserPlan(email: string) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );

  let result = null;
  try {
    result = await axios({
      method: "delete",
      url: `${apiUrl}/Payment/cancelAllSubscription`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function incrementPrintCount() {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;
  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/Payment/incrementPrintCount`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}
