import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
// @ts-ignore
import isObject from "lodash.isobject";
import styled, { keyframes } from "styled-components";
import DefaultPage from "./components/DefaultPage/DefaultPage";
import Navigation from "./components/Navigation/Navigation";
import UserAuthModal from "./components/UserAuth/UserAuthModal";
import PaymentPlansModal from "./components/Payment/PaymentPlansModal";
import { connect } from "react-redux";
import axios from "axios";
import { toggleIsLoggedIn, setPaymentPlans, toggleIsPayingUser } from "./redux";
import { verifyAccess, setAccessTokenAndAccessUsername } from "./API/Auth";
import { pullPaymentPlans, checkIfUserIsPaying } from "./API/Payment";
import Toast from "./components/Helpers/Toast";
import { nameOfAllProducts } from "./components/Helpers/Variables";
import CreatePreviewModal from "./components/Crossword/FinalPage/PreviewModal/CreatePreviewModal";
import PremadePreviewModal from "./components/Crossword/FinalPage/PreviewModal/PremadePreviewModal";
import SavedPreviewModal from "./components/Crossword/FinalPage/PreviewModal/SavedPreviewModal";
import ExpiredPlanModal from "./components/ExpiredPlan/ExpiredPlanModal";

export interface props {
  toggleIsLoggedIn: Function;
  isLoggedIn: boolean;
  isPayingUser: boolean;
  setPaymentPlans: Function;
  toggleIsPayingUser: Function;
}

export interface state {
  loading: boolean;
  isLoggedIn: boolean;
  isPayingUser: boolean;
}

const firstLoad = keyframes`
from {
   opacity: 0;
}
to {
   opacity: 1;
}
`;
const Div = styled("div")`
  line-height: 1.1;
  font-family: Helvetica !important;
  animation: ${firstLoad} 0.5s ease;
`;
const Loading = styled("div")`
  display: flex;
  flex-direction: column;
  background-color: #2a3840;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;
const Logo = styled("div")`
  color: #70c989;
  font-size: 7vw;
  font-weight: bold;
`;
export class Main extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      loading: true,
      isLoggedIn: false,
      isPayingUser: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: { isLoggedIn?: boolean; isPayingUser?: boolean } = {};

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }

    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    return Object.keys(update).length ? update : null;
  }

  async componentDidMount() {
    // intercept axios for new access token and username
    this.interceptAxios();

    // check if user is logged in before loading site
    try {
      await verifyAccess(this.props.toggleIsLoggedIn);
    } catch (e) {
      console.log(e);
    }

    // check if user is paying
    if (this.state.isLoggedIn) {
      try {
        await checkIfUserIsPaying(this.props.toggleIsPayingUser);
      } catch (e) {
        console.log(e);
      }
    }

    this.setState({
      loading: false
    });

    // pull payment plans from api
    try {
      await pullPaymentPlans(this.props.setPaymentPlans);
    } catch (e) {
      throw e;
    }
  }

  interceptAxios = () => {
    axios.interceptors.response.use((response: any) => {
      if (
        response !== undefined &&
        response !== null &&
        "data" in response &&
        response.data !== undefined &&
        response.data !== null &&
        isObject(response.data) &&
        "newAccessToken" in response.data &&
        response.data.newAccessToken !== undefined &&
        "newAccessUsername" in response.data &&
        response.data.newAccessUsername !== undefined
      ) {
        setAccessTokenAndAccessUsername(
          response.data.newAccessToken,
          response.data.newAccessUsername
        );
      }
      return response;
    });
  };

  getProductRoutes = (finalPage: boolean) => {
    const productRoutes = [];

    for (const productName of nameOfAllProducts) {
      productRoutes.push(
        <Route
          key={productName}
          path={finalPage ? `/${productName}FinalPage` : `/${productName}`}
          component={() => (
            <DefaultPage
              component={finalPage ? `${productName}FinalPage` : productName}
            />
          )}
        />
      );
    }

    return productRoutes;
  };

  public render(): JSX.Element {
    return (
      <Div>
        {this.state.loading ? (
          <Loading>{/* <Logo>Loading... :)</Logo> */}</Loading>
        ) : (
          <Router>
            <Toast autoClose={10000} />
            {/* Crossword modals */}
            <CreatePreviewModal />
            <PremadePreviewModal />
            <SavedPreviewModal />
            {/* Crossword modals */}
            <UserAuthModal />
            <PaymentPlansModal />
            <ExpiredPlanModal />
            <Navigation />
            <Switch>
              {this.getProductRoutes(false)}
              {this.state.isLoggedIn && this.state.isPayingUser
                ? this.getProductRoutes(true)
                : null}
              {this.state.isLoggedIn ? (
                <Route
                  path="/Profile"
                  component={() => <DefaultPage component={"Profile"} />}
                />
              ) : null}
              {this.state.isLoggedIn && this.state.isPayingUser ? (
                <Route
                  path="/PaymentSuccessful"
                  component={() => (
                    <DefaultPage component={"PaymentSuccessful"} />
                  )}
                />
              ) : null}
              {this.state.isLoggedIn ? (
                <Route
                  path="/PaymentFailed"
                  component={() => <DefaultPage component={"PaymentFailed"} />}
                />
              ) : null}
              <Route
                path="/AboutUs"
                component={() => <DefaultPage component={"AboutUs"} />}
              />
              <Route
                path="/ContactUs"
                component={() => <DefaultPage component={"ContactUs"} />}
              />
              <Route
                path="/PrivacyPolicy"
                component={() => <DefaultPage component={"PrivacyPolicy"} />}
              />
              <Route
                path="/TermsAndConditions"
                component={() => (
                  <DefaultPage component={"TermsAndConditions"} />
                )}
              />
              <Redirect to="/Crossword" />
            </Switch>
          </Router>
        )}
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const MainContainer = connect(mapStateToProps, {
  toggleIsLoggedIn,
  setPaymentPlans,
  toggleIsPayingUser
})(Main);

export default MainContainer;
