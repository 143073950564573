import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  checkIfUserIsPaying,
  getUserPaymentInfo,
  updateReceiptEmail,
  cancelUserPlan
} from "../../API/Payment";
import {
  toggleIsPayingUser,
  toggleToast,
  togglePaymentPlansModal
} from "../../redux";
import {
  paymentPlansInterface,
  paymentPlansModalTypes
} from "../Helpers/Variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { InputGroup, InputGroupAddon, Button, Input, Modal } from "reactstrap";
//@ts-ignore
import Loader from "react-loader-spinner";
import moment from "moment";

export interface props {
  isLoggedIn: boolean;
  isPayingUser: boolean;
  toggleIsPayingUser: Function;
  paymentPlans: Array<paymentPlansInterface>;
  toggleToast: Function;
  togglePaymentPlansModal: Function;
}
export interface state {
  isLoggedIn: boolean;
  isPayingUser: boolean;
  planDetailsLoaded: boolean;
  activePaymentPlan: paymentPlansInterface;
  paymentPlans: Array<paymentPlansInterface>;
  emailText: string;
  attemptingReceiptEmailUpdate: boolean;
  showUpdatePaymentDetailsModal: boolean;
  showUpdatePaymentPlanModal: boolean;
  showCancelPaymentModal: boolean;
  isCurrentlyInTrial: boolean;
  paymentDateTime: string;
  showCancelPaymentConfirmationModal: boolean;
  cancelEmailText: string;
  attemptPlanCancellation: boolean;
  printsDone: string;
}

const Div = styled("div")`
  margin: 10px 0 10px 0;
  width: 100%;
  background-color: #3c4c55;
  padding: 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
  padding-bottom: 20px;
`;
const Description = styled("div")`
  padding-top: 30px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 20px;
`;
const TableColumn = styled("div")`
  display: flex;
  flex-direction: row;
  .category {
    color: white;
  }
`;
const TableCell = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-weight: 500;
  color: #70c989;
  .checkIcon {
    color: #70c989;
  }
  .crossIcon {
    color: red;
  }
`;
const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
    min-width: 120px !important;
    max-width: 150px !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const ReactStrapButton = styled(Button)`
  margin-left: 5px !important;
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 30px !important;
  padding: 0 13px 0 13px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const PlanDetails = styled("div")``;
const VariousButtons = styled("div")`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  .modal-content {
    max-width: 365px;
    display: flex;
    border-radius: 0 !important;
    border: none;
    background-color: #3c4c55;
    padding: 36px;
  }
`;
const StyledModalHeader = styled("div")`
  width: 100%;
  display: flex;
  background-color: transparent;
  .closeButtonTab {
    width: 36px;
    font-weight: 700;
  }
  .loginButtonTab {
    border-right: 1px solid #70c989;
  }
`;
const Tab = styled("div")<{ active?: number }>`
  height: 34px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3c4c55;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  color: ${props => (props.active ? "#70c989" : "#ffffff")};
  box-shadow: ${props =>
    props.active ? "inset 0px -5px 0px 0px #70c989" : null};
`;
const CloseModalDiv = styled("div")`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;
const ModalText = styled("div")`
  color: white;
  margin: 10px 0 10px 0;
`;
const Footer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
`;
export class Payment extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isPayingUser: false,
      planDetailsLoaded: false,
      activePaymentPlan: {
        id: "",
        plan: "",
        amountInCents: 0,
        moneyText: "",
        currency: "",
        oneTimePayment: false,
        payFormDescription: "",
        savingsText: "",
        promotionText: "",
        downloadYourOwn: "",
        downloadPremade: "",
        saves: "",
        customization: false,
        noAds: false,
        purchaseButtonText: "",
        showPurchaseButton: false,
        restrictMaxPrints: false,
        trialDays: 0
      },
      paymentPlans: [],
      emailText: " ",
      attemptingReceiptEmailUpdate: false,
      showUpdatePaymentDetailsModal: false,
      showUpdatePaymentPlanModal: false,
      showCancelPaymentModal: false,
      showCancelPaymentConfirmationModal: false,
      isCurrentlyInTrial: false,
      paymentDateTime: "",
      cancelEmailText: "",
      attemptPlanCancellation: false,
      printsDone: ""
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isPayingUser?: boolean;
      isLoggedIn?: boolean;
      paymentPlans?: Array<paymentPlansInterface>;
    } = {};

    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }

    if (nextProps.paymentPlans !== prevState.paymentPlans) {
      update.paymentPlans = nextProps.paymentPlans;
    }

    return Object.keys(update).length ? update : null;
  }

  async componentDidMount() {
    // check if user is paying
    if (this.state.isLoggedIn) {
      try {
        await checkIfUserIsPaying(this.props.toggleIsPayingUser);
      } catch (e) {
        console.log(e);
      }
    }

    if (this.state.isLoggedIn && this.state.isPayingUser) {
      try {
        const userPaymentInfo = await getUserPaymentInfo();
        let activePaymentPlan = null;
        for (const paymentPlanObj of this.state.paymentPlans) {
          if (paymentPlanObj.id === userPaymentInfo.paymentPlanId) {
            activePaymentPlan = paymentPlanObj;
          }
        }

        // set if user is currenlty in trial
        let isCurrentlyInTrial = false;
        if (!activePaymentPlan.oneTimePayment) {
          // isCurrentlyInTrial
          const daysPassed =
            moment().diff(moment(userPaymentInfo.paymentDateTime), "days") + 1;
          if (daysPassed < activePaymentPlan.trialDays) {
            isCurrentlyInTrial = true;
          }
        }
        this.setState({
          activePaymentPlan,
          emailText: userPaymentInfo.receiptEmail,
          planDetailsLoaded: true,
          isCurrentlyInTrial,
          paymentDateTime: userPaymentInfo.paymentDateTime,
          printsDone: userPaymentInfo.prints
        });
      } catch (e) {
        throw e;
      }
    }
  }

  getPaymentDetails() {
    if (!this.state.planDetailsLoaded) {
      return <Description>Loading, please wait...</Description>;
    }

    return (
      <div>
        <PlanDetails>{this.getVisualDetails()}</PlanDetails>
        {this.state.activePaymentPlan.oneTimePayment ? null : (
          <StyledInputGroup>
            <InputGroupAddon className="titleLabel" addonType="append">
              Receipt Email
            </InputGroupAddon>
            <Input
              type="text"
              className="loginInputs"
              value={this.state.emailText}
              onChange={e => {
                this.setState({ emailText: e.target.value });
              }}
              style={{
                border: "1px solid #70c989",
                borderRadius: 0
              }}
            />
            <ReactStrapButton
              onClick={() => this.attemptReceiptEmailUpdate()}
              disabled={
                this.state.attemptingReceiptEmailUpdate ||
                this.state.emailText === ""
              }
              style={{
                width: "80px"
              }}
            >
              {this.state.attemptingReceiptEmailUpdate ? (
                <Loader type="Rings" color="#fff" height={30} width={30} />
              ) : (
                "Update"
              )}
            </ReactStrapButton>
          </StyledInputGroup>
        )}
        <VariousButtons>
          {this.getUpdatePaymentDetailsButton()}
          {this.getUpdatePaymentPlanButton()}
          {this.cancelPaymentButton()}
        </VariousButtons>
        {this.state.isCurrentlyInTrial ? (
          <Description>{`Note: your trial will end on ${moment(
            this.state.paymentDateTime
          )
            .add(7, "days")
            .format("YYYY-MM-DD")}`}</Description>
        ) : null}
        {this.state.activePaymentPlan.restrictMaxPrints ? (
          <Description>
            {`Downloads Done So Far: ${this.state.printsDone}`}
          </Description>
        ) : null}
        {this.state.activePaymentPlan.restrictMaxPrints ? (
          <Description>
            Note: Your account will return to the 'Free Tier' once you reach the
            'Download Your Own' limit.
          </Description>
        ) : null}
      </div>
    );
  }

  getVisualDetails = () => {
    const plans = [];

    plans.push(
      <TableColumn key={0}>
        <TableCell
          className="category"
          style={{ order: 0, fontWeight: "bold" }}
        >
          Active Plan
        </TableCell>
        <TableCell className="category" style={{ order: 1 }}>
          Price
        </TableCell>
        <TableCell className="category" style={{ order: 2 }}>
          Download Your Own
        </TableCell>
        <TableCell className="category" style={{ order: 3 }}>
          Download Premade
        </TableCell>
        <TableCell className="category" style={{ order: 4 }}>
          Saves
        </TableCell>
        <TableCell className="category" style={{ order: 5 }}>
          No Ads
        </TableCell>
        <TableCell className="category" style={{ order: 6 }}>
          Customization
        </TableCell>
      </TableColumn>
    );

    plans.push(
      <TableColumn key={1}>
        <TableCell style={{ order: 0, fontWeight: "bold" }}>
          {this.state.activePaymentPlan.plan}
        </TableCell>
        <TableCell style={{ order: 1 }}>
          {this.state.activePaymentPlan.moneyText}
        </TableCell>
        <TableCell style={{ order: 2 }}>
          {this.state.activePaymentPlan.downloadYourOwn}
        </TableCell>
        <TableCell style={{ order: 3 }}>
          {this.state.activePaymentPlan.downloadPremade}
        </TableCell>
        <TableCell style={{ order: 4 }}>
          {this.state.activePaymentPlan.saves}
        </TableCell>
        <TableCell style={{ order: 5 }}>
          {this.state.activePaymentPlan.noAds ? (
            <FontAwesomeIcon icon={faCheck} className="checkIcon" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="crossIcon" />
          )}
        </TableCell>
        <TableCell style={{ order: 6 }}>
          {this.state.activePaymentPlan.customization ? (
            <FontAwesomeIcon icon={faCheck} className="checkIcon" />
          ) : (
            <FontAwesomeIcon icon={faTimes} className="crossIcon" />
          )}
        </TableCell>
      </TableColumn>
    );

    return plans;
  };

  attemptReceiptEmailUpdate = async () => {
    this.setState({
      attemptingReceiptEmailUpdate: true
    });

    let result = null;
    try {
      result = await updateReceiptEmail(this.state.emailText);
    } catch (e) {
      // ask user to enter confirmation code
      this.props.toggleToast(
        true,
        "Unable to update receipt email, please try again later"
      );
      this.setState({
        attemptingReceiptEmailUpdate: false
      });
      return;
    }

    if ("updated" in result && result.updated === true) {
      this.props.toggleToast(true, "Receipt email successfully updated");
      this.setState({
        attemptingReceiptEmailUpdate: false
      });
    } else {
      this.props.toggleToast(
        true,
        "Unable to update receipt email, please contact wordymaker@gmail.com if this problem persists"
      );
      this.setState({
        attemptingReceiptEmailUpdate: false
      });
    }
  };

  getUpdatePaymentDetailsButton = () => {
    if (this.state.activePaymentPlan.oneTimePayment) {
      return null;
    }
    return (
      <ReactStrapButton
        onClick={() => {
          this.setState({ showUpdatePaymentDetailsModal: true });
        }}
      >
        Update Payment Details
      </ReactStrapButton>
    );
  };
  getUpdatePaymentPlanButton = () => {
    return (
      <ReactStrapButton
        onClick={() => {
          this.setState({ showUpdatePaymentPlanModal: true });
        }}
      >
        Change Payment Plan
      </ReactStrapButton>
    );
  };
  cancelPaymentButton = () => {
    return (
      <ReactStrapButton
        onClick={() => {
          this.setState({ showCancelPaymentModal: true });
        }}
      >
        {this.state.isCurrentlyInTrial ? "Cancel Trial" : "Cancel Plan"}
      </ReactStrapButton>
    );
  };

  getUpdatePaymentDetailsModal = () => {
    return (
      <StyledModal
        toggle={() => {
          this.setState({ showUpdatePaymentDetailsModal: false });
        }}
        isOpen={this.state.showUpdatePaymentDetailsModal}
        centered={true}
      >
        <StyledModalHeader>
          <CloseModalDiv>
            <Tab
              className="closeButtonTab"
              onClick={() => {
                this.setState({ showUpdatePaymentDetailsModal: false });
              }}
            >
              X
            </Tab>
          </CloseModalDiv>
        </StyledModalHeader>
        <ModalText style={{ whiteSpace: "pre-line" }}>
          {
            "Currently, the only way to update your payment details is to: \n\n1. Cancel plan \n2. Activate a plan"
          }
        </ModalText>
        <Footer>
          <ReactStrapButton
            onClick={() => {
              this.setState({ showUpdatePaymentDetailsModal: false });
            }}
          >
            Close
          </ReactStrapButton>
        </Footer>
      </StyledModal>
    );
  };
  getUpdatePaymentPlanModal = () => {
    return (
      <StyledModal
        toggle={() => {
          this.setState({ showUpdatePaymentPlanModal: false });
        }}
        isOpen={this.state.showUpdatePaymentPlanModal}
        centered={true}
      >
        <StyledModalHeader>
          <CloseModalDiv>
            <Tab
              className="closeButtonTab"
              onClick={() => {
                this.setState({ showUpdatePaymentPlanModal: false });
              }}
            >
              X
            </Tab>
          </CloseModalDiv>
        </StyledModalHeader>
        <ModalText style={{ whiteSpace: "pre-line" }}>
          {
            "Currently, the only way to update your payment details is to: \n\n1. Cancel plan \n2. Activate a plan"
          }
        </ModalText>
        <ModalText style={{ whiteSpace: "pre-line" }}>
          Feel free to browse payment plans using the button below
        </ModalText>
        <Footer>
          <ReactStrapButton
            onClick={() =>
              this.props.togglePaymentPlansModal(
                true,
                paymentPlansModalTypes[1],
                false
              )
            }
          >
            Browse Plans
          </ReactStrapButton>
          <ReactStrapButton
            onClick={() => {
              this.setState({ showUpdatePaymentPlanModal: false });
            }}
          >
            Close
          </ReactStrapButton>
        </Footer>
      </StyledModal>
    );
  };
  getCancelPaymentModal = () => {
    return (
      <StyledModal
        toggle={() => {
          this.setState({ showCancelPaymentModal: false });
        }}
        isOpen={this.state.showCancelPaymentModal}
        centered={true}
      >
        <StyledModalHeader>
          <CloseModalDiv>
            <Tab
              className="closeButtonTab"
              onClick={() => {
                this.setState({ showCancelPaymentModal: false });
              }}
            >
              X
            </Tab>
          </CloseModalDiv>
        </StyledModalHeader>
        <ModalText style={{ whiteSpace: "pre-line" }}>
          {`Are you sure you want to cancel ${
            this.state.isCurrentlyInTrial ? "your trial for" : ""
          } the ${
            this.state.activePaymentPlan.plan
          } plan for your account? \n\nYour account will lose all the benefits that come with the ${
            this.state.activePaymentPlan.plan
          } plan \n\nIf you're sure, click 'Cancel Plan' below, otherwise click nevermind`}
        </ModalText>
        {this.state.isCurrentlyInTrial ? (
          <ModalText>{`Note: your trial will end on ${moment(
            this.state.paymentDateTime
          )
            .add(7, "days")
            .format("YYYY-MM-DD")}`}</ModalText>
        ) : null}
        <Footer>
          <ReactStrapButton
            onClick={() => {
              this.setState({ showCancelPaymentModal: false });
            }}
          >
            Close
          </ReactStrapButton>
          <ReactStrapButton
            onClick={() => {
              this.setState({ showCancelPaymentConfirmationModal: true });
            }}
          >
            Cancel Plan
          </ReactStrapButton>
        </Footer>
      </StyledModal>
    );
  };

  getCancelPaymentConfirmationModal = () => {
    return (
      <StyledModal
        toggle={() => {
          this.setState({
            showCancelPaymentModal: false,
            showCancelPaymentConfirmationModal: false
          });
        }}
        isOpen={this.state.showCancelPaymentConfirmationModal}
        centered={true}
      >
        <StyledModalHeader>
          <CloseModalDiv>
            <Tab
              className="closeButtonTab"
              onClick={() => {
                this.setState({
                  showCancelPaymentModal: false,
                  showCancelPaymentConfirmationModal: false
                });
              }}
            >
              X
            </Tab>
          </CloseModalDiv>
        </StyledModalHeader>
        <ModalText style={{ whiteSpace: "pre-line" }}>
          {
            "Please enter your account email to cancel your paid membership. \n\nNote: you can also cancel you paid membership by contacting wordymaker@gmail.com"
          }
        </ModalText>
        <StyledInputGroup>
          <InputGroupAddon className="titleLabel" addonType="append">
            Email
          </InputGroupAddon>
          <Input
            type="email"
            className="loginInputs"
            value={this.state.cancelEmailText}
            onChange={e => {
              this.setState({ cancelEmailText: e.target.value });
            }}
            style={{
              border: "0.75px solid #70c989",
              borderRadius: 0
            }}
          />
        </StyledInputGroup>
        <Footer>
          <ReactStrapButton
            onClick={() => {
              this.setState({
                showCancelPaymentModal: false,
                showCancelPaymentConfirmationModal: false
              });
            }}
          >
            Nevermind
          </ReactStrapButton>
          <ReactStrapButton
            disabled={
              this.state.cancelEmailText === "" ||
              this.state.attemptPlanCancellation
            }
            onClick={() => this.attemptPlanCancellation()}
            style={{
              width: "110px"
            }}
          >
            {this.state.attemptPlanCancellation ? (
              <Loader type="Rings" color="#fff" height={36} width={36} />
            ) : (
              "Cancel Plan"
            )}
          </ReactStrapButton>
        </Footer>
      </StyledModal>
    );
  };

  attemptPlanCancellation = async () => {
    this.setState({
      attemptPlanCancellation: true
    });

    let result = null;
    try {
      result = await cancelUserPlan(this.state.cancelEmailText);
    } catch (e) {
      this.props.toggleToast(
        true,
        "Unable to cancel, please contact wordymaker@gmail.com if this problem persists"
      );
      this.setState({
        attemptPlanCancellation: false
      });
      return;
    }

    if ("allCanceled" in result && result.allCanceled === true) {
      this.props.toggleToast(
        true,
        "Successfuly cancelled. You are no longer a paid member"
      );
      this.props.toggleIsPayingUser(false);
    } else {
      this.props.toggleToast(
        true,
        "Unable to cancel, please contact wordymaker@gmail.com if this problem persists"
      );
      this.setState({
        attemptPlanCancellation: false,
        showCancelPaymentModal: false,
        showCancelPaymentConfirmationModal: false
      });
    }
  };

  getUnpaidUserInfo = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70%"
        }}
      >
        <ReactStrapButton
          onClick={() =>
            this.props.togglePaymentPlansModal(
              true,
              paymentPlansModalTypes[1],
              true
            )
          }
        >
          Browse Plans
        </ReactStrapButton>
      </div>
    );
  };

  render(): JSX.Element {
    return (
      <Div>
        {this.state.isPayingUser ? (
          <div>
            {this.getUpdatePaymentDetailsModal()}
            {this.getUpdatePaymentPlanModal()}
            {this.getCancelPaymentModal()}
            {this.getCancelPaymentConfirmationModal()}
          </div>
        ) : null}
        <Title>Payment</Title>
        {this.state.isPayingUser
          ? this.getPaymentDetails()
          : this.getUnpaidUserInfo()}
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser,
    paymentPlans: state.paymentReducer.paymentPlans
  };
}

const PaymentContainer = connect(mapStateToProps, {
  toggleIsPayingUser,
  toggleToast,
  togglePaymentPlansModal
})(Payment);

export default PaymentContainer;
