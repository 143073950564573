import * as React from "react";
import styled from "styled-components";
import Switch from "react-switch";
import {
  ButtonGroup,
  Button,
  InputGroup,
  Input,
  InputGroupAddon
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { exportPdf, exportPng } from "../../Helpers/DownloadHelpers";
import SaveCrosswordButton from "./PreviewModal/Buttons/SaveCrosswordButton";
import { connect } from "react-redux";
import { nameOfAllProducts } from "../../Helpers/Variables";
import DownloadDropdown from "./PreviewModal/Buttons/DownloadDropdown";

export interface props {
  togglePreview: Function;
  totalHeightInCm: number;
  changedTotalHeightInCm: number;
  showAnswerKey: boolean;
  showTitle: boolean;
  titleText: string;
  showDateInput: boolean;
  showNameInput: boolean;
  showCustomInput1: boolean;
  customInput1Text: string;
  showCustomInput2: boolean;
  customInput2Text: string;
  bigifiedClueNumberLabel: boolean;
  VerticalClueListFontSizeInPx: number;
  HorizontalClueListFontSizeInPx: number;
  puzzleBlockHeightInCm: number;
  blockSizeChanged: boolean;
  refreshPreview: Function;
  maxXCoord: number;
  changedMaxXCoord: number;
  maxXCoordHasChanged: boolean;
  maxYCoord: number;
  changedMaxYCoord: number;
  maxYCoordHasChanged: boolean;
  sizeHasChanged: boolean;
  customizeType?: string;
}

export interface state {
  titleText: string;
  customInput1Text: string;
  customInput2Text: string;
  customizeType: string;
}

const Div = styled("div")<{ totalHeightInCm: number }>`
  height: calc(${props => props.totalHeightInCm}cm - 2.5px);
  overflow: auto;
  // top: calc(-${props => props.totalHeightInCm}cm + 2.5px);
  width: 7cm;
  position: absolute;
  left: -267.5px;
  background-color: #70c989;
`;
const Group = styled("div")`
  width: 100%;
  border-bottom: solid 2.5px #252a31;
  padding-top: 15px;
  padding-bottom: 15px;
`;
const Title = styled("div")`
  font-weight: bold;
  color: #3a3434;
  font-size: 20px;
  padding-bottom: 15px;
`;
const ReactStrapButton = styled(Button)`
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const OptionContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: left;
`;
const OptionLabel = styled("span")`
  padding-right: 10px;
`;
const ReactStrapButtonGroup = styled(ButtonGroup)`
  max-height: 36px;
  padding-left: 25px;
  padding-right: 25px;
`;
const AppliedButtonGroup = styled(ButtonGroup)`
  display: flex;
  flex-direction: column;
`;
const AppliedText = styled("span")`
  font-weight: bold;
  color: white;
  font-size: 13px;
`;
const CharacterBox = styled("div")<{ dimension: number }>`
  height: ${props => props.dimension}cm;
  width: ${props => props.dimension}cm;
  background-color: transparent;
  box-shadow: 0px 0.5px 0px 1.5px white;
`;
export class EditModal extends React.Component<props, state> {
  EditModal: any;
  constructor(props: props) {
    super(props);
    this.state = {
      titleText: props.titleText,
      customInput1Text: props.customInput1Text,
      customInput2Text: props.customInput2Text,
      customizeType: "create"
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      customizeType?: string;
    } = {};

    if (nextProps.customizeType !== prevState.customizeType) {
      update.customizeType = nextProps.customizeType;
    }

    return Object.keys(update).length ? update : null;
  }

  showDownloadOptions = () => {
    return (
      <DownloadDropdown
        isSaveDropdown={false}
        isPremadeDropdown={false}
        isCreateDropdown={true}
        titleText={this.props.titleText}
        isCreatedSave={false}
      />
      // <ReactStrapButtonGroup style={{ width: "260px" }}>
      //   <ReactStrapButton onClick={() => exportPdf(this.props.titleText)}>
      //     PDF
      //   </ReactStrapButton>
      //   <ReactStrapButton onClick={() => exportPng(this.props.titleText)}>
      //     Image
      //   </ReactStrapButton>
      // </ReactStrapButtonGroup>
    );
  };

  showSizeOptions = () => {
    return (
      <div>
        <AppliedButtonGroup>
          {this.props.sizeHasChanged ? (
            <AppliedText>Must be applied</AppliedText>
          ) : null}
          <OptionContainer>
            <ReactStrapButtonGroup>
              <ReactStrapButton
                onClick={() => this.props.togglePreview("size", "a4")}
                active={this.props.changedTotalHeightInCm === 29.7}
              >
                A4
              </ReactStrapButton>
              <ReactStrapButton
                onClick={() => this.props.togglePreview("size", "letter")}
                active={this.props.changedTotalHeightInCm === 27.94}
              >
                Letter
              </ReactStrapButton>
            </ReactStrapButtonGroup>
          </OptionContainer>
        </AppliedButtonGroup>
        {this.props.sizeHasChanged ? (
          <ReactStrapButton onClick={() => this.props.refreshPreview("size")}>
            Apply
          </ReactStrapButton>
        ) : null}
      </div>
    );
  };

  showTitleOptions = () => {
    return (
      <div>
        <OptionContainer>
          <OptionLabel>Title</OptionLabel>
          <Switch
            offColor="#252a31"
            onColor="#6d7b8e"
            onChange={() => {
              this.props.togglePreview("titleToggle");
            }}
            checked={this.props.showTitle}
          />
        </OptionContainer>
        {this.props.showTitle ? (
          <InputGroup>
            <OptionContainer>
              <Input
                type="text"
                placeholder="Title"
                value={this.state.titleText}
                onChange={e => {
                  this.setState({ titleText: e.target.value });
                }}
                style={{
                  border: "1px solid #70c989",
                  borderRadius: 0
                }}
              />
              <InputGroupAddon addonType="append">
                <ReactStrapButton
                  onClick={() =>
                    this.props.togglePreview("titleText", this.state.titleText)
                  }
                >
                  Set
                </ReactStrapButton>
              </InputGroupAddon>
            </OptionContainer>
          </InputGroup>
        ) : null}
        <OptionContainer>
          <OptionLabel>Date Fill-In</OptionLabel>
          <Switch
            offColor="#252a31"
            onColor="#6d7b8e"
            onChange={() => {
              this.props.togglePreview("dateInput");
            }}
            checked={this.props.showDateInput}
          />
        </OptionContainer>
        <OptionContainer>
          <OptionLabel>Name Fill-In</OptionLabel>
          <Switch
            offColor="#252a31"
            onColor="#6d7b8e"
            onChange={() => {
              this.props.togglePreview("nameInput");
            }}
            checked={this.props.showNameInput}
          />
        </OptionContainer>
        <OptionContainer>
          <OptionLabel>Custom Fill-In 1</OptionLabel>
          <Switch
            offColor="#252a31"
            onColor="#6d7b8e"
            onChange={() => {
              this.props.togglePreview("customInput1");
            }}
            checked={this.props.showCustomInput1}
          />
        </OptionContainer>
        {this.props.showCustomInput1 ? (
          <InputGroup>
            <OptionContainer>
              <Input
                type="text"
                placeholder="Custom Input 1"
                value={this.state.customInput1Text}
                onChange={e => {
                  this.setState({ customInput1Text: e.target.value });
                }}
                style={{
                  border: "1px solid #70c989",
                  borderRadius: 0
                }}
              />
              <InputGroupAddon addonType="append">
                <ReactStrapButton
                  onClick={() =>
                    this.props.togglePreview(
                      "customInput1Text",
                      this.state.customInput1Text
                    )
                  }
                >
                  Set
                </ReactStrapButton>
              </InputGroupAddon>
            </OptionContainer>
          </InputGroup>
        ) : null}
        <OptionContainer>
          <OptionLabel>Custom Fill-In 2</OptionLabel>
          <Switch
            offColor="#252a31"
            onColor="#6d7b8e"
            onChange={() => {
              this.props.togglePreview("customInput2");
            }}
            checked={this.props.showCustomInput2}
          />
        </OptionContainer>
        {this.props.showCustomInput2 ? (
          <InputGroup>
            <OptionContainer>
              <Input
                type="text"
                placeholder="Custom Input 2"
                value={this.state.customInput2Text}
                onChange={e => {
                  this.setState({ customInput2Text: e.target.value });
                }}
                style={{
                  border: "1px solid #70c989",
                  borderRadius: 0
                }}
              />
              <InputGroupAddon addonType="append">
                <ReactStrapButton
                  onClick={() =>
                    this.props.togglePreview(
                      "customInput2Text",
                      this.state.customInput2Text
                    )
                  }
                >
                  Set
                </ReactStrapButton>
              </InputGroupAddon>
            </OptionContainer>
          </InputGroup>
        ) : null}
      </div>
    );
  };

  showPuzzleOptions = () => {
    return (
      <div>
        <OptionContainer>
          <OptionLabel>Answer Key</OptionLabel>
          <Switch
            offColor="#252a31"
            onColor="#6d7b8e"
            onChange={() => {
              this.props.togglePreview("answerKey");
            }}
            checked={this.props.showAnswerKey}
          />
        </OptionContainer>
        <ButtonGroup>
          <OptionContainer>
            <OptionLabel>Number Label</OptionLabel>
            <ReactStrapButton
              onClick={() => this.props.togglePreview("numberLabel", false)}
              active={this.props.bigifiedClueNumberLabel === false}
            >
              Small
            </ReactStrapButton>
            <ReactStrapButton
              onClick={() => this.props.togglePreview("numberLabel", true)}
              active={this.props.bigifiedClueNumberLabel === true}
            >
              Big
            </ReactStrapButton>
          </OptionContainer>
        </ButtonGroup>
        <React.Fragment>
          <AppliedButtonGroup>
            {this.props.blockSizeChanged ? (
              <AppliedText>Must be applied</AppliedText>
            ) : null}
            <OptionContainer>
              <OptionLabel>Block Size</OptionLabel>
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview("puzzleBlockHeightInCm", 0.5)
                }
                active={this.props.puzzleBlockHeightInCm === 0.5}
              >
                <CharacterBox dimension={0.5} />
              </ReactStrapButton>
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview("puzzleBlockHeightInCm", 0.6)
                }
                active={this.props.puzzleBlockHeightInCm === 0.6}
              >
                <CharacterBox dimension={0.6} />
              </ReactStrapButton>
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview("puzzleBlockHeightInCm", 0.7)
                }
                active={this.props.puzzleBlockHeightInCm === 0.7}
              >
                <CharacterBox dimension={0.7} />
              </ReactStrapButton>
            </OptionContainer>
          </AppliedButtonGroup>
          <AppliedButtonGroup>
            {this.props.maxXCoordHasChanged ? (
              <AppliedText>Must be applied</AppliedText>
            ) : null}
            <OptionContainer>
              <OptionLabel>Max Horizontal Blocks</OptionLabel>
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview(
                    "maxXCoord",
                    this.props.changedMaxXCoord - 1
                  )
                }
              >
                -
              </ReactStrapButton>
              <input
                disabled
                style={{
                  width: "40px",
                  border: "none",
                  textAlign: "center",
                  backgroundColor: "white",
                  color: "black",
                  height: "36px"
                }}
                value={this.props.changedMaxXCoord + 1}
                onChange={e => {}}
              />
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview(
                    "maxXCoord",
                    this.props.changedMaxXCoord + 1
                  )
                }
              >
                +
              </ReactStrapButton>
            </OptionContainer>
          </AppliedButtonGroup>
          <AppliedButtonGroup>
            {this.props.maxYCoordHasChanged ? (
              <AppliedText>Must be applied</AppliedText>
            ) : null}
            <OptionContainer>
              <OptionLabel>Max Vertical Blocks</OptionLabel>
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview(
                    "maxYCoord",
                    this.props.changedMaxYCoord - 1
                  )
                }
              >
                -
              </ReactStrapButton>
              <input
                disabled
                style={{
                  width: "40px",
                  border: "none",
                  textAlign: "center",
                  backgroundColor: "white",
                  color: "black",
                  height: "36px"
                }}
                value={this.props.changedMaxYCoord + 1}
                onChange={e => {}}
              />
              <ReactStrapButton
                onClick={() =>
                  this.props.togglePreview(
                    "maxYCoord",
                    this.props.changedMaxYCoord + 1
                  )
                }
              >
                +
              </ReactStrapButton>
            </OptionContainer>
          </AppliedButtonGroup>
          {this.props.blockSizeChanged ||
          this.props.maxXCoordHasChanged ||
          this.props.maxYCoordHasChanged ? (
            <ReactStrapButton
              onClick={() => this.props.refreshPreview("puzzle")}
            >
              Apply
            </ReactStrapButton>
          ) : null}
        </React.Fragment>
      </div>
    );
  };

  showClueOptions = () => {
    return (
      <div>
        <OptionContainer>
          <ButtonGroup>
            <OptionLabel>Down Clues Font</OptionLabel>
            <ReactStrapButton
              onClick={() =>
                this.props.togglePreview(
                  "verticalCluesFont",
                  this.props.VerticalClueListFontSizeInPx - 1
                )
              }
            >
              -
            </ReactStrapButton>
            <input
              disabled
              style={{
                width: "40px",
                border: "none",
                textAlign: "center",
                backgroundColor: "white",
                color: "black"
              }}
              value={Math.round(this.props.VerticalClueListFontSizeInPx)}
              onChange={e => {}}
            />
            <ReactStrapButton
              onClick={() =>
                this.props.togglePreview(
                  "verticalCluesFont",
                  this.props.VerticalClueListFontSizeInPx + 1
                )
              }
            >
              +
            </ReactStrapButton>
          </ButtonGroup>
        </OptionContainer>
        <OptionContainer>
          <ButtonGroup>
            <OptionLabel>Across Clues Font</OptionLabel>
            <ReactStrapButton
              onClick={() =>
                this.props.togglePreview(
                  "horizontalCluesFont",
                  this.props.HorizontalClueListFontSizeInPx - 1
                )
              }
            >
              -
            </ReactStrapButton>
            <input
              disabled
              style={{
                width: "40px",
                border: "none",
                textAlign: "center",
                backgroundColor: "white",
                color: "black"
              }}
              value={Math.round(this.props.HorizontalClueListFontSizeInPx)}
              onChange={e => {}}
            />
            <ReactStrapButton
              onClick={() =>
                this.props.togglePreview(
                  "horizontalCluesFont",
                  this.props.HorizontalClueListFontSizeInPx + 1
                )
              }
            >
              +
            </ReactStrapButton>
          </ButtonGroup>
        </OptionContainer>
      </div>
    );
  };

  render(): JSX.Element {
    return (
      <Div totalHeightInCm={this.props.totalHeightInCm}>
        <Group style={{ display: "flex", justifyContent: "center" }}>
          <ReactStrapButton
            style={{ height: "36px", width: "98px" }}
            // @ts-ignore
            onClick={() => this.props.history.push("/CrossWord")}
          >
            Go Back
          </ReactStrapButton>
          <SaveCrosswordButton
            isPremadeButton={false}
            isSaveButton={false}
            isCreateButton={false}
            type={nameOfAllProducts[0]}
          />
        </Group>
        <Group>
          {/* <Title>Download</Title> */}
          {this.showDownloadOptions()}
        </Group>
        <Group>
          <Title>Page Size</Title>
          {this.showSizeOptions()}
        </Group>
        <Group>
          <Title>Title</Title>
          {this.showTitleOptions()}
        </Group>
        <Group>
          <Title>Puzzle</Title>
          {this.showPuzzleOptions()}
        </Group>
        <Group>
          <Title>Clues</Title>
          {this.showClueOptions()}
        </Group>
        {/* <ReactStrapButton
          onClick={() => this.props.refreshPreview("simpleRegen")}
        >
          Regenerate
        </ReactStrapButton> */}
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    customizeType: state.crosswordReducer.customizeType
  };
}

const EditModalContainer = connect(mapStateToProps, {})(EditModal);
// @ts-ignore
export default withRouter(EditModalContainer);
