import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

export interface props {
  unplacedWords: Array<{
    id: number;
    word: string;
    clue: string;
  }>;
}

export interface state {}

const UnplacedWordsModal = styled("div")`
  display: flex;
  margin-left: 10px;
  width: 7cm;
  background-color: #70c989;
  flex-direction: column;
`;
const Title = styled("div")`
  font-weight: bold;
  color: #3a3434;
  font-size: 15px;
  padding: 20px 0 0 0;
  text-align: center;
`;
const List = styled("ul")`
  padding-top: 13px;
  overflow-y: auto;
  height: 100%;
  margin: unset;
`;
export class CreatePreviewModalUnplacedWords extends React.Component<
  props,
  state
> {
  constructor(props: props) {
    super(props);
    this.state = {};
  }

  getListOfUnplacedWords = () => {
    const words = [];
    for (const wordObj of this.props.unplacedWords) {
      words.push(<li>{wordObj.word}</li>);
    }
    return <List>{words}</List>;
  };

  render(): JSX.Element {
    return (
      <UnplacedWordsModal>
        <Title>These words couldn't be placed :(</Title>
        <ol
          style={{
            margin: "unset",
            paddingTop: "0.5rem",
            paddingBottom: "1rem",
            textAlign: "left",
            fontSize: "13px",
            borderBottom: "1px solid #3a3434"
          }}
        >
          <li>There may not be any letter matches</li>
          <li>You could try creating again</li>
        </ol>
        {this.getListOfUnplacedWords()}
      </UnplacedWordsModal>
    );
  }
}

const CreatePreviewModalUnplacedWordsContainer = connect(
  null,
  {}
)(CreatePreviewModalUnplacedWords);

export default CreatePreviewModalUnplacedWordsContainer;
