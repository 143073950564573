import axios from "axios";
import { apiUrl } from "../apiconfig";

export async function getAllSavedProducts(type: string) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/save/getAll/${type}`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function deleteSavedProduct(type: string, id: string) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;

  try {
    result = await axios({
      method: "delete",
      url: `${apiUrl}/save/${type}/${id}`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function getSavedProductById(
  type: string,
  id: string,
  setRawDataToSave: Function,
  setProductIdToCheckIfAlreadySaved: Function
) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/save/getSingle/${type}/${id}`,
      data: {
        email
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  setProductIdToCheckIfAlreadySaved(id);
  setRawDataToSave(type, result.data.rawData);
  // @ts-ignore
  return {
    rawData: result.data.rawData,
    isCreatedSave: result.data.isCreatedSave
  };
}

export async function saveProduct(
  type: string,
  rawData: object,
  id: string,
  premadeProductId: string = null,
  isCreatedSave: boolean,
  setProductIdToCheckIfAlreadySaved: Function
) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );
  const email = window.localStorage.getItem("loggedInUserEmail");

  let result = null;

  try {
    result = await axios({
      method: "put",
      url: `${apiUrl}/save/${type}`,
      data: {
        email,
        id,
        rawData,
        premadeProductId,
        isCreatedSave
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  // set product id of saved ting so that if trying to save again, it'll be an update to the existing save instead of a brand new save boii
  setProductIdToCheckIfAlreadySaved(result.data.id);
  return result.data;
}
