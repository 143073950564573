import * as React from "react";
import styled from "styled-components";
import PasswordForm from "../UserAuth/PasswordForm";
//@ts-ignore
import Loader from "react-loader-spinner";
import { InputGroup, InputGroupAddon, Button, Input, Modal } from "reactstrap";
import { updatePasswordAsLoggedInUser } from "../../API/Auth";
import { toggleToast } from "../../redux";
import { connect } from "react-redux";

export interface props {
  toggleToast: Function;
}
export interface state {
  newPasswordText: string;
  passwordIsValid: boolean;
  currentPasswordText: string;
  confirmPasswordText: string;
  attemptPasswordUpdate: boolean;
  showUpdatePasswordConfirmationModal: boolean;
  emailText: string;
}

const Div = styled("div")`
  margin: 10px 0 10px 0;
  width: 100%;
  background-color: #3c4c55;
  padding: 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
  padding-bottom: 20px;
`;
const Inputs = styled("div")`
  display: flex;
  flex-direction: column;
`;
const Footer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
`;
const ReactStrapButton = styled(Button)`
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 36px !important;
  width: 98px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  min-height: 300px;
  .modal-content {
    padding: 20px;
    min-height: 300px;
    max-width: 365px;
    display: flex;
    border-radius: 0 !important;
    border: none;
    background-color: #3c4c55;
  }
`;
const ModalText = styled("div")`
  color: white;
  margin: 10px 0 10px 0;
`;
const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: 35px auto auto auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const ModalFooter = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
`;
export class Password extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      newPasswordText: "",
      passwordIsValid: false,
      currentPasswordText: "",
      confirmPasswordText: "",
      attemptPasswordUpdate: false,
      showUpdatePasswordConfirmationModal: false,
      emailText: ""
    };
  }

  componentDidMount() {}

  attemptPasswordUpdate = async () => {
    this.setState({
      attemptPasswordUpdate: true
    });

    let result = null;
    try {
      result = await updatePasswordAsLoggedInUser(
        this.state.emailText,
        this.state.currentPasswordText,
        this.state.newPasswordText
      );
    } catch (e) {
      this.props.toggleToast(
        true,
        "Unable to update password, please make sure you entered your current password properly and your email is correct"
      );
      this.setState({
        attemptPasswordUpdate: false
      });
      return;
    }

    if ("updated" in result && result.updated === true) {
      this.props.toggleToast(true, "Password successfully updated. 👍");
      this.setState({
        attemptPasswordUpdate: false,
        newPasswordText: "",
        currentPasswordText: "",
        confirmPasswordText: "",
        showUpdatePasswordConfirmationModal: false
      });
    } else {
      this.props.toggleToast(
        true,
        "Unable to update password, please make sure you entered your current password properly and your email is correct"
      );
      this.setState({
        attemptPasswordUpdate: false
      });
    }
  };

  getUpdatePasswordConfirmationModal = () => {
    return (
      <StyledModal
        isOpen={this.state.showUpdatePasswordConfirmationModal}
        toggle={() =>
          this.setState({
            showUpdatePasswordConfirmationModal: !this.state
              .showUpdatePasswordConfirmationModal
          })
        }
        centered={true}
      >
        <React.Fragment>
          <ModalText>
            Please enter your account email to finish update
          </ModalText>
          <StyledInputGroup>
            <InputGroupAddon className="titleLabel" addonType="append">
              Email
            </InputGroupAddon>
            <Input
              type="email"
              className="loginInputs"
              value={this.state.emailText}
              onChange={e => {
                this.setState({ emailText: e.target.value });
              }}
              style={{
                borderRadius: 0
              }}
            />
          </StyledInputGroup>
          <ModalFooter>
            <ReactStrapButton
              style={{ margin: "5px" }}
              onClick={() =>
                this.setState({
                  showUpdatePasswordConfirmationModal: !this.state
                    .showUpdatePasswordConfirmationModal
                })
              }
            >
              Back
            </ReactStrapButton>
            <ReactStrapButton
              style={{ margin: "5px" }}
              onClick={() => this.attemptPasswordUpdate()}
              disabled={
                this.state.attemptPasswordUpdate ||
                this.state.newPasswordText !== this.state.confirmPasswordText ||
                !this.state.passwordIsValid ||
                this.state.emailText.length === 0
              }
            >
              {this.state.attemptPasswordUpdate ? (
                <Loader type="Rings" color="#fff" height={36} width={36} />
              ) : (
                "Update"
              )}
            </ReactStrapButton>
          </ModalFooter>
        </React.Fragment>
      </StyledModal>
    );
  };

  render(): JSX.Element {
    return (
      <Div>
        {this.getUpdatePasswordConfirmationModal()}
        <Title>Update your password</Title>
        <Inputs>
          <PasswordForm
            showTooltip={false}
            tooltipId=""
            label="Current Password"
            passwordText={this.state.currentPasswordText}
            confirmPassword={false}
            updatePassword={(currentPasswordText: string) => {
              this.setState({ currentPasswordText });
            }}
          />
          <PasswordForm
            showTooltip={true}
            tooltipId="passwordFormTooltip"
            passwordText={this.state.newPasswordText}
            label="New Password"
            confirmPassword={false}
            validate={(passwordIsValid: boolean) => {
              this.setState({ passwordIsValid });
            }}
            updatePassword={(newPasswordText: string) => {
              this.setState({ newPasswordText });
            }}
          />
          <PasswordForm
            showTooltip={true}
            tooltipId="confirmPasswordFormTooltip"
            label="Confirm Password"
            passwordText={this.state.confirmPasswordText}
            confirmPassword={true}
            updatePassword={(confirmPasswordText: string) => {
              this.setState({ confirmPasswordText });
            }}
            matchesPassword={
              this.state.newPasswordText === this.state.confirmPasswordText &&
              this.state.confirmPasswordText.length > 0
            }
          />
        </Inputs>
        <Footer>
          <ReactStrapButton
            onClick={() =>
              this.setState({
                showUpdatePasswordConfirmationModal: !this.state
                  .showUpdatePasswordConfirmationModal
              })
            }
            disabled={
              this.state.newPasswordText !== this.state.confirmPasswordText ||
              !this.state.passwordIsValid
            }
          >
            Update
          </ReactStrapButton>
        </Footer>
      </Div>
    );
  }
}

const PasswordContainer = connect(null, { toggleToast })(Password);

export default PasswordContainer;
