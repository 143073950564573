import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

export interface props {}

export interface state {}

const Div = styled("div")`
  margin: 20px 20px 20px 20px;
  flex: 1;
  background-color: #3c4c55;
  padding: 20px 20px 20px 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
`;
const Header = styled("div")`
  padding-top: 20px;
  color: white;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
`;
const Description = styled("div")`
  padding-top: 10px;
  width: 100%;
  text-align: left;
  color: white;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1;
`;
export class AboutUs extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    return (
      <Div>
        <Title>About Us</Title>
        <Description>
          WordyMaker's goal is to provide the best possible tools to allow the
          creation of engaging content. We currently offer a crossword creation
          tool, and will soon be adding word search and bingo creation tools
          among others.
        </Description>
        <Description>
          Please feel free to contact us at wordymaker@gmail.com if you have any
          suggestions or need any help.
        </Description>
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const AboutUsContainer = connect(mapStateToProps, {})(AboutUs);

export default AboutUsContainer;
