import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import isEqual from "lodash.isequal";
import {
  setCrosswordWordAndTitleData,
  toggleUserAuthModal,
  toggleAuthToShow,
  toggleCrosswordCustomizeType,
  toggleGenerate,
  toggleCrosswordCreatePreviewModal,
  updateCreateYourOwnCrosswordFormData,
  updateCreateYourOwnCrosswordTitleTextData,
  toggleToast,
  setProductIdToCheckIfAlreadySaved,
  setOpenCreatePreviewModalProductType
} from "../../../redux";
import { connect } from "react-redux";
import { nameOfAllProducts } from "../../Helpers/Variables";

export interface createYourOwnCrosswordFormData {
  [key: number]: {
    word?: string;
    clue?: string;
  };
}
export interface props {
  createYourOwnCrosswordFormData?: {
    [key: number]: {
      word?: string;
      clue?: string;
    };
  };
  toggleCrosswordCustomizeType?: Function;
  setCrosswordWordAndTitleData: Function;
  isLoggedIn: boolean;
  toggleUserAuthModal: Function;
  toggleAuthToShow: Function;
  isPayingUser: boolean;
  toggleGenerate?: Function;
  createYourOwnCrosswordTitleTextData?: string;
  toggleCrosswordCreatePreviewModal: Function;
  updateCreateYourOwnCrosswordFormData: Function;
  updateCreateYourOwnCrosswordTitleTextData: Function;
  toggleToast: Function;
  setProductIdToCheckIfAlreadySaved: Function;
  setOpenCreatePreviewModalProductType: Function;
}

export interface state {
  createYourOwnCrosswordFormData: {
    [key: number]: {
      word?: string;
      clue?: string;
    };
  };
  forms: Array<any>;
  createYourOwnCrosswordTitleTextData: string;
  isLoggedIn: boolean;
  isPayingUser: boolean;
  disIsForNoReasonExceptToMakeFormWorkWithRedux: string;
}

const Div = styled("div")`
  height: 50vh;
  min-height: 455px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #3c4c55;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const Title = styled("div")`
  color: #ffffff;
  font-size: 25px;
`;
const ClueForms = styled("div")`
  width: 80%;
  height: 100%
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  //   background-color: none;
  border: 1px solid #70c989;
`;
const SingleForm = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  .formInput {
    background-color: #252a31;
    width: 35px;
    display: flex;
    justify-content: center;
  }
`;
const NumberLabel = styled("div")`
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
`;
const WordInput = styled(Input)`
  border-radius: 0 !important;
  border: none !important;
  height: 26px !important;
`;
const ClueInput = styled(Input)`
  border-radius: 0 !important;
  border: none !important;
  height: 26px !important;
  margin-left: 5px !important;
`;
const ReactStrapButton = styled(Button)`
  margin-top: 20px;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const CreateButton = styled(Button)`
  font-weight: 600 !important;
  color: #3c4c55 !important;
  margin-top: 20px;
  background-color: #70c989 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #63ad78 !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #63ad78 !important;
  }
`;
const StyledInputGroup = styled(InputGroup)`
  padding-top: 15px;
  padding-bottom: 10px;
  width: unset !important;
  .titleInput {
    height: 26px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 26px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const ClearButton = styled(Button)`
  height: 26px !important;
  border: unset !important;
  border-radius: unset !important;
  background-color: #252a31 !important;
  color: white !important;
  margin-left: 5px !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
`;

export class CrosswordForm extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      createYourOwnCrosswordFormData: {},
      forms: [],
      createYourOwnCrosswordTitleTextData: "",
      isLoggedIn: false,
      isPayingUser: false,
      disIsForNoReasonExceptToMakeFormWorkWithRedux: ""
    };
  }

  componentDidMount() {
    this.createForms(80);
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isLoggedIn?: boolean;
      isPayingUser?: boolean;
      createYourOwnCrosswordFormData?: {
        [key: number]: {
          word?: string;
          clue?: string;
        };
      };
      createYourOwnCrosswordTitleTextData?: string;
    } = {};

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }
    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }
    if (
      !isEqual(
        nextProps.createYourOwnCrosswordFormData,
        prevState.createYourOwnCrosswordFormData
      )
    ) {
      update.createYourOwnCrosswordFormData =
        nextProps.createYourOwnCrosswordFormData;
    }
    if (
      nextProps.createYourOwnCrosswordTitleTextData !==
      prevState.createYourOwnCrosswordTitleTextData
    ) {
      update.createYourOwnCrosswordTitleTextData =
        nextProps.createYourOwnCrosswordTitleTextData;
    }

    return Object.keys(update).length ? update : null;
  }

  updateWordsToPlace = (number: number, type: string, value: string) => {
    const { createYourOwnCrosswordFormData } = this.state;
    if (!(number in createYourOwnCrosswordFormData)) {
      createYourOwnCrosswordFormData[number] = {};
    }

    if (!(type in createYourOwnCrosswordFormData[number])) {
      //@ts-ignore
      createYourOwnCrosswordFormData[number][type] = {};
    }

    //@ts-ignore
    createYourOwnCrosswordFormData[number][type] = value;

    const { word, clue } = createYourOwnCrosswordFormData[number];
    if (!value.replace(/\s/g, "").length) {
      delete createYourOwnCrosswordFormData[number];
    }

    if (value.length > 45 && type === "word") {
      delete createYourOwnCrosswordFormData[number];
    }

    if (value.length > 80 && type === "clue") {
      delete createYourOwnCrosswordFormData[number];
    }

    if (word !== undefined && clue !== undefined) {
      this.setState(
        {
          disIsForNoReasonExceptToMakeFormWorkWithRedux: ""
        },
        () =>
          this.props.updateCreateYourOwnCrosswordFormData(
            createYourOwnCrosswordFormData
          )
      );
    }
  };

  clearSingleForm = (number: number) => {
    const { createYourOwnCrosswordFormData } = this.state;
    delete createYourOwnCrosswordFormData[number];
    this.setState(
      {
        disIsForNoReasonExceptToMakeFormWorkWithRedux: ""
      },
      () =>
        this.props.updateCreateYourOwnCrosswordFormData(
          createYourOwnCrosswordFormData
        )
    );
    //@ts-ignore
    document.getElementById(`word.${number}`).value = "";
    //@ts-ignore
    document.getElementById(`clue.${number}`).value = "";
  };

  createForms = (amount: number) => {
    const forms = [];
    for (let i = 1; i <= amount; i += 1) {
      forms.push(
        <SingleForm key={Math.random()}>
          <InputGroup key={Math.random()}>
            <InputGroupAddon
              className="formInput"
              addonType="prepend"
              key={Math.random()}
            >
              <NumberLabel key={Math.random()}>{i}.</NumberLabel>
            </InputGroupAddon>
            <WordInput
              id={`word.${i}`}
              key={Math.random()}
              placeholder="word"
              defaultValue={
                this.state.createYourOwnCrosswordFormData[i] !== undefined &&
                this.state.createYourOwnCrosswordFormData[i].word !== undefined
                  ? this.state.createYourOwnCrosswordFormData[i].word
                  : ""
              }
              onChange={(e: any) => {
                this.updateWordsToPlace(i, "word", e.target.value);
              }}
              onKeyPress={(e: any) => {
                if (e.target.value.length > 44) {
                  e.preventDefault();
                  this.props.toggleToast(
                    true,
                    "Sorry, words longer than 45 letters aren't allowed"
                  );
                }
                if (e.charCode === 32 || e.which === 32 || e.keyCode === 32) {
                  e.preventDefault();
                }
              }}
            />
            <ClueInput
              id={`clue.${i}`}
              key={Math.random()}
              placeholder="clue"
              defaultValue={
                this.state.createYourOwnCrosswordFormData[i] !== undefined &&
                this.state.createYourOwnCrosswordFormData[i].clue !== undefined
                  ? this.state.createYourOwnCrosswordFormData[i].clue
                  : ""
              }
              onChange={(e: any) =>
                this.updateWordsToPlace(i, "clue", e.target.value)
              }
              onKeyPress={(e: any) => {
                if (e.target.value.length > 79) {
                  e.preventDefault();
                  this.props.toggleToast(
                    true,
                    "Sorry, clue longer than 80 characters aren't allowed"
                  );
                }
              }}
            />
            <ClearButton onClick={() => this.clearSingleForm(i)}>X</ClearButton>
          </InputGroup>
        </SingleForm>
      );
    }
    this.setState({ forms });
  };

  handleCreation = (toggleCrosswordCreatePreviewModal: boolean) => {
    const words = [];

    const { createYourOwnCrosswordFormData } = this.state;
    const wordNumbers = Object.keys(createYourOwnCrosswordFormData);
    for (const number of wordNumbers) {
      //@ts-ignore
      const { word, clue } = createYourOwnCrosswordFormData[number];
      if (
        word !== undefined &&
        word !== null &&
        clue !== undefined &&
        clue !== null
      ) {
        words.push({
          id: number,
          word,
          clue
        });
      }
    }

    if (words.length > 0) {
      this.props.setCrosswordWordAndTitleData(
        words,
        this.state.createYourOwnCrosswordTitleTextData
      );
      this.checkInvalidForms();
    }
    if (toggleCrosswordCreatePreviewModal) {
      this.props.toggleCrosswordCreatePreviewModal(true);
      this.props.setOpenCreatePreviewModalProductType(nameOfAllProducts[0]);
    }
  };

  checkInvalidForms = () => {
    const { createYourOwnCrosswordFormData } = this.state;

    let invalidForm = false;
    for (const key in createYourOwnCrosswordFormData) {
      if (
        !("word" in createYourOwnCrosswordFormData[key]) ||
        !("clue" in createYourOwnCrosswordFormData[key])
      ) {
        invalidForm = true;
      }
    }

    if (invalidForm) {
      this.props.toggleToast(
        true,
        "Looks like you have at least one word/clue pair missing either the word or clue, these pairs will not show on the puzzle"
      );
    }
  };

  clearAllForms = () => {
    const { createYourOwnCrosswordFormData } = this.state;

    const ids = Object.keys(createYourOwnCrosswordFormData);
    if (ids.length > 0) {
      this.setState(
        {
          disIsForNoReasonExceptToMakeFormWorkWithRedux: ""
        },
        () => this.props.updateCreateYourOwnCrosswordFormData({})
      );
      for (const id of ids) {
        //@ts-ignore
        document.getElementById(`word.${id}`).value = "";
        //@ts-ignore
        document.getElementById(`clue.${id}`).value = "";
      }
    }
  };

  handleNotLoggedIn = () => {
    this.props.toggleToast(
      true,
      "Please sign up or sign in to create your own crossword"
    );
    this.props.toggleUserAuthModal(true);
    this.props.toggleAuthToShow("register");
  };

  handleCreateButton = () => {
    this.props.toggleGenerate(true);

    // if (!this.props.isLoggedIn) {
    //   this.handleNotLoggedIn();
    // }
    // if (this.props.isLoggedIn && !this.props.isPayingUser) {
    if (!this.props.isPayingUser) {
      this.handleCreation(true);
    }
    // if (this.props.isLoggedIn && this.props.isPayingUser) {
    if (this.props.isPayingUser) {
      this.props.setProductIdToCheckIfAlreadySaved("");
      this.props.toggleCrosswordCustomizeType("create");
      this.handleCreation(false);
      // @ts-ignore
      this.props.history.push("/CrossWordFinalPage");
    }
  };

  render(): JSX.Element {
    return (
      <Div>
        <Title>
          Create Your Own{" "}
          <span id="UncontrolledTooltipExample">
            <FontAwesomeIcon color="#70c989" icon={faQuestionCircle} />
          </span>
          <UncontrolledTooltip
            placement="right"
            target="UncontrolledTooltipExample"
          >
            <ul style={{ textAlign: "left" }}>
              <li>
                The same upper and lower case letters are considered the same
              </li>
              <li>Words can be up to 45 letters long</li>
              <li>Words can't have spaces</li>
              <li>Clues can be up to 80 characters long</li>
              <li>Clues can't be empty</li>
            </ul>
          </UncontrolledTooltip>
        </Title>
        <StyledInputGroup>
          <InputGroupAddon className="titleLabel" addonType="append">
            Title
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Title"
            className="titleInput"
            value={this.state.createYourOwnCrosswordTitleTextData}
            onChange={e => {
              this.props.updateCreateYourOwnCrosswordTitleTextData(
                e.target.value
              );
            }}
            style={{
              borderRadius: 0
            }}
          />
        </StyledInputGroup>
        <ClearButton
          onClick={() => this.clearAllForms()}
          style={{
            alignSelf: "flex-end",
            marginRight: "76px"
          }}
        >
          Clear All
        </ClearButton>
        <ClueForms>{this.state.forms}</ClueForms>
        <CreateButton
          id="createButton"
          disabled={
            Object.keys(this.state.createYourOwnCrosswordFormData).length === 0
          }
          onClick={this.handleCreateButton}
        >
          Create
        </CreateButton>
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    createYourOwnCrosswordTitleTextData:
      state.crosswordReducer.createYourOwnCrosswordTitleTextData,
    createYourOwnCrosswordFormData:
      state.crosswordReducer.createYourOwnCrosswordFormData,
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const CrosswordFormConnected = connect(mapStateToProps, {
  setCrosswordWordAndTitleData,
  toggleUserAuthModal,
  toggleAuthToShow,
  toggleCrosswordCustomizeType,
  toggleGenerate,
  toggleCrosswordCreatePreviewModal,
  updateCreateYourOwnCrosswordFormData,
  updateCreateYourOwnCrosswordTitleTextData,
  toggleToast,
  setProductIdToCheckIfAlreadySaved,
  setOpenCreatePreviewModalProductType
})(CrosswordForm);

export default withRouter(CrosswordFormConnected);
