import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

export interface props {}

export interface state {}

const Div = styled("div")`
  margin: 20px 20px 20px 20px;
  flex: 1;
  background-color: #3c4c55;
  padding: 20px 20px 20px 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
`;
const Header = styled("div")`
  padding-top: 20px;
  color: white;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
`;
const Description = styled("div")`
  width: 100%;
  text-align: left;
  color: white;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1.5;
`;
export class PrivacyPolicy extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    return (
      <Div>
        <Title>Privacy Policy</Title>
        <Header>Your privacy is critically important to us.</Header>
        <Header>Overview</Header>
        <Description>
          It is WordyMaker's policy to respect your privacy regarding any
          information we may collect while operating our website. This Privacy
          Policy applies to wordymaker.com (hereinafter, "us", "we", or
          "wordymaker.com"). We respect your privacy and are committed to
          protecting personally identifiable information you may provide us
          through the Website. We have adopted this privacy policy ("Privacy
          Policy") to explain what information may be collected on our Website,
          how we use this information, and under what circumstances we may
          disclose the information to third parties. This Privacy Policy applies
          only to information we collect through the Website and does not apply
          to our collection of information from other sources. This Privacy
          Policy, together with the Terms and conditions posted on our Website,
          set forth the general rules and policies governing your use of our
          Website. Depending on your activities when visiting our Website, you
          may be required to agree to additional terms and conditions.
        </Description>
        <Header>Website Visitors</Header>
        <Description>
          Like most website operators, WordyMaker collects
          non-personally-identifying information of the sort that web browsers
          and servers typically make available, such as the browser type,
          language preference, referring site, and the date and time of each
          visitor request. WordyMaker's purpose in collecting non-personally
          identifying information is to better understand how WordyMaker's
          visitors use its website.
        </Description>
        <Header>Gathering of Personally-Identifying Information</Header>
        <Description>
          Certain visitors to WordyMaker's websites choose to interact with
          WordyMaker in ways that require WordyMaker to gather
          personally-identifying information. The amount and type of information
          that WordyMaker gathers depends on the nature of the interaction. For
          example, we ask visitors who sign up for https://wordymaker.com to
          provide an email address.
        </Description>
        <Header>Security</Header>
        <Description>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially acceptable means to protect your Personal Information, we
          cannot guarantee its absolute security.
        </Description>
        <Header>Advertisements</Header>
        <Description>
          Ads appearing on our website may be delivered to users by advertising
          partners, who may set cookies. These cookies allow the ad server to
          recognize your computer each time they send you an online
          advertisement to compile information about you or others who use your
          computer. This information allows ad networks to, among other things,
          deliver targeted advertisements that they believe will be of most
          interest to you. This Privacy Policy covers the use of cookies by
          WordyMaker and does not cover the use of cookies by any advertisers.
        </Description>
        <Header>wordymaker.com uses Google AdWords for remarketing</Header>
        <Description>
          wordymaker.com uses the remarketing services to advertise on third
          party websites (including Google) to previous visitors to our site.
          This could be in the form of an advertisement on the Google search
          results page, or a site in the Google Display Network. Third-party
          vendors, including Google, use cookies to serve ads based on someone's
          past visits. Of course, any data collected will be used in accordance
          with our own privacy policy and Google's privacy policy.
        </Description>
        <Header>
          Protection of Certain Personally-Identifying Information
        </Header>
        <Description>
          WordyMaker discloses potentially personally-identifying and
          personally-identifying information only to those of its employees,
          contractors and affiliated organizations that (i) need to know that
          information in order to process it on WordyMaker's behalf or to
          provide services available at WordyMaker's website, and (ii) that have
          agreed not to disclose it to others. Some of those employees,
          contractors and affiliated organizations may be located outside of
          your home country; by using WordyMaker's website, you consent to the
          transfer of such information to them. WordyMaker will not rent or sell
          potentially personally-identifying and personally-identifying
          information to anyone. Other than to its employees, contractors and
          affiliated organizations, as described above, WordyMaker discloses
          potentially personally-identifying and personally-identifying
          information only in response to a subpoena, court order or other
          governmental request, or when WordyMaker believes in good faith that
          disclosure is reasonably necessary to protect the property or rights
          of WordyMaker, third parties or the public at large. If you are a
          registered user of https://wordymaker.com and have supplied your email
          address, WordyMaker may occasionally send you an email to tell you
          about new features, solicit your feedback, or just keep you up to date
          with what's going on with WordyMaker and our products. If you send us
          a request (for example via a support email or via one of our feedback
          mechanisms), we reserve the right to publish it in order to help us
          clarify or respond to your request or to help us support other users.
          WordyMaker takes all measures reasonably necessary to protect against
          the unauthorized access, use, alteration or destruction of potentially
          personally-identifying and personally-identifying information.
        </Description>
        <Header>Aggregated Statistics</Header>
        <Description>
          WordyMaker may collect statistics about the behavior of visitors to
          its website. WordyMaker may display this information publicly or
          provide it to others. However, WordyMaker does not disclose your
          personally-identifying information.
        </Description>
        <Header>Cookies</Header>
        <Description>
          To enrich and perfect your online experience, WordyMaker uses
          "Cookies", similar technologies and services provided by others to
          display personalized content, appropriate advertising and store your
          preferences on your computer. A cookie is a string of information that
          a website stores on a visitor's computer, and that the visitor's
          browser provides to the website each time the visitor returns.
          WordyMaker uses cookies to help WordyMaker identify and track
          visitors, their usage of https://wordymaker.com, and their website
          access preferences. WordyMaker visitors who do not wish to have
          cookies placed on their computers should set their browsers to refuse
          cookies before using WordyMaker's websites, with the drawback that
          certain features of WordyMaker's websites may not function properly
          without the aid of cookies. By continuing to navigate our website
          without changing your cookie settings, you hereby acknowledge and
          agree to WordyMaker's use of cookies.
        </Description>
        <Header>E-commerce</Header>
        <Description>
          Those who engage in transactions with WordyMaker – by purchasing
          WordyMaker's services or products, are asked to provide additional
          information, including as necessary the personal and financial
          information required to process those transactions. In each case,
          WordyMaker collects such information only insofar as is necessary or
          appropriate to fulfill the purpose of the visitor's interaction with
          WordyMaker. WordyMaker does not disclose personally-identifying
          information other than as described below. And visitors can always
          refuse to supply personally-identifying information, with the caveat
          that it may prevent them from engaging in certain website-related
          activities.
        </Description>
        <Header>Privacy Policy Changes</Header>
        <Description>
          Although most changes are likely to be minor, WordyMaker may change
          its Privacy Policy from time to time, and in WordyMaker's sole
          discretion. WordyMaker encourages visitors to frequently check this
          page for any changes to its Privacy Policy. Your continued use of this
          site after any change in this Privacy Policy will constitute your
          acceptance of such change.
        </Description>
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const PrivacyPolicyContainer = connect(mapStateToProps, {})(PrivacyPolicy);

export default PrivacyPolicyContainer;
