import { combineReducers, createStore } from "redux";
import {
  nameOfAllProducts,
  paymentPlansInterface
} from "./components/Helpers/Variables";

// actions.js
export const setCrosswordWordAndTitleData = (
  words: Array<any> = [],
  titleText: string = ""
) => ({
  type: "setCrosswordWordAndTitleData",
  payload: {
    words,
    titleText
  }
});
export const setCrosswordTitleText = (titleText: string) => ({
  type: "setCrosswordTitleText",
  payload: {
    titleText
  }
});
export const toggleGenerate = (generate: boolean) => ({
  type: "toggleGenerate",
  payload: {
    generate
  }
});
export const toggleUserAuthModal = (showUserAuthModal: boolean) => ({
  type: "toggleUserAuthModal",
  payload: {
    showUserAuthModal
  }
});
export const toggleAuthToShow = (authToShow: string) => ({
  type: "toggleAuthToShow",
  payload: {
    authToShow
  }
});
export const toggleIsLoggedIn = (isLoggedIn: boolean) => ({
  type: "toggleIsLoggedIn",
  payload: {
    isLoggedIn
  }
});

export const toggleCrosswordCustomizeType = (customizeType: string) => ({
  type: "toggleCrosswordCustomizeType",
  payload: {
    customizeType
  }
});

export const toggleCrosswordPremadePreviewModal = (
  showCrosswordPremadePreviewModal: boolean
) => ({
  type: "toggleCrosswordPremadePreviewModal",
  payload: {
    showCrosswordPremadePreviewModal
  }
});

export const toggleCrosswordCreatePreviewModal = (
  showCrosswordCreatePreviewModal: boolean
) => ({
  type: "toggleCrosswordCreatePreviewModal",
  payload: {
    showCrosswordCreatePreviewModal
  }
});

export const updateCreateYourOwnCrosswordFormData = (createYourOwnCrosswordFormData: {
  [key: number]: {
    word?: string;
    clue?: string;
  };
}) => ({
  type: "updateCreateYourOwnCrosswordFormData",
  payload: {
    createYourOwnCrosswordFormData
  }
});

export const updateCreateYourOwnCrosswordTitleTextData = (
  createYourOwnCrosswordTitleTextData: string
) => ({
  type: "updateCreateYourOwnCrosswordTitleTextData",
  payload: {
    createYourOwnCrosswordTitleTextData
  }
});

export const setPremadeCrosswordId = (premadeCrosswordId: number) => ({
  type: "setPremadeCrosswordId",
  payload: {
    premadeCrosswordId
  }
});

export const toggleToast = (showToast: boolean, toastText: string = "") => ({
  type: "toggleToast",
  payload: {
    showToast,
    toastText
  }
});

export const toggleCrosswordSavedPreviewModal = (
  showCrosswordSavedPreviewModal: boolean
) => ({
  type: "toggleCrosswordSavedPreviewModal",
  payload: {
    showCrosswordSavedPreviewModal
  }
});

export const setSavedCrosswordId = (savedCrosswordId: number) => ({
  type: "setSavedCrosswordId",
  payload: {
    savedCrosswordId
  }
});

// reducers.js
export const crosswordReducer = (
  state: {
    words: Array<any>;
    titleText: string;
    generate: boolean;
    customizeType: string;
    showCrosswordPremadePreviewModal: boolean;
    showCrosswordCreatePreviewModal: boolean;
    createYourOwnCrosswordTitleTextData: string;
    createYourOwnCrosswordFormData: {
      [key: number]: {
        word?: string;
        clue?: string;
      };
    };
    premadeCrosswordId: string;
    showCrosswordSavedPreviewModal: boolean;
    savedCrosswordId: string;
  } = {
    words: [],
    titleText: "",
    generate: false,
    customizeType: "",
    showCrosswordPremadePreviewModal: false,
    showCrosswordCreatePreviewModal: false,
    createYourOwnCrosswordTitleTextData: "",
    createYourOwnCrosswordFormData: {},
    premadeCrosswordId: "",
    showCrosswordSavedPreviewModal: false,
    savedCrosswordId: ""
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "setCrosswordWordAndTitleData":
      state.words = action.payload.words;
      state.titleText = action.payload.titleText;
      return { ...state, ...state };
    case "toggleGenerate":
      state.generate = action.payload.generate;
      return { ...state, ...state };
    case "toggleCrosswordCustomizeType":
      state.customizeType = action.payload.customizeType;
      return { ...state, ...state };
    case "toggleCrosswordPremadePreviewModal":
      state.showCrosswordPremadePreviewModal =
        action.payload.showCrosswordPremadePreviewModal;
      return { ...state, ...state };
    case "toggleCrosswordCreatePreviewModal":
      state.showCrosswordCreatePreviewModal =
        action.payload.showCrosswordCreatePreviewModal;
      return { ...state, ...state };
    case "updateCreateYourOwnCrosswordFormData":
      state.createYourOwnCrosswordFormData =
        action.payload.createYourOwnCrosswordFormData;
      return { ...state, ...state };
    case "updateCreateYourOwnCrosswordTitleTextData":
      state.createYourOwnCrosswordTitleTextData =
        action.payload.createYourOwnCrosswordTitleTextData;
      return { ...state, ...state };
    case "setPremadeCrosswordId":
      state.premadeCrosswordId = action.payload.premadeCrosswordId;
      return { ...state, ...state };
    case "toggleCrosswordSavedPreviewModal":
      state.showCrosswordSavedPreviewModal =
        action.payload.showCrosswordSavedPreviewModal;
      return { ...state, ...state };
    case "setSavedCrosswordId":
      state.savedCrosswordId = action.payload.savedCrosswordId;
      return { ...state, ...state };
    case "setCrosswordTitleText":
      state.titleText = action.payload.titleText;
      return { ...state, ...state };
    default:
      return state;
  }
};

export const userAuthReducer = (
  state: {
    showUserAuthModal: boolean;
    authToShow: string;
    isLoggedIn: boolean;
  } = {
    showUserAuthModal: false,
    authToShow: "login",
    isLoggedIn: false
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "toggleUserAuthModal":
      state.showUserAuthModal = action.payload.showUserAuthModal;
      state.authToShow = action.payload.authToShow;
      return { ...state, ...state };
    case "toggleAuthToShow":
      state.authToShow = action.payload.authToShow;
      return { ...state, ...state };
    case "toggleIsLoggedIn":
      state.isLoggedIn = action.payload.isLoggedIn;
      return { ...state, ...state };
    default:
      return state;
  }
};

export const paymentReducer = (
  state: {
    isPayingUser: boolean;
    showPaymentPlansModal: boolean;
    paymentPlansModalType: string;
    showPaymentFormModal: boolean;
    paymentPlans: Array<paymentPlansInterface>;
    paymentPlanId: string;
    showPurchaseButtonsInModal: boolean;
    productType: string;
    showExpiredPlanModal: boolean;
  } = {
    isPayingUser: false,
    showPaymentPlansModal: false,
    paymentPlansModalType: "",
    showPaymentFormModal: false,
    paymentPlans: [],
    paymentPlanId: "0",
    showPurchaseButtonsInModal: true,
    productType: "",
    showExpiredPlanModal: false
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "toggleIsPayingUser":
      state.isPayingUser = action.payload.isPayingUser;
      return { ...state, ...state };
    case "togglePaymentPlansModal":
      state.showPaymentPlansModal = action.payload.showPaymentPlansModal;
      if (action.payload.paymentPlansModalType !== null) {
        state.paymentPlansModalType = action.payload.paymentPlansModalType;
      }
      state.showPurchaseButtonsInModal =
        action.payload.showPurchaseButtonsInModal;
      return { ...state, ...state };
    case "togglePaymentFormModal":
      state.showPaymentFormModal = action.payload.showPaymentFormModal;
      state.paymentPlanId = action.payload.paymentPlanId;
      return { ...state, ...state };
    case "setPaymentPlans":
      state.paymentPlans = action.payload.paymentPlans;
      return { ...state, ...state };
    case "setOpenCreatePreviewModalProductType":
      state.productType = action.payload.productType;
      return { ...state, ...state };
    case "toggleExpiredPlanModal":
      state.showExpiredPlanModal = action.payload.showExpiredPlanModal;
      return { ...state, ...state };
    default:
      return state;
  }
};

export const toggleIsPayingUser = (isPayingUser: boolean) => ({
  type: "toggleIsPayingUser",
  payload: {
    isPayingUser
  }
});

export const setPaymentPlans = (paymentPlans: paymentPlansInterface) => ({
  type: "setPaymentPlans",
  payload: {
    paymentPlans
  }
});

export const setOpenCreatePreviewModalProductType = (productType: string) => ({
  type: "setOpenCreatePreviewModalProductType",
  payload: {
    productType
  }
});

export const toggleExpiredPlanModal = (showExpiredPlanModal: boolean) => ({
  type: "toggleExpiredPlanModal",
  payload: {
    showExpiredPlanModal
  }
});

export const toastReducer = (
  state: {
    showToast: boolean;
    toastText: string;
  } = {
    showToast: false,
    toastText: ""
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "toggleToast":
      state.showToast = action.payload.showToast;
      state.toastText = action.payload.toastText;
      return { ...state, ...state };
    default:
      return state;
  }
};

export const togglePaymentPlansModal = (
  showPaymentPlansModal: boolean,
  paymentPlansModalType: string = null,
  showPurchaseButtonsInModal: boolean = true
) => ({
  type: "togglePaymentPlansModal",
  payload: {
    showPaymentPlansModal,
    paymentPlansModalType,
    showPurchaseButtonsInModal
  }
});

export const togglePaymentFormModal = (
  showPaymentFormModal: boolean,
  paymentPlanId: string = "0"
) => ({
  type: "togglePaymentFormModal",
  payload: {
    showPaymentFormModal,
    paymentPlanId
  }
});

export const universalProductTingsReducer = (
  state: {
    showSavedProductDeletionConfirmationModal: boolean;
  } = {
    showSavedProductDeletionConfirmationModal: false
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "toggleSavedProductDeletionConfirmationModal":
      state.showSavedProductDeletionConfirmationModal =
        action.payload.showSavedProductDeletionConfirmationModal;
      return { ...state, ...state };
    default:
      return state;
  }
};

export const toggleSavedProductDeletionConfirmationModal = (
  showSavedProductDeletionConfirmationModal: number
) => ({
  type: "toggleSavedProductDeletionConfirmationModal",
  payload: {
    showSavedProductDeletionConfirmationModal
  }
});

export const saveProductReducer = (
  state: {
    productIdToCheckIfAlreadySaved: string;
    crosswordRawDataToSave: any;
  } = {
    productIdToCheckIfAlreadySaved: "",
    crosswordRawDataToSave: {}
  },
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "setProductIdToCheckIfAlreadySaved":
      state.productIdToCheckIfAlreadySaved =
        action.payload.productIdToCheckIfAlreadySaved;
      return { ...state, ...state };
    case "setCrosswordRawDataToSave":
      state.crosswordRawDataToSave = action.payload.crosswordRawDataToSave;
      return { ...state, ...state };
    default:
      return state;
  }
};

export const setProductIdToCheckIfAlreadySaved = (
  productIdToCheckIfAlreadySaved: string
) => ({
  type: "setProductIdToCheckIfAlreadySaved",
  payload: {
    productIdToCheckIfAlreadySaved
  }
});

export const setRawDataToSave = (type: string, rawDataToSave: any) => {
  if (type === nameOfAllProducts[0]) {
    return {
      type: "setCrosswordRawDataToSave",
      payload: {
        crosswordRawDataToSave: rawDataToSave
      }
    };
  }
};

export const reducers = combineReducers({
  crosswordReducer,
  userAuthReducer,
  paymentReducer,
  toastReducer,
  universalProductTingsReducer,
  saveProductReducer
});

// store.js
export function configureStore(initialState = {}) {
  const store = createStore(reducers, initialState);
  return store;
}

export const store = configureStore();
