import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

export async function exportPdf(titleText: string, showAnswerKey?: boolean) {
  const input = document.getElementById("crosswordEditPage");
  const input2 = document.getElementById("cluesSecondPage");

  if (showAnswerKey) {
    const characterElements = document.getElementsByClassName(
      "crosswordCharacter"
    );
    //@ts-ignore
    for (const element of characterElements) {
      element.style.display = "contents";
    }

    const clueElements = document.getElementsByClassName("clueAnswer");
    //@ts-ignore
    for (const element of clueElements) {
      element.style.display = "contents";
    }
  }

  // scale determines quality (4 = 400%)
  const scale = 3;
  let imgData = null;
  try {
    imgData = await domtoimage.toPng(input, {
      height: input.offsetHeight * scale,
      width: input.offsetWidth * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        height: input.offsetHeight + "px",
        width: input.offsetWidth + "px"
      }
    });
  } catch (e) {
    console.log(e);
  }

  const pdf = new jsPDF("p", "mm", "a4");
  pdf.addImage(
    imgData,
    "PNG",
    0,
    0,
    input.offsetWidth * 0.264583, // pixel to mm conversion
    input.offsetHeight * 0.264583, // pixel to mm conversion
    "",
    "FAST"
  );

  if (input2 !== null && input2 !== undefined) {
    pdf.addPage();
    let imgData2 = null;
    try {
      imgData2 = await domtoimage.toPng(input2, {
        height: input2.offsetHeight * scale,
        width: input2.offsetWidth * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          height: input2.offsetHeight + "px",
          width: input2.offsetWidth + "px"
        }
      });
    } catch (e) {
      console.log(e);
    }
    pdf.addImage(
      imgData2,
      "PNG",
      0,
      0,
      input2.offsetWidth * 0.264583, // pixel to mm conversion
      input2.offsetHeight * 0.264583, // pixel to mm conversion
      "",
      "FAST"
    );
  }

  if (showAnswerKey) {
    const characterElements = document.getElementsByClassName(
      "crosswordCharacter"
    );
    //@ts-ignore
    for (const element of characterElements) {
      element.style.display = "none";
    }

    const clueElements = document.getElementsByClassName("clueAnswer");
    //@ts-ignore
    for (const element of clueElements) {
      element.style.display = "none";
    }
  }

  pdf.save(`${titleText}_crossword.pdf`);
}

export async function exportPng(titleText: string, showAnswerKey?: boolean) {
  const input = document.getElementById("crosswordEditPage");

  if (showAnswerKey) {
    const characterElements = document.getElementsByClassName(
      "crosswordCharacter"
    );
    //@ts-ignore
    for (const element of characterElements) {
      element.style.display = "contents";
    }

    const clueElements = document.getElementsByClassName("clueAnswer");
    //@ts-ignore
    for (const element of clueElements) {
      element.style.display = "contents";
    }
  }

  // scale determines quality (4 = 400%)
  const scale = 3;
  let imgData = null;
  try {
    imgData = await domtoimage.toPng(input, {
      height: input.offsetHeight * scale,
      width: input.offsetWidth * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        height: input.offsetHeight + "px",
        width: input.offsetWidth + "px"
      }
    });
  } catch (e) {
    console.log(e);
  }

  // create fake component and add png to it temporarily to download
  let downloadLink = document.createElement("a");
  downloadLink.href = imgData;
  downloadLink.download = `${titleText}_crossword.png`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  const input2 = document.getElementById("cluesSecondPage");
  if (input2 !== null && input2 !== undefined) {
    // scale determines quality (4 = 400%)
    const scale = 3;
    let imgData = null;
    try {
      imgData = await domtoimage.toPng(input2, {
        height: input2.offsetHeight * scale,
        width: input2.offsetWidth * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          height: input2.offsetHeight + "px",
          width: input2.offsetWidth + "px"
        }
      });
    } catch (e) {
      console.log(e);
    }

    // create fake component and add png to it temporarily to download
    let downloadLink = document.createElement("a");
    downloadLink.href = imgData;
    downloadLink.download = `${titleText}_crossword_clues.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  if (showAnswerKey) {
    const characterElements = document.getElementsByClassName(
      "crosswordCharacter"
    );
    //@ts-ignore
    for (const element of characterElements) {
      element.style.display = "none";
    }

    const clueElements = document.getElementsByClassName("clueAnswer");
    //@ts-ignore
    for (const element of clueElements) {
      element.style.display = "none";
    }
  }
}
