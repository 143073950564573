import axios from "axios";
import { apiUrl } from "../apiconfig";

export async function getCrosswordInfo(
  words: Array<{
    id: number;
    word: string;
    clue: string;
  }>,
  maxXCoord: number,
  maxYCoord: number
) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );

  let result = null;
  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/algos/crossword`,
      data: {
        words,
        maxXCoord,
        maxYCoord
      },
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}
