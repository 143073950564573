import * as React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  toggleGenerate,
  toggleCrosswordSavedPreviewModal,
  setSavedCrosswordId,
  toggleSavedProductDeletionConfirmationModal
} from "../../../redux";
import { connect } from "react-redux";
//@ts-ignore
import BootstrapTable from "react-bootstrap-table-next";
//@ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
  //@ts-ignore
} from "react-bootstrap-table2-paginator";
import { getAllSavedProducts } from "../../../API/SavedProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSync } from "@fortawesome/free-solid-svg-icons";
import SavedProductDeletionConfirmation from "./SavedProductDeletionConfirmation";
import { nameOfAllProducts } from "../Variables";

export interface props {
  toggleGenerate: Function;
  toggleCrosswordSavedPreviewModal: Function;
  setSavedCrosswordId: Function;
  toggleSavedProductDeletionConfirmationModal: Function;
  type: string;
}

export interface state {
  showDeleteConfirmModal: boolean;
  savedProducts: Array<any>;
  savedProductToDeleteId: string;
  noSavedProducts: boolean;
  refreshing: boolean;
}

const Div = styled("div")`
  height: 25vh;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  background-color: #3c4c55;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const Title = styled("div")`
  color: #ffffff;
  font-size: 25px;
`;
const PremadeListForm = styled("div")`
  max-height: 76%;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #70c989;
`;
const TableDiv = styled("div")`
  height: 100%;
  overflow: auto;
  .table {
    margin-bottom: unset;
  }
  cursor: pointer;
  th.sortable {
    background-color: #252a31;
    &:hover {
      color: #70c989;
    }
    &:focus {
      outline: unset;
      color: #70c989;
      box-shadow: inset 0px -5px 0px 0px #70c989;
    }
    &:active {
      outline: unset;
      color: #70c989;
      box-shadow: inset 0px -5px 0px 0px #70c989;
    }
  }

  th {
    background-color: #252a31;
  }

  tr {
    &:hover {
      td {
        color: #70c989;
      }
    }
  }
  .table-bordered td {
    color: white;
    border: 0.1px solid #252a31;
    padding: 0;
    vertical-align: middle;
  }
  .table-bordered th {
    color: white;
    border: 0.1px solid #252a31;
  }
`;
const PaginationDiv = styled("div")`
  .pagination {
    margin: unset;
  }
  .page-link {
    display: flex;
    height: 26px;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 0 !important;
    background-color: #252a31;
    border: 0.5px solid #70c989;
    &:hover {
      color: #70c989;
      border: 0.5px solid #70c989;
      background-color: #252a31;
    }
    &:focus {
      box-shadow: unset;
    }
  }
  .page-item.active .page-link {
    background-color: #70c989;
    border: 0.5px solid #70c989;
  }
`;
const DeleteButton = styled(Button)`
  height: 26px !important;
  border: unset !important;
  border-radius: unset !important;
  background-color: #252a31 !important;
  color: white !important;
  margin: unset !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
`;
const RefreshButton = styled(Button)`
  height: 26px !important;
  border: unset !important;
  border-radius: unset !important;
  background-color: #252a31 !important;
  color: white !important;
  margin-left: 5px !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
`;
export class PremadeList extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      showDeleteConfirmModal: false,
      savedProducts: [],
      savedProductToDeleteId: "",
      noSavedProducts: false,
      refreshing: false
    };
  }

  componentDidMount() {
    this.getAllSavedProducts(this.props.type);
  }

  getAllSavedProducts = async (type: string) => {
    let savedProducts = null;
    try {
      savedProducts = await getAllSavedProducts(this.props.type);
    } catch (e) {
      throw e;
    }

    if (savedProducts.length === 0) {
      this.setState({ noSavedProducts: true });
    } else {
      this.setState({ savedProducts });
    }

    this.setState({ refreshing: false });
  };

  handleRowClick = async (e: any, row: any, rowIndex: any) => {
    if (
      this.props.type === nameOfAllProducts[0] &&
      e.target.id !== "deleteButton"
    ) {
      this.props.setSavedCrosswordId(row.id);
      this.props.toggleGenerate(false);
      this.props.toggleCrosswordSavedPreviewModal(true);
    }
  };

  deleteButton = (cell: any, row: any, rowIndex: any) => {
    return (
      <DeleteButton
        id="deleteButton"
        onClick={() =>
          this.setState(
            {
              savedProductToDeleteId: row.id
            },
            () => this.props.toggleSavedProductDeletionConfirmationModal(true)
          )
        }
      >
        X{/* <FontAwesomeIcon color="#ffffff" icon={faTrash} /> */}
      </DeleteButton>
    );
  };

  refreshSavedProductList = async () => {
    this.setState(
      {
        savedProducts: []
      },
      () => {
        this.getAllSavedProducts(this.props.type);
      }
    );
  };

  handleRefreshButton = () => {
    this.setState({ refreshing: true, savedProducts: [] }, () =>
      this.getAllSavedProducts(this.props.type)
    );
  };

  getTing = () => {
    // @ts-ignore
    const ting = ({ paginationProps, paginationTableProps }) => (
      <ToolkitProvider
        keyField="id"
        search
        data={this.state.savedProducts}
        columns={[
          {
            dataField: "id",
            hidden: true
          },
          {
            dataField: "rawData.titleText",
            text: "Title",
            sort: true
          },
          {
            dataField: "date",
            text: "Date",
            sort: true
          },
          {
            dataField: "delete",
            text: "",
            formatter: this.deleteButton,
            headerStyle: { width: "36px" }
          }
        ]}
      >
        {(toolkitProps: any) => (
          <React.Fragment>
            <TableDiv>
              <BootstrapTable
                noDataIndication={() => (
                  <div>
                    {this.state.refreshing
                      ? `Refreshing... `
                      : this.state.noSavedProducts
                      ? `Looks like you don't have any saved ${this.props.type}s... `
                      : "Loading..."}
                  </div>
                )}
                hover
                rowStyle={{ lineHeight: "5px" }}
                rowEvents={{
                  onClick: (e: any, row: any, rowIndex: any) =>
                    this.handleRowClick(e, row, rowIndex)
                }}
                {...toolkitProps.baseProps}
                {...paginationTableProps}
              />
            </TableDiv>
            <PaginationDiv>
              <PaginationListStandalone {...paginationProps} />
            </PaginationDiv>
          </React.Fragment>
        )}
      </ToolkitProvider>
    );

    return ting;
  };

  render(): JSX.Element {
    return (
      <Div>
        <SavedProductDeletionConfirmation
          type={this.props.type}
          idOfProductToDelete={this.state.savedProductToDeleteId}
          refreshSavedProductList={this.refreshSavedProductList}
        />
        <Title>Saved</Title>
        <RefreshButton
          onClick={this.handleRefreshButton}
          style={{
            alignSelf: "flex-end",
            marginRight: "76px"
          }}
        >
          <FontAwesomeIcon color="#ffffff" icon={faSync} />
        </RefreshButton>
        <PremadeListForm>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page: 1,
              sizePerPage: 5
            })}
          >
            {this.getTing()}
          </PaginationProvider>
        </PremadeListForm>
      </Div>
    );
  }
}

const PremadeListConnected = connect(null, {
  toggleGenerate,
  toggleCrosswordSavedPreviewModal,
  setSavedCrosswordId,
  toggleSavedProductDeletionConfirmationModal
})(PremadeList);

export default PremadeListConnected;
