import * as React from "react";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  UncontrolledTooltip
} from "reactstrap";
//@ts-ignore
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { connect } from "react-redux";
import { initiateForgotPassword, confirmForgotPassword } from "../../API/Auth";
import {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast
} from "../../redux";
import PasswordForm from "./PasswordForm";

export interface props {
  toggleUserAuthModal: Function;
  toggleIsLoggedIn: Function;
  toggleAuthToShow: Function;
  toggleToast: Function;
}

export interface state {
  emailText: string;
  attemptInitiateForgotPassword: boolean;
  step: number;
  attemptConfirmForgotPassword: boolean;
  confirmationCodeText: string;
  newPasswordText: string;
  confirmPasswordText: string;
  passwordIsValid: boolean;
}

const Div = styled("div")<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Inputs = styled("div")`
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
`;
const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
`;
const Footer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const ReactStrapButton = styled(Button)`
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 36px !important;
  width: 98px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const StepText = styled("div")`
  max-width: 270px;
  font-size: 15px;
  color: white;
  margin: auto auto 10px auto;
`;
export class Login extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      emailText: "",
      attemptInitiateForgotPassword: false,
      step: 1,
      attemptConfirmForgotPassword: false,
      confirmationCodeText: "",
      newPasswordText: "",
      confirmPasswordText: "",
      passwordIsValid: false
    };
  }

  attemptInitiateForgotPassword = async () => {
    this.setState({
      attemptInitiateForgotPassword: true
    });

    let result = null;
    try {
      result = await initiateForgotPassword(this.state.emailText);
    } catch (e) {
      this.props.toggleToast(
        true,
        e.response.data.code === "LimitExceededException"
          ? "Unable to reset password at this time, please try again later"
          : "Invalid email"
      );
      this.setState({
        attemptInitiateForgotPassword: false
      });
    }

    if ("sent" in result && result.sent === true) {
      this.setState({ step: 2, attemptInitiateForgotPassword: false });
    } else {
      this.props.toggleToast(true, "Invalid email");
      this.setState({
        attemptInitiateForgotPassword: false
      });
    }
  };

  attemptConfirmForgotPassword = async () => {
    this.setState({
      attemptConfirmForgotPassword: true
    });

    let result = null;
    try {
      result = await confirmForgotPassword(
        this.state.emailText,
        this.state.newPasswordText,
        this.state.confirmationCodeText
      );
    } catch (e) {
      this.props.toggleToast(true, "Invalid reset code or password");
      this.setState({
        attemptConfirmForgotPassword: false
      });
    }

    if ("updated" in result && result.updated === true) {
      // navigate back to login and let user know that password reset worked
      this.props.toggleToast(true, "Password successfully reset. 👍");
      this.props.toggleAuthToShow("login");
    } else {
      this.props.toggleToast(true, "Invalid reset code or password");
      this.setState({
        attemptConfirmForgotPassword: false
      });
    }
  };

  getBodyForStep = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <React.Fragment>
          <Inputs>
            <StepText>Send yourself a code to reset your password</StepText>
            <StyledInputGroup>
              <InputGroupAddon className="titleLabel" addonType="append">
                Email
              </InputGroupAddon>
              <Input
                type="email"
                className="loginInputs"
                value={this.state.emailText}
                onChange={e => {
                  this.setState({ emailText: e.target.value });
                }}
                style={{
                  borderRadius: 0
                }}
              />
            </StyledInputGroup>
          </Inputs>
          <Footer>
            <ReactStrapButton
              onClick={() => this.attemptInitiateForgotPassword()}
              disabled={this.state.attemptInitiateForgotPassword}
            >
              {this.state.attemptInitiateForgotPassword ? (
                <Loader type="Rings" color="#fff" height={36} width={36} />
              ) : (
                "Send"
              )}
            </ReactStrapButton>
          </Footer>
        </React.Fragment>
      );
    }

    if (step === 2) {
      return (
        <React.Fragment>
          <Inputs>
            <StepText>
              Enter the code from your email and a new password below
            </StepText>
            <StyledInputGroup>
              <InputGroupAddon className="titleLabel" addonType="append">
                Code
              </InputGroupAddon>
              <Input
                type="number"
                className="loginInputs"
                value={this.state.confirmationCodeText}
                onChange={e => {
                  this.setState({ confirmationCodeText: e.target.value });
                }}
                style={{
                  borderRadius: 0
                }}
              />
            </StyledInputGroup>
            <PasswordForm
              showTooltip={true}
              tooltipId="passwordFormTooltip"
              passwordText={this.state.newPasswordText}
              label="New Password"
              confirmPassword={false}
              validate={(passwordIsValid: boolean) => {
                this.setState({ passwordIsValid });
              }}
              updatePassword={(newPasswordText: string) => {
                this.setState({ newPasswordText });
              }}
            />
            <PasswordForm
              showTooltip={true}
              tooltipId="confirmPasswordFormTooltip"
              label="Confirm Password"
              passwordText={this.state.confirmPasswordText}
              confirmPassword={true}
              updatePassword={(confirmPasswordText: string) => {
                this.setState({ confirmPasswordText });
              }}
              matchesPassword={
                this.state.newPasswordText === this.state.confirmPasswordText &&
                this.state.confirmPasswordText.length > 0
              }
            />
          </Inputs>
          <Footer>
            <ReactStrapButton
              onClick={() => this.attemptConfirmForgotPassword()}
              disabled={
                this.state.attemptConfirmForgotPassword ||
                this.state.newPasswordText !== this.state.confirmPasswordText ||
                !this.state.passwordIsValid ||
                this.state.confirmationCodeText.length === 0
              }
            >
              {this.state.attemptConfirmForgotPassword ? (
                <Loader type="Rings" color="#fff" height={36} width={36} />
              ) : (
                "Reset"
              )}
            </ReactStrapButton>
          </Footer>
        </React.Fragment>
      );
    }
  };

  render(): JSX.Element {
    return <Div>{this.getBodyForStep()}</Div>;
  }
}

const LoginContainer = connect(null, {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast
})(Login);

export default LoginContainer;
