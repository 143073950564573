import * as React from "react";
import styled from "styled-components";
import { Button, Modal } from "reactstrap";
import {
  toggleSavedProductDeletionConfirmationModal,
  toggleToast
} from "../../../redux";
import { connect } from "react-redux";
import { deleteSavedProduct } from "../../../API/SavedProducts";

export interface props {
  toggleToast: Function;
  toggleSavedProductDeletionConfirmationModal: Function;
  type: string;
  idOfProductToDelete: string;
  showSavedProductDeletionConfirmationModal: boolean;
  refreshSavedProductList: Function;
}

export interface state {
  showSavedProductDeletionConfirmationModal: boolean;
  attemptingDelete: boolean;
}
const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  min-height: 300px;
  .modal-content {
    padding: 20px;
    min-height: 300px;
    max-width: 365px;
    display: flex;
    border-radius: 0 !important;
    border: none;
    background-color: #3c4c55;
  }
`;
const ModalText = styled("div")`
  color: white;
  margin: 10px 0 10px 0;
`;
const ReactStrapButton = styled(Button)`
  margin: 0 10px 0 10px;
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 36px !important;
  width: 98px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const Footer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
`;
export class SavedProductDeletionConfirmationModal extends React.Component<
  props,
  state
> {
  constructor(props: props) {
    super(props);

    this.state = {
      showSavedProductDeletionConfirmationModal: false,
      attemptingDelete: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      showSavedProductDeletionConfirmationModal?: boolean;
    } = {};

    if (
      nextProps.showSavedProductDeletionConfirmationModal !==
      prevState.showSavedProductDeletionConfirmationModal
    ) {
      update.showSavedProductDeletionConfirmationModal =
        nextProps.showSavedProductDeletionConfirmationModal;
    }

    return Object.keys(update).length ? update : null;
  }

  handleSavedDelete = async () => {
    this.setState({
      attemptingDelete: true
    });

    let result = null;
    try {
      result = await deleteSavedProduct(
        this.props.type,
        this.props.idOfProductToDelete
      );
    } catch (e) {
      this.props.toggleToast(true, "Unable to delete, please try again later");
    }

    if ("deleted" in result && result.deleted === true) {
      this.props.refreshSavedProductList();
      this.props.toggleToast(true, "Successfuly deleted");
      this.props.toggleSavedProductDeletionConfirmationModal(false);
    } else {
      this.props.toggleToast(true, "Unable to delete, please try again later");
    }

    this.setState({
      attemptingDelete: false
    });
  };

  render(): JSX.Element {
    return (
      <StyledModal
        isOpen={this.state.showSavedProductDeletionConfirmationModal}
        toggle={() =>
          this.props.toggleSavedProductDeletionConfirmationModal(false)
        }
        centered={true}
      >
        <ModalText>
          Are you sure you want to delete this {this.props.type}?
        </ModalText>
        <Footer>
          <ReactStrapButton
            onClick={() =>
              this.props.toggleSavedProductDeletionConfirmationModal(false)
            }
          >
            Nevermind
          </ReactStrapButton>
          <ReactStrapButton
            disabled={this.state.attemptingDelete}
            onClick={() => this.handleSavedDelete()}
          >
            Delete
          </ReactStrapButton>
        </Footer>
      </StyledModal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    showSavedProductDeletionConfirmationModal:
      state.universalProductTingsReducer
        .showSavedProductDeletionConfirmationModal
  };
}

const SavedProductDeletionConfirmationModalConnected = connect(
  mapStateToProps,
  { toggleSavedProductDeletionConfirmationModal, toggleToast }
)(SavedProductDeletionConfirmationModal);

export default SavedProductDeletionConfirmationModalConnected;
