import * as React from "react";
import styled from "styled-components";
//@ts-ignore
import Loader from "react-loader-spinner";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import { connect } from "react-redux";
import Switch from "react-switch";
import {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast
} from "../../redux";
import Select from "react-select";
import PasswordForm from "./PasswordForm";
import { register, confirmRegister } from "../../API/Auth";
import ConfirmationCodeStep from "./ConfirmationCodeStep";

export interface props {
  toggleUserAuthModal: Function;
  toggleIsLoggedIn: Function;
  toggleAuthToShow: Function;
  toggleToast: Function;
}

export interface state {
  emailText: string;
  passwordText: string;
  confirmPasswordText: string;
  attemptRegisterFirstStep: boolean;
  passwordIsValid: boolean;
  step: number;
  confirmationCodeText: string;
  attemptRegisterSecondStep: boolean;
  // personalOptions: Array<{ value: string; label: string }>;
  // selectedOption: { value?: string; label?: string };
  receiveEmail: boolean;
  legalAcknowledge: boolean;
}

const Div = styled("div")<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Inputs = styled("div")`
  width: 100%;
  flex: 1;
  margin-bottom: 20px;
`;
const StyledInputGroup = styled(InputGroup)`
  max-width: 270px;
  margin: auto;
  padding-bottom: 10px;
  width: unset !important;
  .loginInputs {
    height: 30px !important;
    border: unset !important;
  }
  .titleLabel {
    height: 30px !important;
  }
  .input-group-text {
    border: unset !important;
    background-color: #252a31 !important;
    color: white !important;
  }
  .categoryLabel {
    height: 30px !important;
  }
`;
const Footer = styled("div")`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const ReactStrapButton = styled(Button)`
  padding: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 36px !important;
  width: 98px !important;
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-color: transparent !important
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
`;
const StepText = styled("div")`
  color: white;
  max-width: 270px;
  font-size: 15px;
`;
const EmailDiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Link = styled("span")`
  color: #70c989;
  &:hover {
    cursor: pointer;
  }
`;
const styles = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: 30,
    height: 30,
    width: 173,
    borderRadius: 0,
    border: "unset",
    boxShadow: "unset"
  }),
  container: (base: any, state: any) => ({
    ...base,
    height: 26
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0
  }),
  menu: (base: any) => ({
    ...base,
    margin: 0,
    borderRadius: 0
  }),
  option: (base: any, state: any) => ({
    ...base,
    paddingTop: 5,
    paddingBottom: 5,
    height: 30,
    backgroundColor: state.isSelected
      ? "#70c989"
      : state.isFocused
      ? "#70c98963"
      : null
  })
};
export class Register extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      emailText: "",
      passwordText: "",
      confirmPasswordText: "",
      passwordIsValid: false,
      attemptRegisterFirstStep: false,
      step: 1,
      confirmationCodeText: "",
      attemptRegisterSecondStep: false,
      // personalOptions: [
      //   { value: "student", label: "Student" },
      //   { value: "teacher", label: "Teacher" },
      //   { value: "other", label: "Other" },
      //   { value: "preferNotToSay", label: "Prefer not to say" }
      // ],
      // selectedOption: { value: "preferNotToSay", label: "Prefer not to say" },
      receiveEmail: false,
      legalAcknowledge: false
    };
  }

  componentDidMount = () => {};

  attemptRegisterFirstStep = async () => {
    this.setState({
      attemptRegisterFirstStep: true
    });

    let result = null;
    try {
      result = await register(
        this.state.emailText,
        this.state.passwordText,
        // this.state.selectedOption.value,
        this.state.receiveEmail
      );
    } catch (e) {
      this.props.toggleToast(
        true,
        e.response.data.code === "UsernameExistsException"
          ? "Email already exists"
          : "Unable to register, please verify a valid email is given"
      );
      this.setState({
        attemptRegisterFirstStep: false
      });
    }

    if ("created" in result && result.created === true) {
      this.setState({ step: 2, attemptRegisterFirstStep: false });
    } else {
      this.props.toggleToast(
        true,
        "Unable to register, please verify a valid email is given"
      );
      this.setState({
        attemptRegisterFirstStep: false
      });
    }
  };

  attemptRegisterSecondStep = async () => {
    this.setState({
      attemptRegisterSecondStep: true
    });

    let result = null;
    try {
      result = await confirmRegister(
        this.state.emailText,
        this.state.confirmationCodeText
      );
    } catch (e) {
      this.props.toggleToast(
        true,
        "Unable to complete registration, check that the confirmation code matches the one you received"
      );
      this.setState({
        attemptRegisterSecondStep: false
      });
    }

    if ("confirmed" in result && result.confirmed === true) {
      // navigate back to login and let user know that registration worked
      this.props.toggleToast(
        true,
        "Signed up successfuly, you can now login. 👍"
      );
      this.props.toggleAuthToShow("login");
    } else {
      this.props.toggleToast(
        true,
        "Unable to complete registration, check that the confirmation code matches the one you received"
      );
      this.setState({
        attemptRegisterSecondStep: false
      });
    }
  };

  getBodyForStep = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <React.Fragment>
          <Inputs>
            <StyledInputGroup>
              <InputGroupAddon className="titleLabel" addonType="append">
                Email
              </InputGroupAddon>
              <Input
                type="email"
                className="loginInputs"
                value={this.state.emailText}
                onChange={e => {
                  this.setState({ emailText: e.target.value });
                }}
                style={{
                  borderRadius: 0
                }}
              />
            </StyledInputGroup>
            <PasswordForm
              showTooltip={true}
              tooltipId="passwordFormTooltip"
              passwordText={this.state.passwordText}
              label="Password"
              confirmPassword={false}
              validate={(passwordIsValid: boolean) => {
                this.setState({ passwordIsValid });
              }}
              updatePassword={(passwordText: string) => {
                this.setState({ passwordText });
              }}
            />
            <PasswordForm
              showTooltip={true}
              tooltipId="confirmPasswordFormTooltip"
              label="Confirm Password"
              passwordText={this.state.confirmPasswordText}
              confirmPassword={true}
              updatePassword={(confirmPasswordText: string) => {
                this.setState({ confirmPasswordText });
              }}
              matchesPassword={
                this.state.passwordText === this.state.confirmPasswordText &&
                this.state.confirmPasswordText.length > 0
              }
            />
            {/* <StyledInputGroup>
              <InputGroupAddon className="categoryLabel" addonType="append">
                About You
              </InputGroupAddon>
              <Select
                styles={styles}
                value={this.state.selectedOption}
                onChange={(selectedOption: any) => {
                  this.setState({ selectedOption });
                }}
                options={this.state.personalOptions}
              />
            </StyledInputGroup> */}
            <StyledInputGroup>
              <EmailDiv>
                <StepText style={{ marginRight: "10px" }}>
                  Would you like to receive emails about updates on the site?
                </StepText>
                <Switch
                  offColor="#252a31"
                  onColor="#6d7b8e"
                  onChange={() => {
                    this.setState({ receiveEmail: !this.state.receiveEmail });
                  }}
                  checked={this.state.receiveEmail}
                />
              </EmailDiv>
            </StyledInputGroup>
            <StyledInputGroup>
              <EmailDiv>
                <StepText style={{ marginRight: "10px" }}>
                  <span>
                    Do you acknowledge that you have read and agree to the terms
                    of the{" "}
                    <Link
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/PrivacyPolicy`,
                          "_blank"
                        )
                      }
                    >
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/TermsAndConditions`,
                          "_blank"
                        )
                      }
                    >
                      Terms and Conditions
                    </Link>
                    ?
                  </span>
                </StepText>
                <Switch
                  offColor="#252a31"
                  onColor="#6d7b8e"
                  onChange={() => {
                    this.setState({
                      legalAcknowledge: !this.state.legalAcknowledge
                    });
                  }}
                  checked={this.state.legalAcknowledge}
                />
              </EmailDiv>
            </StyledInputGroup>
          </Inputs>
          <Footer>
            <ReactStrapButton
              onClick={() => this.attemptRegisterFirstStep()}
              disabled={
                this.state.attemptRegisterFirstStep ||
                this.state.passwordText !== this.state.confirmPasswordText ||
                !this.state.passwordIsValid ||
                this.state.emailText.length === 0 ||
                // this.state.selectedOption.value === "" ||
                // this.state.selectedOption.value === "" ||
                !this.state.legalAcknowledge
              }
            >
              {this.state.attemptRegisterFirstStep ? (
                <Loader type="Rings" color="#fff" height={36} width={36} />
              ) : (
                "Next"
              )}
            </ReactStrapButton>
          </Footer>
        </React.Fragment>
      );
    }

    if (step === 2) {
      return <ConfirmationCodeStep emailText={this.state.emailText} />;
    }
  };

  render(): JSX.Element {
    return <Div>{this.getBodyForStep()}</Div>;
  }
}

const RegisterContainer = connect(null, {
  toggleUserAuthModal,
  toggleIsLoggedIn,
  toggleAuthToShow,
  toggleToast
})(Register);

export default RegisterContainer;
