import * as React from "react";
import styled from "styled-components";
import { Modal } from "reactstrap";
import {
  toggleGenerate,
  toggleCrosswordPremadePreviewModal,
  setRawDataToSave,
  setProductIdToCheckIfAlreadySaved
} from "../../../../redux";
import { getPremadeProductById } from "../../../../API/PremadeProducts";
import { Preview } from "../Preview/CrosswordPreview";
import { connect } from "react-redux";
//@ts-ignore
import Loader from "react-loader-spinner";
import DownloadDropdown from "./Buttons/DownloadDropdown";
import CustomizeButton from "./Buttons/CustomizeButton";
import SaveCrosswordButton from "./Buttons/SaveCrosswordButton";
import { nameOfAllProducts } from "../../../Helpers/Variables";

export interface props {
  toggleGenerate: Function;
  toggleCrosswordPremadePreviewModal: Function;
  showCrosswordPremadePreviewModal: boolean;
  premadeCrosswordId: string;
  setRawDataToSave: Function;
  setProductIdToCheckIfAlreadySaved: Function;
}

export interface state {
  grid: Array<any>;
  puzzleBlockHeightInCm: number;
  maxXCoord: number;
  maxYCoord: number;
  horizontalShift: number;
  verticalShift: number;
  lowestMostYCoord: number;
  leftMostXCoord: number;
  rightMostXCoord: number;
  wordsOnGrid: Array<{
    id: number;
    word: string;
    clue: string;
    orientation: string;
    startCoord: string;
    endCoord: string;
  }>;
  charactersOnGrid: { [key: string]: string };
  puzzleLoaded: boolean;
  HorizontalClueListFontSizeInPx: number;
  VerticalClueListFontSizeInPx: number;
  showTitle: boolean;
  showDateInput: boolean;
  showNameInput: boolean;
  customInput1Text: string;
  showCustomInput1: boolean;
  customInput2Text: string;
  showCustomInput2: boolean;
  bigifiedClueNumberLabel: boolean;
  titleText: string;
  changedPuzzleBlockHeightInCm: number;
  totalHeightInCm: number;
  totalWidthInCm: number;
  changedMaxXCoord: number;
  changedMaxYCoord: number;
  showAnswerKey: boolean;
  showCrosswordPremadePreviewModal: boolean;
  premadeCrosswordId: string;
}

const StyledModalBody = styled("div")`
  background-color: #3c4c55;
  padding: 36px;
`;
const StyledModal = styled(Modal)<{}>`
  justify-content: center;
  .modal-content {
    width: unset;
    border-radius: 0 !important;
    border: none;
    background-color: transparent;
  }
`;
const CloseButton = styled("div")`
  position: absolute;
  align-self: flex-end;
  // border: 1px solid #70c989;
  height: 34px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3c4c55;
  width: 36px;
  font-weight: 700;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  color: #ffffff;
`;
const GridDiv = styled("div")<{
  puzzleBlockHeightInCm: number;
}>`
  display: flex;
  justify-content: center;

  .characterBox {
    height: ${props => props.puzzleBlockHeightInCm}cm;
    width: ${props => props.puzzleBlockHeightInCm}cm;
  }
  .emptyCharacterBox {
    height: ${props => props.puzzleBlockHeightInCm}cm;
    width: ${props => props.puzzleBlockHeightInCm}cm;
  }
  .wordId {
    font-size: 44%;
    color: black;
  }
`;
const ConfigButtons = styled("div")`
  display: flex;
  flex-direction: row;
  min-width: 390px;
  justify-content: space-between;
  margin-bottom: 25px;
`;
const CharacterBox = styled("div")`
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 1.6px 1.5px 0px 2.5px black;
  position: relative;
  z-index: 1;
`;

const EmptyCharacterBox = styled("div")`
  background-color: white;
  // box-shadow: 1.6px 1.5px 0px 2.5px white;
  position: relative;
`;
const WordId = styled("span")`
  font-weight: bold;
  left: 0.5px;
  position: absolute;
`;
const Character = styled("span")`
  font-weight: 600;
  font-size: 85%;
`;
const EditPage = styled("div")<{ totalHeightInCm: number }>`
  max-height: ${props => props.totalHeightInCm}cm;
  display: flex;
  position: relative;
`;
const Body = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export class PremadePreviewModal extends React.Component<props, state> {
  titleDivHeightInCm: number;
  constructor(props: props) {
    super(props);
    this.titleDivHeightInCm = 2.5;
    this.state = {
      grid: [],
      puzzleBlockHeightInCm: 0.6,
      maxXCoord: 33,
      maxYCoord: 44,
      horizontalShift: 0,
      verticalShift: 0,
      lowestMostYCoord: 0,
      leftMostXCoord: 0,
      rightMostXCoord: 0,
      wordsOnGrid: [],
      charactersOnGrid: {},
      puzzleLoaded: false,
      HorizontalClueListFontSizeInPx: 16,
      VerticalClueListFontSizeInPx: 16,
      showTitle: true,
      showDateInput: false,
      showNameInput: false,
      customInput1Text: "",
      showCustomInput1: false,
      customInput2Text: "",
      showCustomInput2: false,
      bigifiedClueNumberLabel: false,
      titleText: "",
      changedPuzzleBlockHeightInCm: 0,
      totalHeightInCm: 0,
      totalWidthInCm: 0,
      changedMaxXCoord: 0,
      changedMaxYCoord: 0,
      showAnswerKey: false,
      showCrosswordPremadePreviewModal: false,
      premadeCrosswordId: ""
    };
  }

  componentDidUpdate(prevProps: props, prevState: state) {
    if (
      this.props.showCrosswordPremadePreviewModal === true &&
      prevState.showCrosswordPremadePreviewModal === false
    ) {
      this.getPremadeGrid();
    }
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      showCrosswordPremadePreviewModal?: boolean;
      puzzleLoaded?: boolean;
      premadeCrosswordId?: string;
    } = {};

    if (
      nextProps.showCrosswordPremadePreviewModal !==
      prevState.showCrosswordPremadePreviewModal
    ) {
      update.showCrosswordPremadePreviewModal =
        nextProps.showCrosswordPremadePreviewModal;
    }

    if (nextProps.premadeCrosswordId !== prevState.premadeCrosswordId) {
      update.premadeCrosswordId = nextProps.premadeCrosswordId;
    }

    if (!nextProps.showCrosswordPremadePreviewModal) {
      update.puzzleLoaded = false;
    }
    return Object.keys(update).length ? update : null;
  }

  getPremadeGrid = async () => {
    let result = null;
    try {
      result = await getPremadeProductById(
        nameOfAllProducts[0],
        this.state.premadeCrosswordId,
        this.props.setRawDataToSave,
        this.props.setProductIdToCheckIfAlreadySaved
      );
    } catch (e) {
      throw e;
    }

    const {
      wordsOnGrid,
      charactersOnGrid,
      lowestMostYCoord,
      horizontalShift,
      verticalShift,
      unplacedWords,
      HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx,
      showTitle,
      showDateInput,
      showNameInput,
      customInput1Text,
      showCustomInput1,
      customInput2Text,
      showCustomInput2,
      bigifiedClueNumberLabel,
      titleText,
      puzzleBlockHeightInCm,
      changedPuzzleBlockHeightInCm,
      totalHeightInCm,
      totalWidthInCm,
      maxXCoord,
      maxYCoord
    } = result;

    let showUnplacedWords = false;
    if (unplacedWords.length > 0) {
      showUnplacedWords = true;
    }
    this.setState(
      {
        // showUnplacedWords,
        wordsOnGrid,
        charactersOnGrid,
        lowestMostYCoord,
        horizontalShift,
        verticalShift,
        // unplacedWords,
        HorizontalClueListFontSizeInPx,
        VerticalClueListFontSizeInPx,
        showTitle,
        showDateInput,
        showNameInput,
        customInput1Text,
        showCustomInput1,
        customInput2Text,
        showCustomInput2,
        bigifiedClueNumberLabel,
        titleText,
        puzzleBlockHeightInCm,
        changedPuzzleBlockHeightInCm,
        totalHeightInCm,
        totalWidthInCm,
        maxXCoord,
        changedMaxXCoord: maxXCoord,
        maxYCoord,
        changedMaxYCoord: maxYCoord
      },
      () => {
        this.setPremadeGrid();
      }
    );
  };

  getWordId = (xCoord: number, yCoord: number) => {
    // unshift after this.centerPuzzle() has run to find proper placement of words
    xCoord -= this.state.horizontalShift;
    yCoord -= this.state.verticalShift;

    const { wordsOnGrid } = this.state;
    for (const wordObj of wordsOnGrid) {
      if (wordObj.startCoord === `${xCoord},${yCoord}`) {
        return wordObj.id;
      }
    }
  };

  setPremadeGrid = () => {
    let grid = [];

    for (let x = 0; x < this.state.maxXCoord + 1; x += 1) {
      let gridLine = [];
      for (let y = this.state.maxYCoord; y >= 0; y -= 1) {
        const { charactersOnGrid } = this.state;
        if (`${x},${y}` in charactersOnGrid) {
          gridLine.push(
            <CharacterBox className="characterBox" key={x + y}>
              <WordId className="wordId">{this.getWordId(x, y)}</WordId>
              <Character className="character">
                {charactersOnGrid[`${x},${y}`]}
              </Character>
            </CharacterBox>
          );
        } else {
          gridLine.push(
            <EmptyCharacterBox className="emptyCharacterBox" key={x + y}>
              {charactersOnGrid[`${x},${y}`]}
            </EmptyCharacterBox>
          );
        }
      }
      // add line to the grid
      grid.push(<div key={x}>{gridLine}</div>);
      // start new line on grid
      grid.push(<br key={x + 2222} />);
    }
    this.setState({ grid, puzzleLoaded: true });
  };

  setClueListFontSize = (
    HorizontalClueListFontSizeInPx: number,
    VerticalClueListFontSizeInPx: number
  ) => {
    this.setState({
      HorizontalClueListFontSizeInPx,
      VerticalClueListFontSizeInPx
    });
  };

  handleToggle = () => {
    this.props.toggleCrosswordPremadePreviewModal(false);
  };

  render(): JSX.Element {
    return (
      <StyledModal
        isOpen={this.state.showCrosswordPremadePreviewModal}
        toggle={this.handleToggle}
        centered={true}
      >
        <CloseButton onClick={this.handleToggle}>X</CloseButton>
        <StyledModalBody>
          <GridDiv puzzleBlockHeightInCm={this.state.puzzleBlockHeightInCm}>
            {this.state.puzzleLoaded ? (
              <Body>
                <ConfigButtons>
                  <DownloadDropdown
                    isSaveDropdown={false}
                    isPremadeDropdown={true}
                    isCreateDropdown={false}
                    titleText={this.state.titleText}
                    isCreatedSave={false}
                  />
                  <CustomizeButton
                    isPremadeButton={true}
                    isSaveButton={false}
                    wordsOnGrid={this.state.wordsOnGrid}
                    titleText={this.state.titleText}
                  />
                  <SaveCrosswordButton
                    type={nameOfAllProducts[0]}
                    isPremadeButton={true}
                    isSaveButton={false}
                    isCreateButton={false}
                  />
                </ConfigButtons>
                <EditPage totalHeightInCm={this.state.totalHeightInCm}>
                  <Preview
                    wordsOnGrid={this.state.wordsOnGrid}
                    lowestMostYCoord={this.state.lowestMostYCoord}
                    horizontalShift={this.state.horizontalShift}
                    verticalShift={this.state.verticalShift}
                    showAnswerKey={this.state.showAnswerKey}
                    showTitle={this.state.showTitle}
                    showDateInput={this.state.showDateInput}
                    showNameInput={this.state.showNameInput}
                    showCustomInput1={this.state.showCustomInput1}
                    customInput1Text={this.state.customInput1Text}
                    showCustomInput2={this.state.showCustomInput2}
                    customInput2Text={this.state.customInput2Text}
                    bigifiedClueNumberLabel={this.state.bigifiedClueNumberLabel}
                    VerticalClueListFontSizeInPx={
                      this.state.VerticalClueListFontSizeInPx
                    }
                    HorizontalClueListFontSizeInPx={
                      this.state.HorizontalClueListFontSizeInPx
                    }
                    titleText={this.state.titleText}
                    setClueListFontSize={this.setClueListFontSize}
                    titleDivHeightInCm={this.titleDivHeightInCm}
                    puzzleBlockHeightInCm={
                      this.state.changedPuzzleBlockHeightInCm
                    }
                    totalHeightInCm={this.state.totalHeightInCm}
                    totalWidthInCm={this.state.totalWidthInCm}
                    maxXCoord={this.state.maxXCoord}
                    maxYCoord={this.state.maxYCoord}
                    charactersOnGrid={this.state.charactersOnGrid}
                    moveCluesToAnotherPage={() => {
                      console.log("not gonna");
                    }}
                  />
                </EditPage>
              </Body>
            ) : (
              <Loader type="Rings" color="#70c989" height={400} width={400} />
            )}
          </GridDiv>
        </StyledModalBody>
      </StyledModal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    showCrosswordPremadePreviewModal:
      state.crosswordReducer.showCrosswordPremadePreviewModal,
    premadeCrosswordId: state.crosswordReducer.premadeCrosswordId
  };
}

const PremadePreviewModalContainer = connect(mapStateToProps, {
  toggleGenerate,
  toggleCrosswordPremadePreviewModal,
  setRawDataToSave,
  setProductIdToCheckIfAlreadySaved
})(PremadePreviewModal);

export default PremadePreviewModalContainer;
