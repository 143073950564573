import * as ReactDOM from "react-dom";
import * as React from "react";
import Main from "./Main";
import { Provider } from "react-redux";
import { store } from "./redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export class App {
  constructor() {
    this.render();
  }

  private render(): void {
    ReactDOM.render(
      <Provider store={store}>
        <Main />
      </Provider>,
      document.getElementById("app")
    );
  }
}

new App();
