import * as React from "react";
import styled from "styled-components";
import { Bingo } from "../../components/Bingo/Bingo";
import CrosswordFirstPage from "../Crossword/FirstPage/CrosswordFirstPage";
import CrosswordFinalPage from "../Crossword/FinalPage/CrosswordFinalPage";
import { Matching } from "../../components/Matching/Matching";
import { WordScrambler } from "../../components/WordScrambler/WordScrambler";
import { WordSearch } from "../../components/WordSearch/WordSearch";
import Profile from "../../components/Profile/Profile";
import { connect } from "react-redux";
import { nameOfAllProducts } from "../Helpers/Variables";
import PaymentSuccessful from "../../components/Payment/PaymentSuccessful";
import PaymentFailed from "../../components/Payment/PaymentFailed";
import AboutUs from "../../components/Legal/AboutUs";
import ContactUs from "../../components/Legal/ContactUs";
import PrivacyPolicy from "../../components/Legal/PrivacyPolicy";
import TermsAndConditions from "../../components/Legal/TermsAndConditions";
import moment from "moment";

export interface props {
  component: string;
}

export interface state {}

const Div = styled("div")<{ minWidth: string }>`
  min-width: ${props => props.minWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-height: 100%;
  background: #20272d;
  box-sizing: border-box;
  padding-top: 50px;
  position: absolute;
  width: 100%;
`;

const Component = styled("div")`
  min-width: 21cm;
  width: 21cm;
  min-height: calc(100vh - 90px);
  background: #2a3840;
  text-align: center;
  display: table;
`;
const LegalTings = styled("div")`
  font-size: 12px;
  min-width: 21cm;
  width: 21cm;
  height: 40px;
  background: #2a3840;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const LegalLinks = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const Link = styled("div")`
  color: #516571;
  font-weight: 600;
  &:hover {
    color: #70c989;
    cursor: pointer;
  }
  margin: 0 10px 0 10px;
`;
const CopyrightText = styled("div")`
  color: #516571;
  font-weight: 600;
  margin-top: 5px;
`;
export class DefaultPage extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {};
  }

  getComponent(type: string) {
    switch (type) {
      case "Bingo":
        return <Bingo />;
      case nameOfAllProducts[0]:
        return <CrosswordFirstPage />;
      case `${nameOfAllProducts[0]}FinalPage`:
        return <CrosswordFinalPage />;
      case `${nameOfAllProducts[1]}`:
        return <WordSearch />;
      // case `${nameOfAllProducts[1]}FinalPage`:
      //   return <WordSearchFinalPage />;
      case "WordScrambler":
        return <WordScrambler />;
      case "Matching":
        return <Matching />;
      case "Profile":
        return <Profile />;
      case "PaymentSuccessful":
        return <PaymentSuccessful />;
      case "PaymentFailed":
        return <PaymentFailed />;
      case "AboutUs":
        return <AboutUs />;
      case "ContactUs":
        return <ContactUs />;
      case "PrivacyPolicy":
        return <PrivacyPolicy />;
      case "TermsAndConditions":
        return <TermsAndConditions />;
    }
  }
  render(): JSX.Element {
    return (
      <Div
        minWidth={
          window.location.pathname.toLowerCase().includes("/crosswordfinalpage")
            ? "1350px"
            : ""
        }
      >
        <Component>{this.getComponent(this.props.component)}</Component>
        <LegalTings>
          <LegalLinks>
            <Link
              onClick={() =>
                window.open(`${window.location.origin}/AboutUs`, "_blank")
              }
            >
              About Us
            </Link>
            <Link
              onClick={() =>
                window.open(`${window.location.origin}/ContactUs`, "_blank")
              }
            >
              Contact Us
            </Link>
            <Link
              onClick={() =>
                window.open(`${window.location.origin}/PrivacyPolicy`, "_blank")
              }
            >
              Privacy Policy
            </Link>
            <Link
              onClick={() =>
                window.open(
                  `${window.location.origin}/TermsAndConditions`,
                  "_blank"
                )
              }
            >
              Terms and Conditions
            </Link>
          </LegalLinks>
          <CopyrightText>
            Copyright © {moment().format("YYYY")} wordymaker.com. All Rights
            Reserved.
          </CopyrightText>
        </LegalTings>
      </Div>
    );
  }
}

const DefaultPageContainer = connect()(DefaultPage);

export default DefaultPageContainer;
