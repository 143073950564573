import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

export interface props {}

export interface state {}

const Div = styled("div")`
  margin: 20px 20px 20px 20px;
  flex: 1;
  background-color: #3c4c55;
  padding: 20px 20px 20px 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
`;
const Header = styled("div")`
  padding-top: 20px;
  color: white;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
`;
const Description = styled("div")`
  padding-top: 10px;
  width: 100%;
  text-align: left;
  color: white;
  font-size: 15px;
  white-space: pre-line;
  line-height: 1;
`;
export class ContactUs extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    return (
      <Div>
        <Title>Contact Us</Title>
        <Description>
          If you have any suggestions, or need any help. Please contact us at
          wordymaker@gmail.com
        </Description>
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const ContactUsContainer = connect(mapStateToProps, {})(ContactUs);

export default ContactUsContainer;
