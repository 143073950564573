import * as React from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  toggleToast,
  toggleUserAuthModal,
  toggleAuthToShow,
  togglePaymentPlansModal,
  setProductIdToCheckIfAlreadySaved
} from "../../../../../redux";
import { connect } from "react-redux";
//@ts-ignore
import Loader from "react-loader-spinner";
import { saveProduct } from "../../../../../API/SavedProducts";
import {
  nameOfAllProducts,
  paymentPlansModalTypes
} from "../../../../Helpers/Variables";

export interface props {
  type: string;
  toggleUserAuthModal: Function;
  toggleAuthToShow: Function;
  isLoggedIn: boolean;
  isPremadeButton: boolean;
  isSaveButton: boolean;
  isCreateButton: boolean;
  toggleToast: Function;
  productIdToCheckIfAlreadySaved: string;
  crosswordRawDataToSave: any;
  togglePaymentPlansModal: Function;
  isPayingUser: boolean;
  setProductIdToCheckIfAlreadySaved: Function;
}

export interface state {
  isLoggedIn: boolean;
  productIdToCheckIfAlreadySaved: string;
  attemptingSave: boolean;
  crosswordRawDataToSave: any;
  isPayingUser: boolean;
}

const ReactStrapButton = styled(Button)`
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 36px;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
  width: 115px;
`;
export class SaveCrosswordButton extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      productIdToCheckIfAlreadySaved: "",
      attemptingSave: false,
      crosswordRawDataToSave: {},
      isPayingUser: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isLoggedIn?: boolean;
      productIdToCheckIfAlreadySaved?: string;
      crosswordRawDataToSave?: any;
      isPayingUser?: boolean;
    } = {};

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }

    if (
      nextProps.productIdToCheckIfAlreadySaved !==
      prevState.productIdToCheckIfAlreadySaved
    ) {
      update.productIdToCheckIfAlreadySaved =
        nextProps.productIdToCheckIfAlreadySaved;
    }

    if (nextProps.crosswordRawDataToSave !== prevState.crosswordRawDataToSave) {
      update.crosswordRawDataToSave = nextProps.crosswordRawDataToSave;
    }

    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    return Object.keys(update).length ? update : null;
  }

  handleSaveButton = async () => {
    if (!this.state.isLoggedIn) {
      this.props.toggleToast(
        true,
        "Please sign up or sign in to save crosswords"
      );
      this.props.toggleUserAuthModal(true);
      this.props.toggleAuthToShow("register");
      return;
    }

    // if (this.props.isCreateButton && !this.state.isPayingUser) {
    //   this.props.togglePaymentPlansModal(true);
    //   return;
    // }

    // user is logged in so initiate save
    let rawDataToSave = null;
    if (this.props.type === nameOfAllProducts[0]) {
      rawDataToSave = this.state.crosswordRawDataToSave;
    }

    this.setState({
      attemptingSave: true
    });
    let result = null;
    try {
      result = await saveProduct(
        this.props.type,
        rawDataToSave,
        this.state.productIdToCheckIfAlreadySaved,
        this.props.isPremadeButton
          ? this.state.productIdToCheckIfAlreadySaved
          : null,
        this.props.isCreateButton ? true : false,
        this.props.setProductIdToCheckIfAlreadySaved
      );
    } catch (e) {
      if (e.response.data.error === "Max save limit reached") {
        this.props.togglePaymentPlansModal(true, paymentPlansModalTypes[2]);
        this.props.toggleToast(
          true,
          "Unfortunately you have reached your save amount limit"
        );
      } else {
        this.props.toggleToast(true, "Unable to save, please try again later");
      }
      this.setState({
        attemptingSave: false
      });
    }

    if ("saved" in result && result.saved === true) {
      this.props.toggleToast(true, "Successfuly saved");
    } else if ("updated" in result && result.updated === true) {
      this.props.toggleToast(true, "Successfully updated");
    } else {
      this.props.toggleToast(true, "Unable to save, please try again later");
    }

    this.setState({
      attemptingSave: false
    });
  };

  render(): JSX.Element {
    return (
      <React.Fragment>
        <ReactStrapButton
          disabled={this.state.attemptingSave}
          ispremadebutton={this.props.isPremadeButton ? 1 : 0}
          onClick={() => this.handleSaveButton()}
        >
          {this.state.attemptingSave ? (
            <Loader type="Rings" color="#fff" height={25} width={36} />
          ) : (
            "Save"
          )}
        </ReactStrapButton>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isLoggedIn: state.userAuthReducer.isLoggedIn,
    productIdToCheckIfAlreadySaved:
      state.saveProductReducer.productIdToCheckIfAlreadySaved,
    crosswordRawDataToSave: state.saveProductReducer.crosswordRawDataToSave,
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const SaveCrosswordButtonContainer = connect(mapStateToProps, {
  toggleUserAuthModal,
  toggleAuthToShow,
  toggleToast,
  togglePaymentPlansModal,
  setProductIdToCheckIfAlreadySaved
})(SaveCrosswordButton);

export default SaveCrosswordButtonContainer;
