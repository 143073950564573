import axios from "axios";
import { apiUrl } from "../apiconfig";

export async function login(
  email: string,
  password: string,
  toggleUserAuthModal: Function,
  toggleIsLoggedIn: Function
) {
  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/auth/login`,
      data: {
        email,
        password
      }
    });
  } catch (e) {
    throw e;
  }

  setAccessTokenAndAccessUsername(
    result.data.accessToken,
    result.data.accessUsername,
    email
  );
  toggleUserAuthModal(false);
  toggleIsLoggedIn(true);
}

export function setAccessTokenAndAccessUsername(
  accessToken: string,
  accessUsername: string,
  email?: string
) {
  window.localStorage.setItem("crosswordTingAccessToken", accessToken);
  window.localStorage.setItem("crosswordTingAccessUsername", accessUsername);
  if (email !== undefined && email !== null) {
    window.localStorage.setItem("loggedInUserEmail", email);
  }
}

export function logoutLocally(toggleIsLoggedIn: Function) {
  window.localStorage.removeItem("crosswordTingAccessToken");
  window.localStorage.removeItem("crosswordTingAccessUsername");
  window.localStorage.removeItem("loggedInUserEmail");
  toggleIsLoggedIn(false);
}

export async function verifyAccess(toggleIsLoggedIn: Function) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );

  // either access token or access username aren't in local storage
  if (
    crosswordTingAccessToken === undefined ||
    crosswordTingAccessToken === null ||
    crosswordTingAccessUsername === undefined ||
    crosswordTingAccessUsername === null
  ) {
    logoutLocally(toggleIsLoggedIn);
    return;
  }

  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/auth/verifyAccess`,
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      }
    });
  } catch (e) {
    // access token and/or access username are invalid so remove from local storage
    logoutLocally(toggleIsLoggedIn);
    return;
  }

  // access token and access username given are valid so tell redux that user is logged in
  toggleIsLoggedIn(true);
  return result.data;
}

export async function initiateForgotPassword(email: string) {
  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/users/initiateForgotPassword`,
      data: {
        email
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function confirmForgotPassword(
  email: string,
  newPassword: string,
  confirmationCode: string
) {
  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/users/confirmForgotPassword`,
      data: {
        email,
        newPassword,
        confirmationCode
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function register(
  email: string,
  password: string,
  // background: string,
  receiveEmail: boolean
) {
  let result = null;

  try {
    result = await axios({
      method: "put",
      url: `${apiUrl}/users/`,
      data: {
        email,
        password,
        // background,
        receiveEmail
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function confirmRegister(email: string, code: string) {
  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/users/confirmWithCode`,
      data: {
        email,
        code
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function resendConfirmationCode(email: string) {
  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/users/resendConfirmCode`,
      data: {
        email
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function updatePasswordAsLoggedInUser(
  email: string,
  currentPassword: string,
  newPassword: string
) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );

  let result = null;

  try {
    result = await axios({
      method: "post",
      url: `${apiUrl}/users`,
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      },
      data: {
        email,
        currentPassword,
        newPassword
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}

export async function deleteUser(email: string) {
  const crosswordTingAccessToken = window.localStorage.getItem(
    "crosswordTingAccessToken"
  );
  const crosswordTingAccessUsername = window.localStorage.getItem(
    "crosswordTingAccessUsername"
  );

  let result = null;

  try {
    result = await axios({
      method: "delete",
      url: `${apiUrl}/users`,
      headers: {
        accessToken: crosswordTingAccessToken,
        accessUsername: crosswordTingAccessUsername
      },
      data: {
        email
      }
    });
  } catch (e) {
    throw e;
  }

  return result.data;
}
