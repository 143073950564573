import * as React from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import { toggleToast } from "../../redux";
import { connect } from "react-redux";

export interface props {
  autoClose: number;
  toggleToast: Function;
  showToast: boolean;
  toastText: string;
}
export interface state {
  showToast: boolean;
  toastText: string;
}

const StyledToast = styled(ToastContainer)`
  .toastClass {
    color: #232931;
    background-color: #70c989;
    font-weight: bold;
    height: 130px;
    border: 2px solid #252a31;
  }
  .toastProgressBarClass {
    background: linear-gradient(to right, #252a31, #6d7b8e);
  }
`;

export class Toast extends React.Component<props, state> {
  toastId: any;

  constructor(props: props) {
    super(props);
    this.state = {
      showToast: false,
      toastText: ""
    };

    this.toastId = null;
  }

  componentDidUpdate(prevProps: props, prevState: state) {
    if (this.state.showToast === true) {
      if (!toast.isActive(this.toastId)) {
        this.toastId = toast(this.state.toastText, { containerId: "toast" });
      }
      this.props.toggleToast(false);
    }
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: { showToast?: boolean; toastText?: string } = {};

    if (nextProps.showToast !== prevState.showToast) {
      update.showToast = nextProps.showToast;
    }

    if (nextProps.toastText !== prevState.toastText) {
      update.toastText = nextProps.toastText;
    }

    return Object.keys(update).length ? update : null;
  }

  render(): JSX.Element {
    return (
      <StyledToast
        containerId={"toast"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={this.props.autoClose}
        toastClassName="toastClass"
        progressClassName="toastProgressBarClass"
      />
    );
  }
}

function mapStateToProps(state: any) {
  return {
    showToast: state.toastReducer.showToast,
    toastText: state.toastReducer.toastText
  };
}

const ToastReduxContainer = connect(mapStateToProps, { toggleToast })(Toast);

export default ToastReduxContainer;
