import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import {
  toggleUserAuthModal,
  toggleAuthToShow,
  toggleIsLoggedIn
} from "../../redux";
import { Button } from "reactstrap";
import { logoutLocally } from "../../API/Auth";
import { nameOfAllProducts, displayProductName } from "../Helpers/Variables";

export interface props {
  printCrossword?: any;
  isLoggedIn: boolean;
  toggleUserAuthModal: Function;
  toggleAuthToShow: Function;
  toggleIsLoggedIn: Function;
}
export interface state {
  currentNavHeight: string;
  newNavHeight: string;
  currentLinkBlockHeight: string;
  newLinkBlockHeight: string;
  showEditFor: string;
  minimized: boolean;
  isLoggedIn: boolean;
}

const smoothNavHeightChange = (props: {
  currentNavHeight: string;
  newNavHeight: string;
}) => keyframes`
0% {
    height: ${props.currentNavHeight};
}
100% {
    height: ${props.newNavHeight};
}
`;

const backGroundColorTransition = keyframes`
// 0% {
//     background-color: #232931;
// }
// 50% {
//     background-color: #304040;
// }
// 100% {
//     background-color: #232931;
// }
`;

const Nav = styled("div")<{
  currentNavHeight: string;
  newNavHeight: string;
  minimized: boolean;
}>`
min-width: 100vw;
height: ${props => props.newNavHeight}
background-color: #2a3840;
//   box-shadow: inset 0px 0 275px 30px rgba(0,0,0,0.8);

// animation tings
animation: ${smoothNavHeightChange} 1s ease,  ${backGroundColorTransition} 20s ease infinite;

// nav bar being over everything tings
position: fixed;
transition: top 0.3s;
top: 0; left: 0; right: 0; bottom: 0;
z-index: 5;

display: flex;
flex-direction: ${props => (props.minimized ? "row" : "column")};
box-shadow: inset 0 -1px 0 #70c989;
`;
const Box1 = styled("div")`
  flex: 1;
`;

const LinkBlockContainer = styled("div")`
  display: flex;
  flex-direcion: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const LinkBlock = styled(Link)<{
  newlinkblockheight: string;
  currentlinkblockheight: string;
  minimized: boolean;
  active: number;
}>`
  display: flex;
  align-items: center;

  width: 130px;
  height: ${props => (props.minimized ? "100%" : props.newlinkblockheight)};
  background-color: unset;
  position: relative;
  box-shadow: ${props =>
    props.active
      ? "inset 0px -5px 0px 0px #70c989"
      : !props.minimized
      ? "inset 0px -5px 0px 0px #70c989"
      : null};
  .linkText {
    color: ${props =>
      props.active && props.minimized ? "#70c989" : "#ffffff"};
  }
  &:hover {
    .linkText {
      color: #70c989;
    }
  }
  text-decoration: none !important;
`;
const LinkText = styled("div")`
  width: 100%;
  bottom: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
`;
const UserAuthContainer = styled("div")`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const firstLoad = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ReactStrapButton = styled(Button)<{
  navminimized?: boolean;
}>`
  background-color: unset !important;
  box-shadow: ${props =>
    !props.navminimized ? "inset 0px -5px 0px 0px #70c989" : null} !important;
  min-width: 78px !important;
  max-width: 75px !important;
  animation: ${firstLoad} 0.5s ease;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: ${props => (props.navminimized ? "26px" : "36px")} !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  color: #ffffff !important;
  background-color: unset !important;
  border-radius: 0 !important;
  border: 0 !important;
  &:focus {
    background-color: unset !important;
    all: unset;
    box-shadow: ${props =>
      !props.navminimized ? "inset 0px -5px 0px 0px #70c989" : null} !important;
    min-width: 78px !important;
    max-width: 75px !important;
    animation: ${firstLoad} 0.5s ease;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: ${props => (props.navminimized ? "26px" : "36px")} !important;
    font-weight: 600 !important;
    font-size: 11px !important;
    color: #ffffff !important;
    border-radius: 0 !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    background-color: unset !important;
    all: unset;
    box-shadow: ${props =>
      !props.navminimized ? "inset 0px -5px 0px 0px #70c989" : null} !important;
    min-width: 78px !important;
    max-width: 75px !important;
    animation: ${firstLoad} 0.5s ease;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: ${props => (props.navminimized ? "26px" : "36px")} !important;
    font-weight: 600 !important;
    font-size: 11px !important;
    color: #ffffff !important;
    border-radius: 0 !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: unset !important;
    color: #70c989 !important;
  }
`;
const AuthLinkBlock = styled(Link)<{
  navminimized?: boolean;
  active: number;
}>`
  min-width: 78px;
  text-decoration: none !important;
  animation: ${firstLoad} 0.5s ease;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: ${props => (props.navminimized ? "100%" : "36px")};
  font-weight: 600 !important;
  font-size: 11px !important;
  box-shadow: ${props =>
    props.active
      ? "inset 0px -5px 0px 0px #70c989"
      : !props.navminimized
      ? "inset 0px -5px 0px 0px #70c989"
      : null}; !important;
  color: ${props => (props.active ? "#70c989" : "#ffffff")} !important;
  background-color: unset !important;
  border-radius: 0 !important;
  border-color: unset !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  &:hover {
    color: #70c989 !important;
  }
`;
export class Navigation extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    let onHomePage = false;
    // @ts-ignore
    // if (this.props.location.pathname === "/") {
    //   onHomePage = true;
    // }
    this.state = {
      currentNavHeight: onHomePage === false ? "50px" : "100vh",
      newNavHeight: onHomePage === false ? "50px" : "100vh",
      currentLinkBlockHeight: onHomePage === false ? "" : "100px",
      newLinkBlockHeight: onHomePage === false ? "" : "100px",
      showEditFor: "none",
      minimized: onHomePage === false ? true : false,
      isLoggedIn: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      return { isLoggedIn: nextProps.isLoggedIn };
    } else return null;
  }

  handleNavBarState = (
    newNavHeight: string,
    newLinkBlockHeight: string,
    minimized: boolean
  ) => {
    const currentNavHeight = document.getElementById("navDiv").offsetHeight;
    const currentLinkBlockHeight = document.getElementById("linkBlockDiv")
      .offsetHeight;

    this.setState({
      currentNavHeight: `${currentNavHeight}px`,
      newNavHeight,
      currentLinkBlockHeight: `${currentLinkBlockHeight}px`,
      newLinkBlockHeight,
      minimized
    });
  };

  getLinkBlocks = () => {
    let linkBlocks = [];

    for (const productName of nameOfAllProducts) {
      linkBlocks.push(
        <LinkBlock
          key={productName}
          //@ts-ignore
          minimized={this.state.minimized ? 1 : 0}
          newlinkblockheight={this.state.newLinkBlockHeight}
          currentlinkblockheight={this.state.currentLinkBlockHeight}
          id="linkBlockDiv"
          onClick={() => {
            this.handleNavBarState("50px", "32px", true);
          }}
          to={`/${productName}`}
          active={
            // @ts-ignore
            this.props.location.pathname.toLowerCase() === `/${productName}` ||
            // @ts-ignore
            this.props.location.pathname.toLowerCase() ===
              `/${productName}preview`
              ? 1
              : 0
          }
        >
          <LinkText className="linkText">
            {displayProductName[productName].toUpperCase()}
          </LinkText>
        </LinkBlock>
      );
    }

    return linkBlocks;
  };

  getNavButtons = () => {
    return <LinkBlockContainer>{this.getLinkBlocks()}</LinkBlockContainer>;
  };

  getUserAuthButtons = () => {
    if (this.state.isLoggedIn) {
      return (
        <UserAuthContainer>
          <AuthLinkBlock
            onClick={() => this.handleNavBarState("50px", "32px", true)}
            //@ts-ignore
            navminimized={this.state.minimized ? 1 : 0}
            active={
              // @ts-ignore
              this.props.location.pathname.toLowerCase() === "/profile" ? 1 : 0
            }
            to={"/Profile"}
          >
            PROFILE
          </AuthLinkBlock>
          <ReactStrapButton
            onClick={() => logoutLocally(this.props.toggleIsLoggedIn)}
            navminimized={this.state.minimized ? 1 : 0}
          >
            SIGN OUT
          </ReactStrapButton>
        </UserAuthContainer>
      );
    }
    return (
      <UserAuthContainer>
        <ReactStrapButton
          onClick={() => {
            this.props.toggleUserAuthModal(true);
            this.props.toggleAuthToShow("login");
          }}
          navminimized={this.state.minimized ? 1 : 0}
        >
          SIGN IN
        </ReactStrapButton>
        <ReactStrapButton
          onClick={() => {
            this.props.toggleUserAuthModal(true);
            this.props.toggleAuthToShow("register");
          }}
          navminimized={this.state.minimized ? 1 : 0}
        >
          SIGN UP
        </ReactStrapButton>
      </UserAuthContainer>
    );
  };

  render(): JSX.Element {
    // window.onscroll = function() {
    //   if (window.pageYOffset <= 50) {
    //     document.getElementById("navDiv").style.top = "0";
    //   } else {
    //     document.getElementById("navDiv").style.top = "-50px";
    //   }
    // };
    return (
      <Nav
        currentNavHeight={this.state.currentNavHeight}
        newNavHeight={this.state.newNavHeight}
        //@ts-ignore
        minimized={this.state.minimized ? 1 : 0}
        id="navDiv"
      >
        <Box1 />
        {this.getNavButtons()}
        {this.getUserAuthButtons()}
      </Nav>
    );
  }
}

function mapStateToProps(state: any) {
  return { isLoggedIn: state.userAuthReducer.isLoggedIn };
}

const NavigationContainer = connect(mapStateToProps, {
  toggleUserAuthModal,
  toggleAuthToShow,
  toggleIsLoggedIn
})(Navigation);

// @ts-ignore
export default withRouter(NavigationContainer);
