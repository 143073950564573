import * as React from "react";
import styled from "styled-components";
import { Button, Modal, Table } from "reactstrap";
import { connect } from "react-redux";
import { toggleToast } from "../../redux";
import { paymentPlansInterface } from "../Helpers/Variables";
import { getSuccessfulPaymentDescription } from "../../API/Payment";
const queryString = require("query-string");

export interface props {
  paymentPlans: Array<paymentPlansInterface>;
  isLoggedIn: boolean;
  toggleToast: Function;
}

export interface state {
  paymentPlans: Array<paymentPlansInterface>;
  isLoggedIn: boolean;
  descriptionLoaded: boolean;
  description: string;
}

const Div = styled("div")`
  margin: 20px 20px 20px 20px;
  flex: 1;
  background-color: #3c4c55;
  padding: 20px 20px 20px 20px;
`;
const Title = styled("div")`
  color: white;
  font-size: 25px;
`;
const Description = styled("div")`
  padding-top: 30px;
  width: 100%;
  text-align: left;
  color: white;
  font-size: 18px;
  white-space: pre-line;
  line-height: 2;
`;
export class PaymentSuccessful extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      paymentPlans: [],
      isLoggedIn: false,
      descriptionLoaded: false,
      description: ""
    };
  }

  async componentDidMount() {
    try {
      await this.getDescription();
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      paymentPlans?: Array<paymentPlansInterface>;
      isLoggedIn?: boolean;
    } = {};
    if (nextProps.paymentPlans !== prevState.paymentPlans) {
      update.paymentPlans = nextProps.paymentPlans;
    }

    if (nextProps.isLoggedIn !== prevState.isLoggedIn) {
      update.isLoggedIn = nextProps.isLoggedIn;
    }

    return Object.keys(update).length ? update : null;
  }

  async getDescription() {
    const checkoutSessionId = queryString.parse(location.search).session_id;

    let description = null;
    try {
      description = await getSuccessfulPaymentDescription(checkoutSessionId);
    } catch (e) {
      console.log(e);
      throw e;
    }

    this.setState({
      descriptionLoaded: true,
      description
    });
  }

  render(): JSX.Element {
    return (
      <Div>
        <Title>Payment Successful</Title>
        <Description>
          {this.state.descriptionLoaded
            ? this.state.description
            : "Please wait..."}
        </Description>
        <br />
        <br />
        <Description>
          {this.state.descriptionLoaded
            ? "PS, if you were in the middle of creating something before payment, you'll find it saved like you left it :)"
            : null}
        </Description>
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    paymentPlans: state.paymentReducer.paymentPlans,
    isLoggedIn: state.userAuthReducer.isLoggedIn
  };
}

const PaymentSuccessfulContainer = connect(mapStateToProps, {
  toggleToast
})(PaymentSuccessful);

export default PaymentSuccessfulContainer;
