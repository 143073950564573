import * as React from "react";
import styled from "styled-components";
import Delete from "./Delete";
import Password from "./Password";
import Payment from "./Payment";
import { connect } from "react-redux";

export interface props {
  isPayingUser: boolean;
}
export interface state {
  isPayingUser: boolean;
}

const Div = styled("div")`
  width: 100%;
  height: 100%
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 10px 20px;
`;
export class Profile extends React.Component<props, state> {
  constructor(props: props) {
    super(props);

    this.state = {
      isPayingUser: false
    };
  }

  static getDerivedStateFromProps(nextProps: props, prevState: state) {
    let update: {
      isPayingUser?: boolean;
    } = {};

    if (nextProps.isPayingUser !== prevState.isPayingUser) {
      update.isPayingUser = nextProps.isPayingUser;
    }

    return Object.keys(update).length ? update : null;
  }

  componentDidMount() {}

  render(): JSX.Element {
    return (
      <Div>
        <Payment />
        <Password />
        {/* <Delete /> */}
      </Div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    isPayingUser: state.paymentReducer.isPayingUser
  };
}

const ProfileContainer = connect(mapStateToProps, {})(Profile);

export default ProfileContainer;
