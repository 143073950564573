import * as React from "react";
import styled from 'styled-components';

export interface props {}

const Div = styled('div')`
`

export class WordSearch extends React.Component<props, {}> {
  render(): JSX.Element {
    return <Div>Word Search</Div>;
  }
}
