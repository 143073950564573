import * as React from "react";
import styled from "styled-components";
import { ButtonGroup, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { setCrosswordWordAndTitleData } from "../../../../redux";
import { connect } from "react-redux";
import { store } from "../../../../redux";

export interface props {
  totalHeightInCm: number;
  totalWidthInCm: number;
  unplacedWords: Array<{
    id: number;
    word: string;
    clue: string;
  }>;
  refreshPreview: any;
  setCrosswordWordAndTitleData: Function;
}

export interface state {}

const UnplacedWordsModal = styled("div")<{ totalHeightInCm: number }>`
  height: calc(${props => props.totalHeightInCm}cm - 2.5px);
  width: 7cm;
  background-color: #70c989;
  border-bottom: 2px solid black;
  border-left: 4px solid black;
  border-right: 2px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
`;
const Title = styled("div")`
  font-weight: bold;
  color: #3a3434;
  font-size: 20px;
  padding-bottom: 15px;
`;
const SorryGroup = styled("div")`
  width: 100%;
  border-bottom: solid 2.5px #252a31;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const WordListGroup = styled("div")`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  overflow-y: auto;
  line-height: 1.5;
`;

const ReactStrapButtonGroup = styled(ButtonGroup)`
  display: flex;
  flex-direction: column;
`;
const ReactStrapButton = styled(Button)`
  background-color: #252a31 !important;
  border-radius: 0 !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #6d7b8e !important;
  }
  &:not(:disabled):not(.disabled).active {
    background-color: #6d7b8e !important;
  }
  width: 175px;
  margin-bottom: 5px;
`;
const OptionContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: left;
`;
export class UnplacedWords extends React.Component<props, state> {
  Preview: any;

  constructor(props: props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  getUnplacedWordList = () => {
    return (
      <ul style={{ textAlign: "left", alignSelf: "end" }}>
        {this.props.unplacedWords.map((wordObj, index) => (
          <li key={index}>{wordObj.word}</li>
        ))}
      </ul>
    );
  };

  render(): JSX.Element {
    return (
      <UnplacedWordsModal totalHeightInCm={this.props.totalHeightInCm}>
        <SorryGroup>
          <Title>Sorry</Title>
          <div>
            Some words could not be placed (listed below), you could try to:
          </div>
          <br />
          <ol
            style={{
              marginTop: "1rem",
              marginBottom: "2rem",
              textAlign: "left"
            }}
          >
            <li>
              Make sure the unplaced words have at least one letter in common
              with a placed word
            </li>
            <li>Reduce block size</li>
            <li>Increase number of blocks</li>
            <li>Increase number of blocks</li>
            <li>Decrease number of words</li>
          </ol>
          <OptionContainer>
            <ReactStrapButtonGroup>
              <ReactStrapButton
                // @ts-ignore
                onClick={() => this.props.history.push("/CrossWord")}
              >
                Go Back
              </ReactStrapButton>
              <ReactStrapButton
                onClick={() => this.props.refreshPreview("simpleRegen")}
              >
                Try Regenerating
              </ReactStrapButton>
            </ReactStrapButtonGroup>
          </OptionContainer>
        </SorryGroup>
        <WordListGroup>
          <Title>Unplaced Words</Title>
          {this.getUnplacedWordList()}
        </WordListGroup>
      </UnplacedWordsModal>
    );
  }
}

const UnplacedWordsConnected = connect(null, { setCrosswordWordAndTitleData })(
  UnplacedWords
);
// @ts-ignore
export default withRouter(UnplacedWordsConnected);
