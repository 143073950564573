// real version stored in API
export interface paymentPlansInterface {
  id: string;
  plan: string;
  amountInCents: number;
  moneyText: string;
  currency: string;
  oneTimePayment: boolean;
  payFormDescription: string;
  savingsText: string;
  promotionText: string;
  downloadYourOwn: string;
  downloadPremade: string;
  saves: string;
  customization: boolean;
  noAds: boolean;
  purchaseButtonText: string;
  showPurchaseButton: boolean;
  restrictMaxPrints: boolean;
  trialDays: number;
}
export const nameOfAllProducts: Array<string> = [
  "crossword"
  // "wordSearch"
];
export const displayProductName: { [key: string]: string } = {
  [nameOfAllProducts[0]]: "crossword"
  // [nameOfAllProducts[1]]: "word search"
};
export const paymentPlansModalTypes: Array<string> = [
  "customization",
  "downloading your own creation",
  "saving more than 3 items"
];
